/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
// react
import React, { useMemo } from "react";
import { connect } from "react-redux";

// application
import productApi from "../../server/real/endpoints/products";
import { useProductTabs } from "../../services/hooks";

// blocks
import BlockProductsCarousel from "../blocks/BlockProductsCarousel";

function ProductsCarousel(props) {
    const { section, region } = props;
    const {
 categories = [], title, layout, rows, source, limit,
} = section;

    const getProducts = (tab, source, limit) => {
        switch (source) {
        case "featured":
            return productApi.getProductsByTag(tab * limit, limit, "Featured");
        case "new":
            return productApi.getProductsByTag(tab * limit, limit, "New");
        case "bestseller":
            return productApi.getProductsByTag(tab * limit, limit, "Best Seller");
        default:
            return productApi.getProductsByTag(tab * limit, limit, source);
        }
    };

    const productList = useProductTabs(
        useMemo(() => categories, [region]),
        (tab) => getProducts(tab, source, limit),
        region,
    );

    return (
        <React.Fragment>
            {productList.data?.data?.items && productList.data?.data?.items.length > 0 && (
                <BlockProductsCarousel
                    title={title}
                    layout={layout}
                    rows={rows}
                    products={productList.data.data && productList.data.data.items?.sort((a, b) => a?.data?.productOptions?.sortOrder - b?.data?.productOptions?.sortOrder)}
                    loading={productList.isLoading}
                    groups={[]}
                    onGroupClick={productList.handleTabChange}
                />
            )}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    region: state.region,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsCarousel);
