import ReactDOMServer from "react-dom/server";

export function createOrderMail(order, store) {
    const copyright = store?.footerManagement?.footerCopyrightSection;

    const personalizationData = (personalization, currentsize) => {
        const groupByGroupIdAndSize = personalization.reduce((acc, curr) => {
            const size = curr?.label || curr?.size;
            const key = `${curr.groupId}-${size}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(curr);
            return acc;
        }, {});

        const options = Object.entries(groupByGroupIdAndSize).map(([key], index) => {
            const group = groupByGroupIdAndSize[key];
            const size = group?.[0]?.label || group?.[0]?.size;
            const sizeText = size ? `(${size})` : "";

            if (currentsize !== size) return false;

            return `<div
                          key={sizeText}
                          style='width: '100%'
                      >
                          <span
                              style='font-weight: 600;font-size: 13px;padding-bottom: 3px;border-bottom: 1px solid;'
                          >
                              ${index + 1}. Personalization ${sizeText}
                          </span>
                          ${group
                              .map(
                                  (option) =>
                                      `<div
                                  key=${index}
                                  style='display: flex;flex-direction: row;justify-content: flex-start;width: 100%;gap: 10px;margin-top: 5px;'
                              >
                                  <span style='padding: 0px;font-weight: 600;font-size: 13px;text-wrap: nowrap;display: inline-block;white-space: nowrap;text-align: start;flex-basis: ${group?.reduce(
                                      (acc, curr) => Math.max(acc, curr?.name?.length),
                                      0
                                  )}ch'
                                  >
                                      ${option.name}:
                                  </span>
                                  <span style='padding: 0px;display: flex;flex-grow: 1;text-align: start;font-size: 13px;'
                                  >
                                      ${
                                          typeof option?.value === "string" && option?.value?.length > 0
                                              ? option.value
                                              : ""
                                      }
                                  </span>
                              </div>`
                              )
                              .join("")}
                      </div>`;
        });

        if (options.filter(Boolean).length > 0)
            return `<div style="border: 1px dashed #cacaca; margin: 10px; padding: 0px 10px;">
                          ${options.filter(Boolean).join("")}
                  </div>`;
        else return "";
    };

    const htmlData = `<div
          style="
                  background-color: rgb(241, 245, 249);
                  color: rgb(17, 24, 39);
                  display: flex;
                  flex: 1 1 auto;
                  width: 100%;
                  -webkit-font-smoothing: auto;
                  -moz-osx-font-smoothing: auto;
                  flex-direction: column;
                  margin: 0;
                  min-height: 100%;
                  position: relative;
                  font-feature-settings: 'salt';
                  font-size: 14px;
                  line-height: 1.4;
                  overflow-x: hidden;
                  max-width: 1024px;
                  margin-inline: auto;
                "
        >
          <div style="margin: 32px">
            <div style="padding: 32px">
              <table style="width: 100%; margin-bottom: 50px; table-layout: fixed;">
                <tbody>
                  <tr>
                    <td  style="width: 50%;padding-bottom: 35px;">
                      <table>
                      <tr>
                        <td
                          style="
                              margin: 0px;
                              font-size: 18px;
                              font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                              font-weight: 400;
                              line-height: 1.5;
                              text-align: center;
                              color: rgb(107, 114, 128);
                              place-self: center;
                              width: 96px;
                            "
                        >
                          Bill To
                        </td>
                        <td
                          style="
                              border-left-width: 1px;
                              border-left: solid 1px rgb(226, 232, 240);
                              font-size: 13px;
                              padding-left: 40px;
                            "
                        >
                          <p
                            style="
                                margin: 0px;
                                font-size: 14px;
                                font-family: 'Inter var', Roboto, Helvetica, Arial,
                                  sans-serif;
                                font-weight: 400;
                                line-height: 1.5;
                              "
                          >
                            ${order?.billingAddress?.firstName}
                            ${order?.billingAddress?.lastName}
                          </p>
                          <p
                            style="
                                margin: 0px;
                                font-size: 14px;
                                font-family: 'Inter var', Roboto, Helvetica, Arial,
                                  sans-serif;
                                font-weight: 400;
                                line-height: 1.5;
                              "
                          >
                            ${order?.billingAddress?.company}
                          </p>
                          <p
                            style="
                                margin: 0px;
                                font-size: 14px;
                                font-family: 'Inter var', Roboto, Helvetica, Arial,
                                  sans-serif;
                                font-weight: 400;
                                line-height: 1.5;
                              "
                          >
                            ${order?.billingAddress?.address1}<br />
                            ${order?.billingAddress?.address2}
                          </p>
                          <p
                            style="
                                margin: 0px;
                                font-size: 14px;
                                font-family: 'Inter var', Roboto, Helvetica, Arial,
                                  sans-serif;
                                font-weight: 400;
                                line-height: 1.5;
                              "
                          >
                            ${order?.billingAddress?.city},
                            ${order?.billingAddress?.state},
                            ${order?.billingAddress?.zip}<br />
                            ${order?.billingAddress?.country}
                          </p>
                          <p
                            style="
                                margin: 0px;
                                font-size: 14px;
                                font-family: 'Inter var', Roboto, Helvetica, Arial,
                                  sans-serif;
                                font-weight: 400;
                                line-height: 1.5;
                              "
                          >
                            ${order?.billingAddress?.phone}
                          </p>
                          <p
                            style="
                                margin: 0px;
                                font-size: 14px;
                                font-family: 'Inter var', Roboto, Helvetica, Arial,
                                  sans-serif;
                                font-weight: 400;
                                line-height: 1.5;
                              "
                          >
                            ${order?.billingAddress?.email}
                          </p>
                        </td>
                      </tr>
                      </table>
                    </td>
                    <td style="width: 50%;">
                    <table  style="float: right;">
                      <tr
                        style="
                            margin-bottom: 48px;
                          "
                      >
                        <td style="width: 150px; place-self: center">
                          <img
                            src="${copyright?.footerLogo}"
                            alt="logo"
                            style="width: 100%; margin-right: 32px;"
                          />
                        </td>
                        <td
                          style="
                              padding-left: 40px;
                              font-size: 13px;
                              border-left-width: 1px;
                              border-left: solid 1px rgb(226, 232, 240);
                            "
                        >
                          <p
                            style="
                                font-weight: 500;
                                margin: 0px;
                                font-size: 14px;
                                font-family: 'Inter var', Roboto, Helvetica, Arial,
                                  sans-serif;
                                line-height: 1.5;
                              "
                          >
                            ${store?.primaryInformation?.clientName}
                          </p>
                          <p
                            style="
                                margin: 0px;
                                font-size: 14px;
                                font-family: 'Inter var', Roboto, Helvetica, Arial,
                                  sans-serif;
                                font-weight: 400;
                                line-height: 1.5;
                              "
                          >
                            ${copyright?.copyRightCompanyAddress}
                          </p>
                          <p
                            style="
                                margin: 0px;
                                font-size: 14px;
                                font-family: 'Inter var', Roboto, Helvetica, Arial,
                                  sans-serif;
                                font-weight: 400;
                                line-height: 1.5;
                              "
                          >
                            ${copyright?.copyRightCompanyPhone}
                          </p>
                          <p
                            style="
                                margin: 0px;
                                font-size: 14px;
                                font-family: 'Inter var', Roboto, Helvetica, Arial,
                                  sans-serif;
                                font-weight: 400;
                                line-height: 1.5;
                              "
                          >
                            ${copyright?.copyRightCompanyEmail}
                          </p>
                        </td>
                      </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td  style="width: 50%;margin-bottom: 20px;">
                    <table>
                      <tr>
                        <td
                          style="
              margin: 0px;
              font-size: 18px;
              font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
              font-weight: 400;
              line-height: 1.5;
              text-align: center;
              color: rgb(107, 114, 128);
              place-self: center;
              width: 96px;
            "
                        >
                          Ship To
                        </td>
                        <td
                          style="
              border-left-width: 1px;
              border-left: solid 1px rgb(226, 232, 240);
              font-size: 13px;
              padding-left: 40px;
            "
                        >
                          <p
                            style="
                margin: 0px;
                font-size: 14px;
                font-family: 'Inter var', Roboto, Helvetica, Arial,
                  sans-serif;
                font-weight: 400;
                line-height: 1.5;
              "
                          >
                            ${order?.shippingAddress?.firstName}
                            ${order?.shippingAddress?.lastName}
                          </p>
                          <p
                            style="
                margin: 0px;
                font-size: 14px;
                font-family: 'Inter var', Roboto, Helvetica, Arial,
                  sans-serif;
                font-weight: 400;
                line-height: 1.5;
              "
                          >
                            ${order?.shippingAddress?.company}
                          </p>
                          <p
                            style="
                margin: 0px;
                font-size: 14px;
                font-family: 'Inter var', Roboto, Helvetica, Arial,
                  sans-serif;
                font-weight: 400;
                line-height: 1.5;
              "
                          >
                            ${order?.shippingAddress?.address1}<br />
                            ${order?.shippingAddress?.address2}
                          </p>
                          <p
                            style="
                margin: 0px;
                font-size: 14px;
                font-family: 'Inter var', Roboto, Helvetica, Arial,
                  sans-serif;
                font-weight: 400;
                line-height: 1.5;
              "
                          >
                            ${order?.shippingAddress?.city},
                            ${order?.shippingAddress?.state},
                            ${order?.shippingAddress?.zip}<br />
                            ${order?.shippingAddress?.country}
                          </p>
                          <p
                            style="
                margin: 0px;
                font-size: 14px;
                font-family: 'Inter var', Roboto, Helvetica, Arial,
                  sans-serif;
                font-weight: 400;
                line-height: 1.5;
              "
                          >
                            ${order?.shippingAddress?.phone}
                          </p>
                          <p
                            style="
                margin: 0px;
                font-size: 14px;
                font-family: 'Inter var', Roboto, Helvetica, Arial,
                  sans-serif;
                font-weight: 400;
                line-height: 1.5;
              "
                          >
                            ${order?.shippingAddress?.email}
                          </p>
                        </td>
                      </tr>
                      </table>
                    </td>

                    <td style="width: 50%;text-align: center;">

        <table  style="min-width: 360px;">
                      <tr>
                        <td
                          style="
                              letter-spacing: -0.025em;
                              font-size: 32px;
                              justify-self: end;
                              margin: 0px;
                              font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                              font-weight: 400;
                              line-height: 1.5;
                              color: rgb(107, 114, 128);
                            "
                        >
                          ORDER
                        </td>
                        <td
                          style="
                              font-size: 32px;
                              margin: 0px;
                              font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                              font-weight: 400;
                              line-height: 1.5;
                            "
                        >
                          #{{orderId}}
                        </td>
                      </tr>

                      <tr>
                        <td
                          style="
                              letter-spacing: -0.025em;
                              font-weight: 500;
                              justify-self: end;
                              line-height: 1.5;
                              color: rgb(107, 114, 128);
                              margin: 0px;
                              font-size: 14px;
                              font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                            "
                        >
                          ORDER DATE
                        </td>
                        <td
                          style="
                              font-weight: 500;
                              margin: 0px;
                              font-size: 14px;
                              font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                              line-height: 1.5;
                            "
                        >
                          ${new Date().toLocaleDateString()}
                        </td>
                      </tr>

                      <tr>
                        <td
                          style="
                              letter-spacing: -0.025em;
                              font-weight: 500;
                              justify-self: end;
                              line-height: 1.5;
                              color: rgb(107, 114, 128);
                              margin: 0px;
                              font-size: 14px;
                              font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                            "
                        >
                          PAYMENT METHOD
                        </td>
                        <td
                          style="
                              font-weight: 500;
                              margin: 0px;
                              font-size: 14px;
                              font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                              line-height: 1.5;
                            "
                        >
                          ${order?.payment}
                        </td>
                      </tr>

                      <tr>
                        <td
                          style="
                              letter-spacing: -0.025em;
                              font-weight: 500;
                              justify-self: end;
                              line-height: 1.5;
                              color: rgb(107, 114, 128);
                              margin: 0px;
                              font-size: 14px;
                              font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                            "
                        >
                          SHIPPING METHOD
                        </td>
                        <td
                          style="
                              font-weight: 500;
                              margin: 0px;
                              font-size: 14px;
                              font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                              line-height: 1.5;
                            "
                        >
                          ${order?.shippingMethod ?? order?.shippingCarrier ?? "-"}
                        </td>
                      </tr>

                      <tr>
                        <td
                          style="
                              letter-spacing: -0.025em;
                              font-weight: 500;
                              justify-self: end;
                              line-height: 1.5;
                              color: rgb(107, 114, 128);
                              margin: 0px;
                              font-size: 14px;
                              font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                            "
                        >
                          TOTAL DUE
                        </td>
                        <td
                          style="
                              font-weight: 500;
                              margin: 0px;
                              font-size: 14px;
                              font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                              line-height: 1.5;
                            "
                        >
                          $${(
                              order?.totalAmount +
                              order?.totalTax +
                              order?.shippingAmount -
                              (order?.giftCodeBalance || 0)
                          ).toFixed(2)}
                        </td>
                      </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table style="width: 100%; padding-top: 75px; padding-bottom: 50px; ">
                <thead>
                  <tr>
                    <th>
                      <p
                        style="
                          font-weight: 500;
                          margin: 0px;
                          font-size: 14px;
                          font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                          line-height: 1.5;
                          text-align: center;
                        "
                      >
                        Image
                      </p>
                    </th>
                    <th>

                      <p
                        style="
                          font-weight: 500;
                          margin: 0px;
                          font-size: 14px;
                          font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                          line-height: 1.5;
                          text-align: left;
                        "
                      >
                        Product
                      </p>
                    </th>
                    <th>

                      <p
                        style="
                          font-weight: 500;
                          margin: 0px;
                          font-size: 14px;
                          font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                          line-height: 1.5;
                          text-align: center;
                        "
                      >
                        Color
                      </p>
                    </th>
                    <th>

                      <p
                        style="
                          font-weight: 500;
                          margin: 0px;
                          font-size: 14px;
                          font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                          line-height: 1.5;
                          text-align: center;
                        "
                      >
                        Size
                      </p>
                    </th>
                    <th>
                      <p
                        style="
                          font-weight: 500;
                          margin: 0px;
                          font-size: 14px;
                          font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                          line-height: 1.5;
                          text-align: center;          "
                      >
                        Price
                      </p>
                    </th>
                    <th>

                      <p
                        style="
                          font-weight: 500;
                          margin: 0px;
                          font-size: 14px;
                          font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                          line-height: 1.5;
                          text-align: center;
                        "
                      >
                        Quantity
                      </p>
                    </th>
                    <th>

                      <p
                        style="
                          font-weight: 500;
                          margin: 0px;
                          font-size: 14px;
                          font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                          line-height: 1.5;
                          text-align: center;
                        "
                      >
                        Total
                      </p>
                    </th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  ${order?.lines
                      .map((item) => {
                          return item.sizes
                              .map((s, i) => {
                                  return `
                          <tr>
                            <td style="width: 70px; height: 70px;">
                              <img
                                style="object-fit: contain; margin: 5px;"
                                src="${item?.primaryImage}" alt="product-image" width="70" height="70"
                              />
                            </td>

                            <td>
                                <p
                                  style="
                                    margin: 0px;
                                    font-size: 14px;
                                    font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                                    line-height: 1.5;
                                    text-align: left;
                                  "
                                >
                                  ${item?.productName}
                                </p>
                            </td>

                            <td>
                              <p
                                style="
                                  font-size: 14px;
                                  font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                                  font-weight: 400;
                                  line-height: 1.5;
                                  text-align: center;
                                "
                              >
                                ${item?.color}
                              </p>
                            </td>

                            <td>
                              <p
                                style="
                                  font-size: 14px;
                                  font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                                  font-weight: 400;
                                  line-height: 1.5;
                                  text-align: center;
                                "
                              >
                                ${s?.size}
                              </p>
                            </td>

                            <td>
                              <p
                                style="
                                  margin: 0px;
                                  font-size: 14px;
                                  font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                                  font-weight: 400;
                                  line-height: 1.5;
                                  text-align: center;

                                "
                              >
                               $${s?.unitPrice.toFixed(2)}
                              </p>
                            </td>

                            <td>
                                <p
                                style="
                                  margin: 0px;
                                  font-size: 14px;
                                  font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                                  font-weight: 400;
                                  line-height: 1.5;
                                  text-align: center;

                                "
                              >
                                ${s?.quantity}
                              </p>
                            </td>

                            <td>
                              <p
                                style="
                                  margin: 0px;
                                  font-size: 14px;
                                  font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                                  font-weight: 400;
                                  line-height: 1.5;
                                  text-align: center;
                                "
                              >
                                $${(s?.unitPrice * s.quantity).toFixed(2)}
                              </p>
                            </td>
                          </tr>
                          ${
                              Array.isArray(item?.personalization) &&
                              `
                                  <tr>
                                    <td colspan="7">
                                      ${personalizationData(item?.personalization, s?.size)}
                                    </td>
                                  </tr>
                                `
                          }
                        `;
                              })
                              .join("");
                      })
                      .join("")}
                </tbody>
              </table>
              <table style="width: 100%; margin: 20px; width: min-content;
            margin-left: auto;">
                <tbody>
                  <tr
                    style="
                                  border-bottom-width: 1px;
                                  border-bottom: solid 1px rgb(226, 232, 240);
                                  margin-bottom: 12px;
                                  margin-top: 12px;

                                "
                  >
                    <td style="padding-right: 50px;">

                      <p
                        style="
                          letter-spacing: -0.025em;
                          font-weight: 500;
                          margin: 10px;
                          font-size: 14px;
                          font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                          line-height: 1.5;

                        "
                      >
                        SUBTOTAL
                      </p>
                    </td>
                    <td>

                      <p
                        style="
                          font-size: 16px;
                          text-align: right;

                          margin: 0px;
                          font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                          font-weight: 400;
                          line-height: 1.5;
                        "
                      >
                        $${order?.totalAmount?.toFixed(2)}
                      </p>
                    </td>
                  </tr>
                  <tr
                    style="
                                  border-bottom-width: 1px;
                                  border-bottom: solid 1px rgb(226, 232, 240);
                                  margin-bottom: 12px;
                                  margin-top: 12px;

                                "
                  >
                    <td>

                      <p
                        style="
                          letter-spacing: -0.025em;
                          font-weight: 500;
                          margin: 10px;
                          font-size: 14px;
                          font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                          line-height: 1.5;

                        "
                      >
                        TAX
                      </p>
                    </td>
                    <td>

                      <p
                        style="
                          font-size: 16px;
                          text-align: right;

                          margin: 0px;
                          font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                          font-weight: 400;
                          line-height: 1.5;
                        "
                      >
                        $${order?.totalTax.toFixed(2)}
                      </p>
                    </td>
                  </tr>
                  <tr
                    style="
                                  border-bottom-width: 1px;
                                  border-bottom: solid 1px rgb(226, 232, 240);
                                  margin-bottom: 12px;
                                  margin-top: 12px;

                                "
                  >
                    <td>

                      <p
                        style="
                          letter-spacing: -0.025em;
                          font-weight: 500;
                          margin: 10px;
                          font-size: 14px;
                          font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                          line-height: 1.5;

                        "
                      >
                        SHIPPING
                      </p>
                    </td>
                    <td>

                      <p
                        style="
                          font-size: 16px;
                          text-align: right;

                          margin: 0px;
                          font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                          font-weight: 400;
                          line-height: 1.5;
                        "
                      >
                        $${order?.shippingAmount.toFixed(2)}
                      </p>
                    </td>
                  </tr>
                  ${
                      order?.giftCodeBalance &&
                      `
                  <tr
                    style="
                                  border-bottom-width: 1px;
                                  border-bottom: solid 1px rgb(226, 232, 240);
                                  margin-bottom: 12px;
                                  margin-top: 12px;

                                "
                  >
                    <td>

                      <p
                        style="
                          letter-spacing: -0.025em;
                          font-weight: 500;
                          margin: 10px;
                          font-size: 14px;
                          font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                          line-height: 1.5;

                        "
                      >
                        GIFT CARD
                      </p>
                    </td>
                    <td>

                      <p
                        style="
                          font-size: 16px;
                          text-align: right;

                          margin: 0px;
                          font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                          font-weight: 400;
                          line-height: 1.5;
                        "
                      >
                        $${order?.giftCodeBalance.toFixed(2)}
                      </p>
                    </td>
                  </tr>
                  `
                  }
                  <tr>
                    <td>

                      <p
                        style="
                          font-weight: 500;
                          margin: 10px;
                          font-size: 14px;
                          font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                          line-height: 1.5;
                          letter-spacing: -0.025em;
                          align-self: center;

                        "
                      >
                        TOTAL
                      </p>
                    </td>
                    <td>
                      <p
                        style="
                          font-weight: 500;
                          margin: 0px;
                          font-size: 14px;
                          font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                          line-height: 1.5;
                          text-align: right;

                        "
                      >
                        $${(
                            order?.totalAmount +
                            order?.shippingAmount +
                            order?.totalTax -
                            (order?.giftCodeBalance || 0)
                        ).toFixed(2)}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>


              ${
                  order?.note &&
                  `
              <table style="width: 100%; margin: 20px;">
                <tbody>
                  <tr
                    style="
                                  border-bottom-width: 1px;
                                  border-bottom: solid 1px rgb(226, 232, 240);
                                  margin-bottom: 12px;
                                  margin-top: 12px;

                                "
                  >
                    <td>

                      <p
                        style="
                          letter-spacing: -0.025em;
                          font-weight: 500;
                          margin: 10px;
                          font-size: 14px;
                          font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                          line-height: 1.5;

                        "
                      >
                        ORDER NOTES
                      </p>
                    </td>
                  </tr>
                  <tr
                  style="
                                border-bottom-width: 1px;
                                border-bottom: solid 1px rgb(226, 232, 240);
                                margin-bottom: 12px;
                                margin-top: 12px;

                              "
                >
                  <td>

                    <p
                      style="
                        letter-spacing: -0.025em;
                        margin: 5px 10px;
                        font-size: 12px;
                        font-family: 'Inter var', Roboto, Helvetica, Arial, sans-serif;
                        line-height: 1.5;

                      "
                    >
                      ${order?.note}
                    </p>
                  </td>
                </tr>
                </tbody>
              </table>
              `
              }

            </div>
          </div>
        </div>`.trim();

    return htmlData;
}
