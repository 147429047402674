// react
import React, { Fragment } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// application
import { ArrowRoundedLeft6x9Svg } from '../../svg';
import { url } from '../../services/utils';

function FilterCategory(props) {
    const { data, slug, categorySlug } = props;

    const categoriesList = data && data.map((category, idx) => (
        <Fragment key={idx}>

            <li className={classNames('filter-categories__item', 'filter-categories__item--title', categorySlug && categorySlug.includes(category.name) && 'filter-categories__item--selected')}>
                <Link to={url.category(category.name)}>{category.name}</Link>
            </li>
            {category.subCategory && category.subCategory.map((sub, idx) => (
                <Fragment key={idx}>
                    <li key={idx} className={classNames('filter-categories__item', 'filter-categories__item--sub', categorySlug && categorySlug.includes(sub.name) && 'filter-categories__item--selected')}>
                        <Link to={url.category(encodeURIComponent(`${category.name}/${sub.name}`))}>{sub.name}</Link>
                    </li>
                    {sub.childCategory && sub.childCategory.map((child, idx) => (
                        <Fragment key={idx}>
                            <li key={idx} className={classNames('filter-categories__item', 'filter-categories__item--child', categorySlug && categorySlug.includes(child.name) && 'filter-categories__item--selected')}>
                                <Link to={url.category(encodeURIComponent(`${category.name}/${sub.name}/${child.name}`))}>{child.name}</Link>
                            </li>
                        </Fragment>
                    ))}
                </Fragment>
            ))}
        </Fragment>
    ));

    if (slug) {
        categoriesList.unshift(
            <li key="[shop]" className="filter-categories__item filter-categories__item--parent">
                <ArrowRoundedLeft6x9Svg className="filter-categories__arrow" />
                <Link to={url.catalog()}>All Products</Link>
            </li>,
        );
    }

    return (
        <div className="filter-categories">
            <ul className="filter-categories__list">
                {categoriesList}
            </ul>
        </div>
    );
}

FilterCategory.propTypes = {
    /**
     * Filter object.
     */
    data: PropTypes.object,
};

export default FilterCategory;
