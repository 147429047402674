// react
import React, { useContext } from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// application
import { ArrowRoundedRight6x9Svg } from '../../svg';
import StoreContext from '../../contexts/StoreContext';

function PageHeader(props) {
    const { store } = useContext(StoreContext);
    let { header, breadcrumb } = props;

    if (header) {
        header = (
            <div className="page-header__title">
                <h1>{header}</h1>
            </div>
        );
    }

    if (breadcrumb.length > 0) {
        const lastIndex = breadcrumb.length - 1;

        breadcrumb = breadcrumb.map((item, index) => {
            let link;

            if (lastIndex === index) {
                link = <li key={index} className="breadcrumb-item active" aria-current="page">{item.title}</li>;
            } else {
                link = (
                    <li key={index} className="breadcrumb-item">
                        <Link to={item.url}>{item.title}</Link>
                        <ArrowRoundedRight6x9Svg className="breadcrumb-arrow" />
                    </li>
                );
            }

            return link;
        });

        breadcrumb = (
            <div className="page-header__breadcrumb">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {breadcrumb}
                    </ol>
                </nav>
            </div>
        );
    }

    return (
        <React.Fragment>
            {props.header !== 'Home' && (
                <div className="page-header">
                    <div className="page-header__container container">
                        {breadcrumb}
                        {header}
                    </div>
                </div>
            )}
            {store?.storeMessage?.active && (
                <div className="container closedMessage" style={{ marginInline: 'auto' }}>
                    <span className="exclamationMark">!</span>
                    {store?.storeMessage?.closedMessage}
                </div>
            )}
        </React.Fragment>

    );
}

PageHeader.propTypes = {
    /** page header */
    header: PropTypes.node,
    /** array of breadcrumb links */
    breadcrumb: PropTypes.array,
};

PageHeader.defaultProps = {
    breadcrumb: [],
};

export default PageHeader;
