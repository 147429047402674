// react
import React, { useEffect, useReducer, useState } from "react";

// third-party
import PropTypes from "prop-types";
import queryString from "query-string";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";

// application
import { useParams } from "react-router-dom";
import axios from "axios";
import Sections from "components/layout/Sections";
import BlockLoader from "../blocks/BlockLoader";
import CategorySidebar from "./CategorySidebar";
import CategorySidebarItem from "./CategorySidebarItem";
import PageHeader from "../shared/PageHeader";
import ProductsView from "./ProductsView";
import productApi from "../../server/real/endpoints/products";
import filterApi from "../../server/real/endpoints/filters";
import WidgetFilters from "../widgets/WidgetFilters";
import { sidebarClose } from "../../store/sidebar";

// data stubs
import theme from "../../data/theme";
import { url, getCategoryParents } from "../../services/utils";

function parseQueryOptions(location) {
    const query = queryString.parse(location);
    const optionValues = {};

    if (typeof query.page === "string") {
        optionValues.page = parseFloat(query.page);
    }
    if (typeof query.limit === "string") {
        optionValues.limit = parseFloat(query.limit);
    }
    if (typeof query.sort === "string" && query.sort !== "") {
        optionValues.sort = query.sort;
    }
    if (typeof query.search === "string" && query.search !== "") {
        optionValues.search = query.search;
    }

    return optionValues;
}

function parseQueryFilters(location) {
    const query = queryString.parse(location);
    const filterValues = {};

    Object.keys(query).forEach((param) => {
        const mr = param.match(/^filter_([-_A-Za-z0-9]+)$/);

        if (!mr) {
            return;
        }

        const filterSlug = mr[1];

        filterValues[filterSlug] = query[param];
    });

    return filterValues;
}

function parseQuery(location) {
    return [parseQueryOptions(location), parseQueryFilters(location)];
}

function buildQuery(options, filters) {
    const params = {};

    if (options.page !== 1) {
        params.page = options.page;
    }

    if (options.limit !== 12) {
        params.limit = options.limit;
    }

    if (options.sort !== "default") {
        params.sort = options.sort;
    }

    if (options.search !== "") {
        params.search = "";
    }

    Object.keys(filters)
        .filter((x) => x !== "category" && !!filters[x])
        .forEach((filterSlug) => {
            params[`filter_${filterSlug}`] = filters[filterSlug];
        });

    return queryString.stringify(params, { encode: false });
}

const initialState = {
    init: false,
    /**
     * Indicates that the category is loading.
     */
    filtersIsLoading: true,
    /**
     * Category object.
     */
    categories: [],
    colors: [],
    price: {},
    tags: ["New", "Featured", "Best Seller"],
    /**
     * Indicates that the products list is loading.
     */
    productsListIsLoading: true,
    /**
     * Products list.
     */
    productsList: null,
    /**
     * Products list options.
     *
     * options.page:  number - Current page.
     * options.limit: number - Items per page.
     * options.sort:  string - Sort algorithm.
     */
    options: {
        page: 1,
        skip: 0,
        limit: 20,
        sort: "",
        search: "",
    },
    /**
     * Products list filters.
     *
     * filters[FILTER_SLUG]: string - filter value.
     */
    filters: {},
};

function reducer(state, action) {
    switch (action.type) {
    case "FETCH_FILTERS_SUCCESS":
        return {
            ...state,
            init: true,
            filtersIsLoading: false,
            ...action.filters,
        };
    case "FETCH_PRODUCTS_LIST":
        return { ...state, productsListIsLoading: true };
    case "FETCH_PRODUCTS_LIST_SUCCESS":
        return { ...state, productsListIsLoading: false, productsList: action.productsList };
    case "SET_OPTION_VALUE":
        return {
            ...state,
            options: {
                ...state.options,
                page: 1,
                [action.option]: action.value,
                skip: action.option === "page" ? (action.value - 1) * state.options?.limit : 0,
            },
        };
    case "SET_FILTER_VALUE":
        return {
            ...state,
            options: { ...state.options, page: 1, skip: 0 },
            filters: { ...state.filters, [action.filter]: action.value },
        };
    case "RESET_FILTERS":
        return {
            ...state,
            categories: [],
            colors: [],
            price: {},
            options: { ...state.options, page: 1, skip: 0 },
            filters: {},
        };
    case "RESET":
        return state.init ? initialState : state;
    default:
        throw new Error();
    }
}

function init(state) {
    const [options, filters] = parseQuery(window.location.search);
    return {
        ...state,
        options:
            Object.keys(options).length === 0
                ? state.options
                : { ...options, skip: (options.page - 1) * options.limit },
        filters: Object.keys(filters).length === 0 ? state.filters : filters,
    };
}

function ShopPageCategoryDynamic({
    columns, hideCategory = true, sidebarPosition = "start", viewMode = "grid",
}) {
    const { categorySlug: paramsCategorySlug, subCategorySlug: paramsSubCategorySlug } = useParams();
    const [dynamicState, setDynamicState] = useState({
        store: { loading: "idle", data: null, error: null },
        category: { loading: "idle", data: null, error: null },
    });

    useEffect(() => {
        const fetchStore = async () => axios.get("api/promo/stores/domainsource");
        if (dynamicState.store.loading === "idle") {
            setDynamicState((prev) => ({ ...prev, store: { loading: "pending", data: null, error: null } }));
            fetchStore()
                .then(({ data }) => {
                    setDynamicState((prev) => ({
                        ...prev,
                        store: { loading: "success", data, error: null },
                    }));
                })
                .catch((error) => {
                    setDynamicState((prev) => ({
                        ...prev,
                        store: { loading: "error", data: null, error },
                    }));
                });
        }
    }, [dynamicState.store.loading]);

    const storeSpecificFeatures = dynamicState?.store?.data?.data?.storeSpecificFeatures;
    const contentManagement = dynamicState?.store?.data?.data?.contentManagement ?? [];
    const sections = paramsSubCategorySlug ? [] : contentManagement?.find((x) => x.url.includes(paramsCategorySlug))?.sections ?? [];

    let categorySlug;
    if (paramsCategorySlug) {
        categorySlug = `${paramsCategorySlug}`;
    }

    if (paramsCategorySlug && paramsSubCategorySlug) {
        categorySlug = `${paramsCategorySlug}/${paramsSubCategorySlug}`;
    }

    const offcanvas = columns === 3 ? "mobile" : "always";
    const [state, dispatch] = useReducer(reducer, initialState, init);

    const isPending = dynamicState.store.loading === "pending"
        || dynamicState.category.loading === "pending"
        || state.productsListIsLoading;

    // Replace current url.
    useEffect(() => {
        const query = buildQuery(state.options, state.filters);
        const location = `${window.location.pathname}${query ? "?" : ""}${query}`;

        window.history.replaceState(null, "", location);
    }, [state.options, state.filters]);

    const hideCatalogFilter = storeSpecificFeatures?.hideCatalogFilter;
    const statePrice = state.price;

    // Load category.
    useEffect(() => {
        let canceled = false;

        if (!hideCatalogFilter && Object.keys(statePrice).length === 0) {
            const request = filterApi.getFilters();
            const request2 = filterApi.getFilterProducts(state.options, {
                ...state.filters,
                category: paramsSubCategorySlug ?? paramsCategorySlug ?? categorySlug,
            });

            dispatch({ type: "RESET", categorySlug });

            setDynamicState((prev) => ({
                ...prev,
                category: { loading: "pending", error: null, data: null },
            }));

            request.then((filters) => {
                setDynamicState((prev) => ({
                    ...prev,
                    category: {
                        loading: "success",
                        error: null,
                        data: filters?.categories?.find((x) => x?.url?.includes(paramsCategorySlug)),
                    },
                }));
                if (canceled) {
                    return;
                }

                request2.then((filters2) => {
                    const combineFilters = {
                        ...filters,
                        categories: filters.categories.filter((x) => filters2.categories.includes(x.name)),
                        colors: filters2.colors,
                    };
                    dispatch({ type: "FETCH_FILTERS_SUCCESS", filters: combineFilters });
                });
            });
        } else {
            dispatch({ type: "FETCH_FILTERS_SUCCESS", filters: {} });
        }

        return () => {
            canceled = true;
        };
    }, [
        dispatch,
        categorySlug,
        hideCatalogFilter,
        statePrice,
        state.filters,
        state.options,
        paramsSubCategorySlug,
        paramsCategorySlug,
    ]);

    // Load products.
    useEffect(() => {
        let canceled = false;

        if (storeSpecificFeatures) {
            dispatch({ type: "FETCH_PRODUCTS_LIST" });
            productApi
                .getProductsList(state.options, {
                    ...state.filters,
                    category: paramsSubCategorySlug ?? paramsCategorySlug ?? categorySlug,
                })
                .then((data) => {
                    if (canceled) {
                        return;
                    }

                    const productsList = data.data;

                    dispatch({ type: "FETCH_PRODUCTS_LIST_SUCCESS", productsList });
                });
        }

        return () => {
            canceled = true;
        };
    }, [
        dispatch,
        categorySlug,
        state.options,
        state.filters,
        storeSpecificFeatures,
        paramsSubCategorySlug,
        paramsCategorySlug,
    ]);

    if (
        dynamicState.store.loading === "pending"
        || state.filtersIsLoading
        || (state.productsListIsLoading && !state.productsList)
    ) {
        return <BlockLoader fullWidth />;
    }

    const breadcrumb = [
        { title: "Home", url: url.home() },
        { title: "Shop", url: url.catalog() },
    ];
    let pageTitle = "Shop";
    let content;

    if (state.category) {
        getCategoryParents(state.category).forEach((parent) => {
            breadcrumb.push({ title: parent.name, url: url.category(parent) });
        });

        breadcrumb.push({ title: state.category.name, url: url.category(state.category) });

        pageTitle = state.category.name;
    } else if (hideCategory) {
        pageTitle = categorySlug;
    }

    const productsView = (
        <ProductsView
            isLoading={state.productsListIsLoading}
            productsList={state.productsList}
            options={state.options}
            filters={state.filters}
            dispatch={dispatch}
            layout={viewMode}
            grid={`grid-${columns}-${columns > 3 ? "full" : "sidebar"}`}
            offcanvas={offcanvas}
        />
    );

    const sidebarComponent = (
        <CategorySidebar offcanvas={offcanvas}>
            <CategorySidebarItem>
                <WidgetFilters
                    title="Filters"
                    offcanvas={offcanvas}
                    categories={state.categories}
                    colors={state.colors}
                    price={state.price}
                    tags={state.tags}
                    values={state.filters}
                    dispatch={dispatch}
                    categorySlug={categorySlug}
                    hideCategory={hideCategory}
                />
            </CategorySidebarItem>
        </CategorySidebar>
    );

    if (storeSpecificFeatures?.hideCatalogFilter) {
        content = (
            <div className="container">
                <div className="block">{productsView}</div>
            </div>
        );
    } else if (columns > 3) {
        content = (
            <div className="container">
                <div className="block">{productsView}</div>
                {sidebarComponent}
            </div>
        );
    } else {
        const sidebar = <div className="shop-layout__sidebar">{sidebarComponent}</div>;

        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === "start" && sidebar}
                    <div className="shop-layout__content">
                        <div className="block">{productsView}</div>
                    </div>
                    {sidebarPosition === "end" && sidebar}
                </div>
            </div>
        );
    }

    if (dynamicState.category.data) {
        pageTitle = dynamicState.category.data.name;
        if (paramsSubCategorySlug) {
            const subCategoryName = dynamicState.category.data.subCategory.find(
                (x) => x.id === paramsSubCategorySlug,
            )?.name;

            if (subCategoryName) {
                pageTitle = `${dynamicState.category.data.name}/${subCategoryName}`;
            }
        }
    } else if (dynamicState?.store?.data?.data?.contentManagement) {
        const foundedCategory = dynamicState?.store?.data?.data?.contentManagement?.find((x) => x.url.includes(paramsCategorySlug));
        if (foundedCategory) {
            pageTitle = foundedCategory.name;
        }

        if (paramsSubCategorySlug) {
            const foundedSubCategory = foundedCategory?.submenu?.menu?.find((x) => x.url.includes(paramsSubCategorySlug));
            if (foundedSubCategory) {
                pageTitle = `${foundedCategory.name}/${foundedSubCategory.title}`;
            }
        }
    }

    const foundedCategory = dynamicState?.store?.data?.data?.contentManagement?.find((x) => x.url.includes(paramsCategorySlug));

    if (isPending) return <BlockLoader fullWidth />;

    return (
        <React.Fragment>
            <Sections sections={sections} />

            <Helmet>
                <title>{`Shop Category Page — ${theme.name}`}</title>
            </Helmet>
            <PageHeader header={pageTitle} breadcrumb={breadcrumb} />
            {
                (typeof foundedCategory?.shopHidden !== "boolean" || !foundedCategory?.shopHidden || paramsSubCategorySlug) && (
                    content
                )
            }
        </React.Fragment>
    );
}

ShopPageCategoryDynamic.propTypes = {
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(["grid", "grid-with-features", "list"]),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(["start", "end"]),
};

const mapStateToProps = (state) => ({
    sidebarState: state.sidebar,
    page: state.category,
});

const mapDispatchToProps = () => ({
    sidebarClose,
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCategoryDynamic);
