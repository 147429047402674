// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import BlockHeader from '../shared/BlockHeader';
import ProductCard from '../shared/ProductCard';
import StroykaSlick from '../shared/StroykaSlick';

const settings = {
    infinite: false,
    slidesToShow: 3,
    speed: 500,
    rows: 2,
    slidesPerRow: 2,
};

const slickSettings = {
    'grid-4': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    'grid-4-sm': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 474,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    'grid-5': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    horizontal: {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
};

export default class BlockProductsCarousel extends Component {
    handleNextClick = () => {
        if (this.slickRef) {
            this.slickRef.slickNext();
        }
    };

    handlePrevClick = () => {
        if (this.slickRef) {
            this.slickRef.slickPrev();
        }
    };

    setSlickRef = (ref) => {
        this.slickRef = ref;
    };

    productsColumns() {
        const columns = [];
        const { rows } = this.props;
        let { products } = this.props;

        if (!products) return [];

        if (rows > 0) {
            products = products.slice();

            while (products.length > 0) {
                columns.push(products.splice(0, rows));
            }
        }

        return columns;
    }

    render() {
        const {
            layout, title, withSidebar, onGroupClick, groups, loading, rows,
        } = this.props;

        const columns = this.productsColumns().map((column, index) => {
            const products = column.map((product) => (
                <div key={product.id} className="block-products-carousel__cell">
                    {product.data && <ProductCard key={`product-card-${product.id}`} product={product.data} id={product.id} />}
                </div>
            ));

            return (
                <div key={`product-carousel-${index}`} className="block-products-carousel__column">
                    {products}
                </div>
            );
        });

        const multiLineItemsContent = this.productsColumns().flat().map((product) => (
            <div key={product.id} className="block-products-carousel__cell p-[4px]">
                {product.data && <ProductCard key={`product-card-${product.id}`} product={product.data} id={product.id} />}
            </div>
        ));
        const slidesToShow = Number.isNaN(Number(layout.split('-')[1])) ? 3 : Number(layout.split('-')[1]);

        const blockClasses = classNames('block block-products-carousel', {
            'block-products-carousel--loading': loading,
            'block-products-carousel--has-items': columns.length > 0,
        });
        const containerClasses = classNames({
            container: !withSidebar,
        });

        return (
            <div className={blockClasses} data-layout={layout} data-multi-rows={rows > 1}>
                <div className={containerClasses}>
                    <BlockHeader
                        title={title}
                        groups={groups}
                        arrows
                        onNext={this.handleNextClick}
                        onPrev={this.handlePrevClick}
                        onGroupClick={onGroupClick}
                    />

                    <div className="block-products-carousel__slider">
                        <div className="block-products-carousel__preloader" />

                        <StroykaSlick
                            ref={this.setSlickRef}
                            {...({

                                ...(rows > 1 ? {
                                    ...settings,
                                    variableWidth: false,
                                    slidesToShow,
                                    slidesToScroll: slidesToShow,
                                    slidesPerRow: slidesToShow,
                                    width: '100%',
                                    responsive: slickSettings[layout].responsive.map((item) => ({
                                        ...item,
                                        settings: {
                                            ...item.settings,
                                            slidesToScroll: item?.settings?.slidesToShow ?? slidesToShow,
                                            slidesPerRow: item?.settings?.slidesToShow ?? slidesToShow,
                                        },
                                    })),
                                } : slickSettings[layout]),
                            })}
                        >
                            {rows > 1 ? multiLineItemsContent : columns}
                        </StroykaSlick>
                    </div>
                </div>
            </div>
        );
    }
}

BlockProductsCarousel.propTypes = {
    title: PropTypes.string.isRequired,
    layout: PropTypes.oneOf(['grid-4', 'grid-4-sm', 'grid-5', 'horizontal']),
    rows: PropTypes.number,
    products: PropTypes.array,
    groups: PropTypes.array,
    withSidebar: PropTypes.bool,
    loading: PropTypes.bool,
    onGroupClick: PropTypes.func,
};

BlockProductsCarousel.defaultProps = {
    layout: 'grid-4',
    rows: 1,
    products: [],
    groups: [],
    withSidebar: false,
    loading: false,
    onGroupClick: undefined,
};
