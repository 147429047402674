import React from "react";
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from "reactstrap";

function Disclaimer({ disclaimerModal, handleDisclaimerModal }) {
    return (
        <Modal isOpen={disclaimerModal} toggle={() => handleDisclaimerModal("close")}>
            <ModalHeader toggle={() => handleDisclaimerModal("close")}>Term and Conditions</ModalHeader>
            <ModalBody>
                <h3 className="mb-8">Terms and Conditions for Online Apparel Purchases</h3>
                <ul className="space-y-6">
                    <ol>
                        <strong>Acceptance of Terms:</strong>
                        {' '}
                        By placing an order on our website, you agree to be bound by these Terms and Conditions.
                    </ol>
                    <ol className="space-y-1">
                        <strong className="text-lg">Product Information:</strong>
                        <ul className="space-y-2">
                            <li>
                                <strong>Images:</strong>
                                {' '}
                                Product images are for illustrative purposes only and may not
                                be exact representations of the product.
                            </li>
                            <li>
                                <strong>Color Accuracy:</strong>
                                {' '}
                                Due to variations in screen settings, product colors
                                may appear slightly different than in real life.
                            </li>
                            <li>
                                <strong>Product Availability:</strong>
                                {' '}
                                We reserve the right to remove or modify products
                                from our website at any time. If a product becomes unavailable after you have placed an
                                order, we will notify you and offer a refund or replacement.
                            </li>
                        </ul>
                    </ol>
                    <ol className="space-y-1">
                        <strong className="text-lg">Pricing and Payment:</strong>
                        <ul className="space-y-2">
                            <li>
                                <strong>Prices:</strong>
                                {' '}
                                All prices are listed in US Dollars ($). Prices are subject to change without notice.
                            </li>
                            <li>
                                <strong>Payment:</strong>
                                {' '}
                                We accept credit cards. All payments must be made in full before your order is processed.
                            </li>
                        </ul>
                    </ol>
                    <ol className="space-y-1">
                        <strong className="text-lg">Order Processing and Shipping:</strong>
                        {' '}
                        <ul className="space-y-2">
                            <li>
                                <strong>Order Processing:</strong>
                                {' '}
                                We aim to process all orders within 2-3 weeks. However, processing times may vary due to factors such as product availability and order volume.
                            </li>
                            <li>
                                <strong>Tracking:</strong>
                                {' '}
                                You will receive a tracking number once your order has shipped. You can use this number to track the status of your delivery.
                            </li>
                        </ul>
                    </ol>
                </ul>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => handleDisclaimerModal("close")}>
                    Cancel
                </Button>
                <Button color="primary" onClick={() => handleDisclaimerModal("accept")}>
                    Accept
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default Disclaimer;
