/* eslint-disable global-require */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link, withRouter, Redirect } from "react-router-dom";

// application
import { toast } from "react-toastify";
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import InputNumber from "../shared/InputNumber";
import PageHeader from "../shared/PageHeader";
import { cartRemoveItem, cartUpdateQuantities } from "../../store/cart";
import { Cross12Svg } from "../../svg";
import { url } from "../../services/utils";
import { calculatePartPrices, calcSubtotal, calcTotal } from "../../server/utils";
import StoreContext from "../../contexts/StoreContext";

// data stubs
import theme from "../../data/theme";

class ShopPageCart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            /** example: [{itemId: 8, value: 1}] */
            quantities: [],
            cartItems: [],
        };
    }

    findProductPartId = ({ product, labelSize, partColor } = {}) => {
        const partInventoryArray = product?.data?.inventoryData?.partInventoryArray || [];
        return partInventoryArray.find((x) => x.labelSize === labelSize && x.partColor === partColor)?.partId;
    };

    findProductPrice = ({
        product, labelSize, partColor, _orderLines, store,
    } = {}) => {
        if (!product) return 0;
        let price = product?.data?.pricingManagement?.productCost?.formulas?.[0]?.[0];

        if (typeof price === "string" && price?.includes("partPrice")) {
            try {
                const { Parser } = require("expr-eval");
                const parser = new Parser();
                const expr = parser.parse(price);
                // price = product?.data?.productData?.minimumPartPrice;

                const partArray = product?.data?.pricingData?.partArray || [];
                const partPrices = partArray.flatMap((a) => a?.partPriceArray).map((a) => a?.price);
                const validPartPrices = partPrices.filter((a) => !Number.isNaN(Number.parseFloat(a)) && Number.parseFloat(a) > 0);
                const minimumPartPrice = product?.data?.productData?.minimumPartPrice;
                const foundedMinPartPrice = Math.min(...validPartPrices, minimumPartPrice || 0);

                // if (!price) {
                price = expr.evaluate({
                    partPrice: foundedMinPartPrice,
                });
                // }
            } catch (error) {
                console.error(error);
            }
        }
        if (price && !Number.isNaN(Number(price)) && Number.parseFloat(price) > 0) return Number(price);

        if (_orderLines) {
            const productMap = _orderLines?.reduce((acc, item) => {
                if (!acc[item.productId]) {
                    acc[item.productId] = item.sizes?.reduce((sum, size) => sum + size.quantity, 0);
                } else {
                    acc[item.productId] += item.sizes?.reduce((sum, size) => sum + size.quantity, 0);
                }
                return acc;
            }, {});

            const totalQuantity = productMap[product?.data?.productData?.productId];

            const storePricing = {
                productCost: {
                    formulas: store?.pricingManagement?.productCost?.formulas?.[0],
                    xValues: store?.pricingManagement?.productCost?.xValues,
                },
            };

            if (storePricing.productCost.formulas && storePricing.productCost.xValues) {
                let quantityIndex = storePricing.productCost.xValues?.findIndex((x) => x === totalQuantity);

                if (quantityIndex === -1) {
                    quantityIndex = storePricing.productCost.xValues?.findIndex(
                        (x, i, arr) => totalQuantity > x && totalQuantity < arr[i + 1],
                    );
                }

                if (quantityIndex > storePricing.productCost.xValues?.length - 1) {
                    quantityIndex = storePricing.productCost.xValues?.length - 1;
                }

                const xValueindex = quantityIndex <= -1 ? storePricing.productCost.xValues?.length - 1 : quantityIndex;

                return Number(storePricing.productCost.formulas?.[xValueindex] || 0);
            }
        }

        if (labelSize && partColor) {
            const partId = this.findProductPartId({ product, labelSize, partColor });
            if (partId) {
                const partArray = product?.data?.pricingData?.partArray || [];

                const foundedPrice = partArray?.find((x) => x.partId === partId)?.partPriceArray?.[0]?.price;

                if (foundedPrice && !Number.isNaN(Number(foundedPrice))) return Number(foundedPrice);
            }
        }

        const minimumPartPrice = product?.data?.productData?.minimumPartPrice;
        return Number(minimumPartPrice) || 0;
    };

    componentDidMount() {
        this.setCartItems();
    }

    componentDidUpdate() {
        const { cart } = this.props;
        const { cartItems } = this.state;

        if (
            cartItems.length !== cart.items.length
            || cart.items.some((x) => !cartItems.find((y) => y.id === x.id && y.quantity === x.quantity))
        ) {
            this.setCartItems();
        }
    }

    setCartItems() {
        const { cart } = this.props;
        const { store } = this.context;

        if (store && Array.isArray(cart.items)) {
            cart.items.forEach((item, index, cartItems) => {
                const totalQuantity = cartItems.reduce(
                    (acc, item_) => (item?.product?.id === item_?.product?.id ? acc + Number(item_?.quantity ?? 0) : acc),
                    0,
                );
                const xValues = item.product?.data?.pricingManagement?.productCost?.xValues;
                let xValueIndex = xValues?.findIndex((x) => x === totalQuantity);

                if (xValueIndex === -1) {
                    xValueIndex = xValues?.findIndex(
                        (x, index) => totalQuantity > x && totalQuantity < xValues[index + 1],
                    );
                }

                if (xValueIndex === -1) {
                    xValueIndex = xValues?.length - 1;
                }

                const currentPrice = item.product?.data?.pricingManagement?.productCost?.formulas?.[0]?.[xValueIndex];
                item.currentPrice_ = Number(currentPrice ?? 0) + Number(item.sizeMarkup ?? 0)
                    || this.findProductPrice({
                        product: item.product,
                        labelSize: item.label,
                        partColor: item.color,
                        store,
                        _orderLines: cart.items,
                    });
                item.currentTotal_ = Number(item.currentPrice_ ?? 1) * Number(item.quantity ?? 1);
            });

            this.setState({
                cartItems: calculatePartPrices(cart.items, store),
            });
        }
    }

    getItemQuantity(item) {
        const { quantities } = this.state;
        const quantity = quantities.find((x) => x.itemId === item.id);

        return quantity ? quantity.value : item.quantity;
    }

    handleChangeQuantity = (item, quantity) => {
        this.setState(
            (state) => {
                const stateQuantity = state.quantities.find((x) => x.itemId === item.id);

                if (!stateQuantity) {
                    state.quantities.push({ itemId: item.id, value: quantity });
                } else {
                    stateQuantity.value = quantity;
                }

                return {
                    quantities: state.quantities,
                };
            },
            () => {
                const { cartUpdateQuantities } = this.props;
                const { quantities } = this.state;
                cartUpdateQuantities(quantities);
            },
        );
    };

    cartNeedUpdate() {
        const { quantities } = this.state;
        const { cart } = this.props;

        return (
            quantities.filter((x) => {
                const item = cart.items.find((item) => item.id === x.itemId);

                return item && item.quantity !== x.value && x.value !== "";
            }).length > 0
        );
    }

    renderItems() {
        const { cartRemoveItem } = this.props;
        const { cartItems } = this.state;
        console.log("🚀 ~ file: ShopPageCart.jsx:102 ~ ShopPageCart ~ renderItems ~ cartItems:", cartItems);
        return cartItems.map((item) => {
            let image;
            let options;
            const id = item.product?.id;
            const currentProduct = item.product?.data;
            const product = currentProduct?.productData;

            const removeButton = (id, color, label) => (
                <AsyncAction
                    action={() => cartRemoveItem(id, color, label)}
                    render={({ run, loading }) => {
                        const classes = classNames("btn btn-light btn-sm btn-svg-icon", {
                            "btn-loading": loading,
                        });

                        return (
                            <button type="button" onClick={run} className={classes}>
                                <Cross12Svg />
                            </button>
                        );
                    }}
                />
            );

            const groupByGroupIdAndSize = item.options[0].personalization.reduce((acc, curr) => {
                const size = curr.label;
                const key = `${curr.groupId}-${size}`;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(curr);
                return acc;
            }, {});

            const personalizationContent = Object.entries(groupByGroupIdAndSize).map(([key], index) => {
                const group = groupByGroupIdAndSize[key];
                const size = group?.[0]?.label;
                const sizeText = size ? `(${size})` : "";

                if (group?.[0]?.label !== item.label) return null;
                if (!group?.[0]?.value) return null;
                return (
                    <div key={sizeText} className="flex flex-col items-start w-full my-2">
                        <h6
                            style={{
                                fontWeight: 600,
                                fontSize: 15,
                                paddingBottom: 3,
                                borderBottom: "1px solid",
                            }}
                        >
                            {`${index + 1}. Personalization ${sizeText}`}
                        </h6>
                        {group.map((option) => (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    width: "100%",
                                    gap: 10,
                                }}
                            >
                                <span
                                    style={{
                                        padding: 0,
                                        fontWeight: 600,
                                        textWrap: "nowrap",
                                        display: "inline-block",
                                        whiteSpace: "nowrap",
                                        textAlign: "start",
                                        flexBasis: `${group?.reduce(
                                            (acc, curr) => Math.max(acc, curr?.name?.length),
                                            0,
                                        )}ch`,
                                    }}
                                >
                                    {option.name}
                                    {": "}
                                </span>
                                <span
                                    style={{
                                        padding: 0,
                                        display: "flex",
                                        flexGrow: 1,
                                        textAlign: "start",
                                    }}
                                >
                                    {option.value}
                                </span>
                                <span className="mobile-show">{`$${(option.price || 0).toFixed(2)}`}</span>
                            </div>
                        ))}
                    </div>
                );
            });

            return product ? (
                <tr key={item.id} className="cart-table__row">
                    {/* <td className="cart-table__column cart-table__column--image">
                            {image}
                        </td> */}
                    <td className="cart-table__column cart-table__column--product  !border-b-0 md:border-b !text-start">
                        <Link to={url.product(id)} className="cart-table__product-name">
                            {product.productName}
                        </Link>
                        <ul className="dropcart__product-options">{personalizationContent}</ul>
                    </td>
                    <td
                        className="cart-table__column cart-table__column--product !border-b-0 md:border-b !text-left cart-table__column--price before:!text-start xs-end:before:content-none before:content-[attr(data-title)]"
                        data-title="Color"
                    >
                        {item.color}
                    </td>
                    <td
                        className="cart-table__column cart-table__column--product !border-b-8 md:!border-b-0 !text-left cart-table__column--price before:!text-start xs-end:before:content-none before:content-[attr(data-title)]"
                        data-title="Size"
                    >
                        {item.label}
                    </td>
                    <td
                        className="cart-table__column cart-table__column--total before:!text-start xs-end:before:content-none before:content-[attr(data-title)]"
                        data-title="Price"
                    >
                        <div className="flex flex-col items-end justify-end gap-2 xs-end:!items-end xs-end:!justify-start">
                            <Currency
                                value={
                                    item.product?.data?.pricingManagement?.productCost?.xValues?.length > 1
                                        ? item.currentPrice_
                                        : item.price
                                          || this.findProductPrice({
                                              product: item.product,
                                              labelSize: item.label,
                                              partColor: item.color,
                                              store: this.context.store,
                                              _orderLines: this.props.cart.items,
                                          })
                                }
                            />
                            <ul className="dropcart__product-options !m-0  mobile-hide">
                                {item.options[0].personalization
                                    .filter((x) => x.label === item.label)
                                    .map(
                                        (option, index) => option.value && <li key={index}>{`$${(option.price || 0).toFixed(2)}`}</li>,
                                    )}
                            </ul>
                        </div>
                    </td>
                    <td
                        className="cart-table__column cart-table__column--quantity before:!text-start xs-end:before:content-none before:content-[attr(data-title)]"
                        data-title="Quantity"
                    >
                        <InputNumber
                            onChange={(quantity) => this.handleChangeQuantity(item, quantity)}
                            value={this.getItemQuantity(item)}
                            min={1}
                        />
                    </td>
                    <td
                        className="cart-table__column cart-table__column--total before:!text-start xs-end:before:content-none before:content-[attr(data-title)]"
                        data-title="Total"
                    >
                        <div className="flex flex-col items-end justify-end gap-2 xs-end:!items-end xs-end:!justify-start">
                            <Currency
                                value={
                                    item.product?.data?.pricingManagement?.productCost?.xValues?.length > 1
                                        ? item.currentTotal_
                                        : item.total || item.currentTotal_
                                }
                            />
                            {item?.options?.[0]?.personalization?.filter((x) => x?.label === item?.label && x.value)
                                ?.length > 0 && (
                                <ul className="dropcart__product-options mobile-hide">
                                    $
                                    {item.options[0].personalization
                                        .filter((x) => x?.label === item?.label && x?.value)
                                        .reduce((acc, item) => acc + (item.price || 0), 0)
                                        .toFixed(2)}
                                </ul>
                            )}
                        </div>
                    </td>
                    <td className="cart-table__column cart-table__column--remove !text-left">
                        {removeButton(id, item.color, item.label)}
                    </td>
                </tr>
            ) : (
                <React.Fragment />
            );
        });
    }

    renderTotals() {
        const { cart } = this.props;
        const { cartItems } = this.state;

        if (cart.extraLines.length <= 0) {
            return null;
        }

        const extraLines = cart.extraLines.map((extraLine, index) => (
            <tr key={index}>
                <th>{extraLine.title}</th>
                <td>
                    <Currency value={extraLine.price} />
                </td>
            </tr>
        ));

        return (
            <React.Fragment>
                <thead className="cart__totals-header">
                    <tr>
                        <th>Subtotal</th>
                        <td>
                            <Currency value={calcSubtotal(cartItems)} />
                        </td>
                    </tr>
                </thead>
                <tbody className="cart__totals-body">{extraLines}</tbody>
            </React.Fragment>
        );
    }

    renderCart() {
        const { store } = this.context;
        const { cart, history } = this.props;
        const { cartItems } = this.state;

        // const updateCartButton = (
        //     <AsyncAction
        //         action={() => cartUpdateQuantities(quantities)}
        //         render={({ run, loading }) => {
        //             const classes = classNames('btn btn-primary cart__update-button', {
        //                 'btn-loading': loading,
        //             });

        //             return (
        //                 <button type="button" onClick={run} className={classes} disabled={!this.cartNeedUpdate()}>
        //                     Update Cart
        //                 </button>
        //             );
        //         }}
        //     />
        // );

        const findMinQuantityForCost = (formulas, xValues) => {
            const findZeroIndex = formulas[0].findIndex((x) => x === "0");
            if (findZeroIndex !== -1) {
                return xValues[findZeroIndex + 1];
            }
            return 1;
        };

        const areCartCostsAcceptable = () => {
            let isValid = true;
            let errorMessage = "";

            cartItems.forEach((item) => {
                const { quantity } = item;
                const productCost = item?.product?.data?.pricingManagement?.productCost;
                const productName = item?.product?.data?.productData?.productName;
                if (!productCost) return;
                const { formulas, xValues } = productCost;
                let quantityIndex = xValues.findIndex((x) => x === quantity);
                if (quantityIndex === -1) {
                    quantityIndex = xValues.findIndex((x, i, arr) => quantity > x && quantity < arr[i + 1]);
                }

                if (quantityIndex > xValues.length - 1) {
                    quantityIndex = xValues.length - 1;
                }

                const cost = Number(formulas[0][quantityIndex]);

                if (cost <= 0) {
                    isValid = false;
                    const minQuantity = findMinQuantityForCost(formulas, xValues);
                    errorMessage = `The cost of ${productName} is $0.\n \n Please remove it from your cart or increase the quantity to at least ${minQuantity}.\n`;
                }
            });

            return { isValid, errorMessage };
        };

        return (
            <div className="block cart">
                <div className="container">
                    <table className="cart__table cart-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                {/* <th className="cart-table__column cart-table__column--image">Image</th> */}
                                <th className="cart-table__column cart-table__column--product">Product</th>
                                <th className="cart-table__column cart-table__column--product">Color</th>
                                <th className="cart-table__column cart-table__column--product">Size</th>
                                <th className="cart-table__column cart-table__column--price">Price</th>
                                <th className="cart-table__column cart-table__column--quantity">Quantity</th>
                                <th className="cart-table__column cart-table__column--total">Total</th>
                                <th className="cart-table__column cart-table__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">{this.renderItems()}</tbody>
                    </table>

                    <div className="flex flex-col gap-5 pt-3 my-5 md:flex-row md:justify-between">
                        <div className="cart__buttons !m-0">
                            <Link to="/shop" className="btn btn-light">
                                Continue Shopping
                            </Link>
                            {/* {updateCartButton} */}
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <table className="cart__totals">
                                    {/* {this.renderTotals()} */}
                                    <tfoot className="cart__totals-footer">
                                        <tr>
                                            <th>Cart Total</th>
                                            <td>
                                                <Currency value={calcTotal(cartItems, cart.extraLines)} />
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <button
                                    type="button"
                                    className="btn btn-primary btn-xl btn-block cart__checkout-button"
                                    disabled={!store?.isActive}
                                    onClick={() => {
                                        history.push("/shop/checkout");
                                    }}
                                >
                                    Proceed to checkout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { cart } = this.props;
        const breadcrumb = [
            { title: "Home", url: "" },
            { title: "Shopping Cart", url: "" },
        ];

        let content;

        if (cart.quantity) {
            content = this.renderCart();
        } else {
            content = (
                <div className="block block-empty">
                    <div className="container">
                        <div className="block-empty__body">
                            <div className="block-empty__message">Your shopping cart is empty!</div>
                            <div className="block-empty__actions">
                                <Link to="/" className="btn btn-primary btn-sm">
                                    Continue
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (this?.props?.location?.state?.to === "/shop/checkout") {
            return <Redirect to="/shop/checkout" />;
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Shopping Cart — ${theme.name}`}</title>
                </Helmet>

                <PageHeader header="Shopping Cart" breadcrumb={breadcrumb} />

                {content}
            </React.Fragment>
        );
    }
}

ShopPageCart.contextType = StoreContext;

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartRemoveItem,
    cartUpdateQuantities,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShopPageCart));
