import { useEffect } from "react";

export default function MainProvider({ children }) {
    const currentHost = window.location.host;
    const isIncludeFreethinkapparel = currentHost.includes("freethinkapparel");

    let payjunctionSdkUrl = "https://www.payjunction.com/trinity/js/sdk.js";
    let appSecret = "key_S8WOR6lCBdMompF9f7hBU9ahPAA39lWSHJ3QVADICgYRNwHSmCVCYiCUjaZSE56cHz_xpf_r1q0lphu2CQzgFw";

    if (!isIncludeFreethinkapparel) {
        payjunctionSdkUrl = "https://www.payjunctionlabs.com/trinity/js/sdk.js";
        appSecret = "key_V5Jyhff0nM3t42rDnMHX3o5QEBm23ZX1wZ8nFk9fFgZ0rjlw4hnPgbfwrKZkGaeYsTxeGP9XL5jfrRfZXJgacQ";
    }

    useEffect(() => {
        const script = document.createElement("script");
        script.src = payjunctionSdkUrl;
        script.async = true;

        script.onload = () => {
            console.log("PayJunction SDK script loaded");
            if (window.PayJunction) {
                window.payjunction = new window.PayJunction(appSecret);
            } else {
                console.error("PayJunction is not defined");
            }
        };

        script.onerror = (e) => {
            console.log("🚀 ~ useEffect ~ e:", e);
            console.error("Failed to load the PayJunction SDK script");
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [appSecret, payjunctionSdkUrl]);

    return children;
}
