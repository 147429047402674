/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
// react
import React, { Component, Suspense, useMemo } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// application
import StoreContext from "../../contexts/StoreContext";
import { calculatePartPrice, getProductColors, getProductSizes } from "../../server/utils";
import { cartAddItem } from "../../store/cart";
import { compareAddItem } from "../../store/compare";
import { wishlistAddItem } from "../../store/wishlist";
import AsyncAction from "./AsyncAction";
import Currency from "./Currency";
import InputNumber from "./InputNumber";
import ProductGallery from "./ProductGallery";
import { toast } from "react-toastify";
import ProductInputNumber from "./ProductInputNumber";
import { uniqueId } from "lodash";

const formatHtml = (input) => {
    if (Array.isArray(input)) {
        return input.map((x) => formatHtml(x));
    }

    return input?.replace(/\n/g, "<br />") ?? input;
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedItems: [],
            color: "",
            personalizationList: [],
            personalizationMap: new Map(),
            currentPrice: 0,
        };
    }

    findProductPartId = ({ labelSize, partColor } = {}) => {
        const partInventoryArray = this.props?.product?.data?.inventoryData?.partInventoryArray || [];
        return partInventoryArray.find((x) => x.labelSize === labelSize && x.partColor === partColor)?.partId;
    };

    findFormulasIndex = () => {
        const { product } = this.props;
        const { selectedItems } = this.state;

        const totalQuantity = selectedItems.reduce((acc, item) => acc + item.quantity, 0);

        const { xValues } = product?.data?.pricingManagement?.productCost || {};

        let quantityIndex = xValues?.findIndex((x) => x === totalQuantity);

        if (quantityIndex === -1) {
            quantityIndex = xValues?.findIndex((x, i, arr) => totalQuantity > x && totalQuantity < arr[i + 1]);
        }

        if (quantityIndex > xValues?.length - 1) {
            quantityIndex = xValues?.length - 1;
        }

        return quantityIndex <= -1 ? xValues?.length - 1 : quantityIndex;
    };

    findProductPrice = ({ labelSize, partColor } = {}) => {
        const { product } = this.props;
        const { selectedItems } = this.state;

        const formulasIndex = this.findFormulasIndex() || 0;
        let price = product?.data?.pricingManagement?.productCost?.formulas?.[0]?.[formulasIndex];

        if (typeof price === "string" && price?.includes("partPrice")) {
            try {
                const { Parser } = require("expr-eval");
                const parser = new Parser();
                const expr = parser.parse(price);

                // price = product?.data?.productData?.minimumPartPrice;

                const partArray = product?.data?.pricingData?.partArray || [];
                const partPrices = partArray.flatMap((a) => a?.partPriceArray).map((a) => a?.price);
                const validPartPrices = partPrices.filter((a) => !Number.isNaN(Number.parseFloat(a)) && Number.parseFloat(a) > 0);
                const minimumPartPrice = product?.data?.productData?.minimumPartPrice;
                const foundedMinPartPrice = Math.min(...validPartPrices, minimumPartPrice || 0);

                // if (!price) {
                price = expr.evaluate({
                    partPrice: foundedMinPartPrice,
                });
                // }
            } catch (error) {
                console.error(error);
            }
        }

        if (price && !Number.isNaN(Number(price)) && Number.parseFloat(price) > 0) return Number(price);

        const productMap = selectedItems?.reduce((acc, item) => {
            if (!acc[item.label]) {
                acc[item.label] = item.quantity;
            } else {
                acc[item.label] += item.quantity;
            }
            return acc;
        }, {});

        const totalQuantity = productMap[labelSize] || 0;

        const storePricing = {
            productCost: {
                formulas: this?.context?.store?.pricingManagement?.productCost?.formulas?.[0],
                xValues: this?.context?.store?.pricingManagement?.productCost?.xValues,
            },
        };

        if (storePricing.productCost.formulas && storePricing.productCost.xValues) {
            let quantityIndex = storePricing.productCost.xValues?.findIndex((x) => x === totalQuantity);

            if (quantityIndex === -1) {
                quantityIndex = storePricing.productCost.xValues?.findIndex(
                    (x, i, arr) => totalQuantity > x && totalQuantity < arr[i + 1]
                );
            }

            if (quantityIndex > storePricing.productCost.xValues?.length - 1) {
                quantityIndex = storePricing.productCost.xValues?.length - 1;
            }

            const xValueindex = quantityIndex <= -1 ? storePricing.productCost.xValues?.length - 1 : quantityIndex;

            return Number(storePricing.productCost.formulas?.[xValueindex] || 0);
        }

        if (labelSize && partColor) {
            const partId = this.findProductPartId({ labelSize, partColor });
            if (partId) {
                const partArray = product?.data?.pricingData?.partArray || [];

                const foundedPrice = partArray?.find((x) => x.partId === partId)?.partPriceArray?.[0]?.price;

                if (foundedPrice && !Number.isNaN(Number(foundedPrice)) && Number(foundedPrice) > 0)
                    return Number(foundedPrice);
            }
        }

        const minimumPartPrice = product?.data?.productData?.minimumPartPrice;
        return Number(minimumPartPrice) || 0;
    };

    componentDidMount() {
        const { product } = this.props;

        const partInventoryArray = product?.data?.inventoryData?.partInventoryArray || [];
        const partArray = product?.data?.pricingData?.partArray || [];

        const minimumPartPrice = product?.data?.productData?.minimumPartPrice;
        const price = product?.data?.pricingManagement?.productCost?.formulas?.[0]?.[0];

        if (price && !Number.isNaN(Number(price)) && Number.parseFloat(price) > 0) {
            this.setState({
                currentPrice: Number(price),
            });
        }

        if (product) {
            const colors = getProductColors(product?.data?.productData).filter((clr) => {
                if (product.data?.productOptions?.enableColor) {
                    return product.data?.productOptions.colors?.includes(clr.colorName);
                }
                return true;
            });

            this.setState({
                color: colors && colors.length > 0 ? colors[0]?.colorName : "",
                currentPrice: this.findProductPrice(),
            });
        }
    }

    resetSelectedItems = () => {
        this.setState({ selectedItems: [] });
    };

    handleChangeQuantity = ({ size, val }) => {
        const { selectedItems } = this.state;
        let selectedItem = selectedItems.find((x) => x.label === size.label);
        const groupId = selectedItems.length > 0 ? selectedItems[selectedItems.length - 1].groupId + 1 : 0;
        if (selectedItem) {
            selectedItem.quantity = val;
        } else {
            selectedItem = {
                ...size,
                quantity: val,
                size: size.label,
                groupId,
                uniqueId: uniqueId(),
            };
            selectedItems.push(selectedItem);
        }

        const totalQuantity = selectedItems.reduce((acc, item) => acc + item.quantity, 0);
        const xValues = this.props.product?.data?.pricingManagement?.productCost?.xValues;
        let xValueIndex = xValues?.findIndex((x) => x === totalQuantity);

        if (xValueIndex === -1) {
            xValueIndex = xValues?.findIndex((x, index) => totalQuantity > x && totalQuantity < xValues[index + 1]);
        }

        if (xValueIndex === -1) {
            xValueIndex = xValues?.length - 1;
        }

        const currentPrice = this.props.product?.data?.pricingManagement?.productCost?.formulas?.[0]?.[xValueIndex];

        this.setState({
            selectedItems: [...selectedItems],
            currentPrice:
                this.findProductPrice({
                    labelSize: size.label,
                    partColor: size.color,
                }) || currentPrice,
        });
    };

    handleChangeColor = (clr) => {
        const { color } = this.state;
        if (color === clr) return;
        this.setState({ color: clr, selectedItems: [], personalizationList: [] });
    };

    handleChangePersonalization = ({ name, value, label, personalizationId, price }) => {
        const { personalizationList } = this.state;

        const differentPersonalizationList = personalizationList.filter((p) => !(p.name === name && p.label === label));
        let samePersonalizationList = personalizationList.filter((p) => p.name === name && p.label === label);

        const getGroupIdByName = () => {
            const foundedItem = personalizationList.find((x) => x.name === name);

            if (foundedItem) return foundedItem.groupId;

            if (personalizationList.length > 0) {
                return personalizationList[personalizationList.length - 1].groupId + 1;
            }
            return 0;
        };

        const key = JSON.stringify({ label, personalizationId });

        this.setState((prevState) => {
            const copiedPersonalizationSet = new Map(prevState.personalizationMap);

            const exist = copiedPersonalizationSet.has(key);

            if (exist) {
                copiedPersonalizationSet.set(
                    key,
                    copiedPersonalizationSet.get(key).map((p) => {
                        return {
                            ...p,
                            value: p.name === name && p.label === label ? value : p.value,
                        };
                    })
                );
            } else {
                copiedPersonalizationSet.set(
                    key,
                    this.context.store.personalizationManagement.map((p, i) => {
                        return {
                            name: p.name,
                            value: p.name === name ? value : "",
                            label,
                            price: p.price,
                            groupId: i,
                            size: label,
                        };
                    })
                );
            }

            const newPersonalizationList = [];

            copiedPersonalizationSet.forEach((value) => {
                newPersonalizationList.push(...value);
            });

            return {
                personalizationMap: copiedPersonalizationSet,
                personalizationList: newPersonalizationList.filter(
                    (x) => typeof x?.value === "string" && x?.value?.trim()?.length > 0
                ),
            };
        });

        if (samePersonalizationList.length - 1 < personalizationId) {
            samePersonalizationList.push({
                name,
                value,
                label,
                price,
                groupId: getGroupIdByName(),
                size: label,
                uniqueId: uniqueId(),
            });
        }

        samePersonalizationList = samePersonalizationList.map((persItem, idx) =>
            persItem.name === name && persItem.label === label && idx === personalizationId
                ? {
                      name,
                      value,
                      label,
                      price,
                      groupId: getGroupIdByName(),
                      size: label,
                      uniqueId: uniqueId(),
                  }
                : persItem
        );

        const newPersonalizationList = [...differentPersonalizationList, ...samePersonalizationList].filter(
            (x) => typeof x?.value === "string" && x?.value?.trim()?.length > 0
        );
    };

    render() {
        const { product, layout, cartAddItem } = this.props;

        const { store } = this.context;

        const { selectedItems, color, personalizationList } = this.state;

        const { inventoryData, productData, mediaData, pricingData, personalization, productOptions } = product?.data;

        const colors = getProductColors(productData).filter((clr) => {
            if (product.data?.productOptions?.enableColor) {
                return product.data?.productOptions.colors?.includes(clr.colorName);
            }
            return true;
        });

        const filterEnabledSizes = (sizes, productOptions) =>
            sizes.filter((size) => {
                if (!productOptions?.enableSize) return true;
                return productOptions?.sizes?.includes(size.label);
            });

        const updateSizeMarkup = (sizes, productOptions) =>
            sizes.map((size) => {
                // if (!productOptions?.enableSize) return size;
                const enabledSize = productOptions?.sizes?.includes(size.label);
                const currentPriceDataFormattedMarkup =
                    productOptions?.priceDataFormatted?.find((x) => x.label === size.label)?.markup ?? 0;
                size.sizeMarkup += currentPriceDataFormattedMarkup || 0;
                return size;
            });

        const processProductSizes = ({
            productData,
            inventoryData,
            mediaData,
            pricingData,
            color,
            store,
            productOptions,
        }) => {
            const initialSizes = getProductSizes(
                productData,
                inventoryData,
                mediaData,
                pricingData,
                productOptions,
                color,
                store
            );
            const filteredSizes = filterEnabledSizes(initialSizes, productOptions);
            const finalSizes = updateSizeMarkup(filteredSizes, productOptions);
            return finalSizes;
        };

        const sizes = processProductSizes({
            productData,
            inventoryData,
            mediaData,
            pricingData,
            color,
            store,
            productOptions,
        });

        const minumumQuantity = store?.storeSpecificFeatures?.minumumQuantity || 0;
        const maximumQuantity = store?.storeSpecificFeatures?.maximumQuantity || 0;
        const totalQuantity = selectedItems.reduce((acc, item) => acc + item.quantity, 0);
        const isCloseout = productData.productPartArray?.some((part) => part.isCloseout);
        const isAllSamePrice = pricingData?.partArray.every(
            (x) =>
                calculatePartPrice(product, store, x.partPriceArray[0].price || 0, 1, 1) ===
                calculatePartPrice(product, store, pricingData?.partArray[0].partPriceArray[0].price || 0, 1, 1)
        );

        console.log(personalizationList);

        const isQuantityValid = maximumQuantity ? totalQuantity <= maximumQuantity : true;
        const isWithinRange = totalQuantity >= minumumQuantity;
        const isQuantityInRange = isQuantityValid && isWithinRange;

        const findMinQuantityForCost = (formulas, xValues) => {
            const findZeroIndex = formulas[0].findIndex((x) => x === "0");
            if (findZeroIndex !== -1) {
                return xValues[findZeroIndex + 1];
            }
            return 1;
        };

        const currencyFormatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
        });

        console.log({
            cartItems: this.props.cart.items,
            selectedItems,
        });

        const partArray = product?.data?.pricingData?.partArray || [];
        const partPrices = partArray.flatMap((a) => a?.partPriceArray).map((a) => a?.price);
        const validPartPrices = partPrices.filter(
            (a) => !Number.isNaN(Number.parseFloat(a)) && Number.parseFloat(a) > 0
        );
        const minimumPartPrice = product?.data?.productData?.minimumPartPrice;
        const calculateFoundedMinPartPrice = (product, validPartPrices, minimumPartPrice) => {
            let foundedMinPartPrice = minimumPartPrice;

            let price = product?.data?.pricingManagement?.productCost?.formulas?.[0]?.[0];

            if (typeof price === "string" && price?.includes("partPrice")) {
                foundedMinPartPrice = Math.min(...validPartPrices, minimumPartPrice || 0);
                const { Parser } = require("expr-eval");
                const parser = new Parser();
                const expr = parser.parse(price);
                foundedMinPartPrice = expr.evaluate({
                    partPrice: foundedMinPartPrice,
                });
            }

            return foundedMinPartPrice;
        };

        return (
            <div className={`product product--layout--${layout}`}>
                <div className="product__content">
                    <ProductGallery
                        color={color}
                        layout={layout}
                        option={null}
                        product={null}
                        mediaData={mediaData}
                        productData={productData}
                        enableColors={product.data?.productOptions?.enableColor && product.data?.productOptions.colors}
                    />

                    <div className="product__info">
                        <h1 className="product__name">{productData.productName}</h1>
                        <div style={{ display: "flex", marginBottom: "10px" }}>
                            {/* {isCloseout && <div className="discontinued">Discontinued</div>} */}
                            {productOptions?.featuredProduct &&
                                productOptions?.featuredProduct.includes("Best Seller") && (
                                    <div className="discontinued" style={{ backgroundColor: "#ff3333" }}>
                                        Best Seller
                                    </div>
                                )}
                            {productOptions?.featuredProduct && productOptions?.featuredProduct.includes("New") && (
                                <div className="discontinued" style={{ backgroundColor: "#3377ff" }}>
                                    New
                                </div>
                            )}
                            {productOptions?.featuredProduct &&
                                productOptions?.featuredProduct.includes("Featured") && (
                                    <div className="discontinued" style={{ backgroundColor: "#614ba6" }}>
                                        Featured
                                    </div>
                                )}
                        </div>
                        <div
                            className="product__description"
                            dangerouslySetInnerHTML={{
                                __html: formatHtml(productData.descriptionText ?? productData?.description),
                            }}
                        />
                    </div>

                    <div className="product__sidebar">
                        <div className="product__availability">
                            Availability: <span className="text-success">In Stock</span>
                        </div>

                        {!isAllSamePrice && (
                            <ProductPrices
                                {...{
                                    product,
                                    validPartPrices,
                                    minimumPartPrice,
                                }}
                            />
                        )}

                        <form
                            className="product__options"
                            onSubmit={(e) => {
                                e.preventDefault();
                                cartAddItem(product, [{ personalization: personalizationList }], selectedItems);
                            }}
                        >
                            <div className="form-group product__option">
                                {colors && colors.length !== 0 && <div className="product__option-label">Color</div>}

                                {colors && colors.length > 0 && (
                                    <div className="colors">
                                        {colors
                                            .filter((clr) => {
                                                const sizes = getProductSizes(
                                                    productData,
                                                    inventoryData,
                                                    mediaData,
                                                    pricingData,
                                                    productOptions,
                                                    clr.colorName,
                                                    store
                                                );
                                                if (Array.isArray(sizes) && sizes > 0) {
                                                    return sizes.some((y) => y.price > 0);
                                                }
                                                return true;
                                            })
                                            .map((clr, idx) => (
                                                <button
                                                    key={idx}
                                                    type="button"
                                                    className="color-box"
                                                    style={
                                                        color === clr.colorName ? { backgroundColor: "#ffd333" } : {}
                                                    }
                                                    onClick={() => this.handleChangeColor(clr.colorName)}
                                                >
                                                    <div
                                                        className="color-circle"
                                                        style={{ backgroundColor: clr.hex }}
                                                    />
                                                    <span className="color-text">{clr.colorName}</span>
                                                </button>
                                            ))}
                                    </div>
                                )}
                            </div>

                            <div
                                className={`${
                                    product?.data?.pricingManagement?.productCost?.xValues?.length > 1
                                        ? "block"
                                        : "hidden"
                                } my-3`}
                            >
                                <div className="relative overflow-x-auto">
                                    <table className="w-full text-sm text-left text-gray-500">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                            <tr className="border-b border-gray-200">
                                                <th scope="col" className="px-3 py-3">
                                                    Quantity
                                                </th>
                                                {product?.data?.pricingManagement?.productCost?.xValues?.map(
                                                    (xValue, index, xValues) =>
                                                        product?.data?.pricingManagement?.productCost?.formulas?.[0]?.[
                                                            index
                                                        ] === "0" ? null : (
                                                            <th
                                                                scope="col"
                                                                className="px-3 py-3 border-x first:border-l-0 last:border-r-0"
                                                                key={index}
                                                            >
                                                                {index === 0
                                                                    ? `1 - ${xValues[index + 1] - 1}`
                                                                    : xValues[index + 1]
                                                                    ? `${xValues[index]} - ${xValues[index + 1] - 1}`
                                                                    : `${xValues[index]}+`}
                                                            </th>
                                                        )
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="bg-white border-b">
                                                <td className="px-3 py-2" />
                                                {product?.data?.pricingManagement?.productCost?.formulas?.[0].map(
                                                    (formula, index) =>
                                                        formula > 0 ? (
                                                            <td
                                                                className="px-3 py-2 border-x first:border-l-0 last:border-r-0"
                                                                key={index}
                                                            >
                                                                {currencyFormatter.format(formula)}
                                                            </td>
                                                        ) : null
                                                )}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="form-group product__option">
                                <div className="product__option-label">Size - Quantity</div>

                                <div className="input-radio-label">
                                    {sizes && sizes.length > 0 ? (
                                        <div className="sizes">
                                            {sizes
                                                ?.sort((a, b) => sortSizes(a.label, b.label))
                                                .map((size) => {
                                                    const isSizeAvailable =
                                                        (size.inventory > 0 ||
                                                            (size.inventory === 0 &&
                                                                store?.storeSpecificFeatures?.outOfStock)) &&
                                                        (product?.data?.pricingManagement?.productCost?.xValues
                                                            .length >= 1
                                                            ? true
                                                            : calculatePartPrice(
                                                                  product,
                                                                  store,
                                                                  size?.price || 0,
                                                                  selectedItems.find((x) => x.label === size.label)
                                                                      ?.quantity || 1,
                                                                  size?.sizeMarkup || 0
                                                              ) > 0 ||
                                                              product?.data?.productData?.minimumPartPrice > 0);

                                                    if (!isSizeAvailable) {
                                                        return (
                                                            <div key={`size-label-${size.label}`}>
                                                                <span>{size.label}</span>
                                                                <span style={{ fontSize: "smaller", color: "red" }}>
                                                                    Out of stock
                                                                </span>
                                                            </div>
                                                        );
                                                    }
                                                    return (
                                                        <div className="size-box" key={`size-label-${size.label}`}>
                                                            <span>{size.label}</span>
                                                            <Suspense fallback={<div>loading...</div>}>
                                                                <ProductInputNumber
                                                                    {...{
                                                                        selectedItems,
                                                                        size,
                                                                        handleChangeQuantity: this.handleChangeQuantity,
                                                                        store,
                                                                    }}
                                                                />
                                                            </Suspense>
                                                            {!store?.storeSpecificFeatures?.hideQuantity &&
                                                                (size.inventory !== 0 ? (
                                                                    <span style={{ fontSize: "smaller" }}>
                                                                        {size.inventory} remaining
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        style={{
                                                                            fontSize: "smaller",
                                                                            color: "red",
                                                                        }}
                                                                    >
                                                                        Out of stock
                                                                    </span>
                                                                ))}
                                                            <span style={{ fontSize: "larger", fontWeight: 600 }}>
                                                                $
                                                                {Number(
                                                                    (this.findProductPrice({
                                                                        labelSize: size.label,
                                                                        partColor: color,
                                                                    }) || this.state.currentPrice) +
                                                                        (size?.sizeMarkup || 0)
                                                                ).toFixed(2)}
                                                            </span>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    ) : (
                                        <span className="unselected-color">*Please, select a color!</span>
                                    )}
                                </div>
                            </div>

                            {selectedItems.length > 0 &&
                                personalization?.isEnable &&
                                product?.data?.personalization?.list &&
                                product?.data?.personalization?.list.length > 0 && (
                                    <div className="form-group product__option">
                                        <div className="product__option-label">Personalization</div>

                                        <div className="input-text-label">
                                            {selectedItems.map((item) => (
                                                <div className="personalization" key={item.label}>
                                                    <span
                                                        style={{
                                                            marginLeft: "10px",
                                                            marginBottom: "10px",
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {item.label} Size
                                                    </span>
                                                    <div>
                                                        {[...Array(item.quantity)].map((x, personalizationId) => (
                                                            <div style={{ margin: "10px" }} key={personalizationId}>
                                                                <span style={{ fontWeight: 500, marginLeft: "8px" }}>
                                                                    {personalizationId + 1}. Product
                                                                </span>
                                                                {/* todo */}
                                                                {product?.data?.personalization?.list
                                                                    ?.filter((p) => {
                                                                        if (store?.personalizationManagement) {
                                                                            return (
                                                                                store.personalizationManagement.find(
                                                                                    (pm) => pm.name === p
                                                                                ) !== undefined
                                                                            );
                                                                        }
                                                                        return true;
                                                                    })
                                                                    .map((p, idx) => (
                                                                        <div className="personalization-box" key={idx}>
                                                                            <span className="personalization-name">
                                                                                {p}:
                                                                            </span>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control personalization-value"
                                                                                aria-label="Quantity"
                                                                                value={
                                                                                    // personalizationList.filter(
                                                                                    //     (x) =>
                                                                                    //         x.name === p &&
                                                                                    //         x.label === item.label &&
                                                                                    //         x.groupId === item.groupId
                                                                                    // )[personalizationId]?.value
                                                                                    this.state.personalizationMap
                                                                                        .get(
                                                                                            JSON.stringify({
                                                                                                label: item.label,
                                                                                                personalizationId,
                                                                                            })
                                                                                        )
                                                                                        ?.find((x) => x.name === p)
                                                                                        ?.value
                                                                                }
                                                                                placeholder="Enter personalization value"
                                                                                onChange={(e) =>
                                                                                    this.handleChangePersonalization({
                                                                                        name: p,
                                                                                        value: e.target.value,
                                                                                        label: item.label,
                                                                                        personalizationId:
                                                                                            personalizationId,
                                                                                        price: store.personalizationManagement.find(
                                                                                            (x) => x.name === p
                                                                                        )?.price,
                                                                                    })
                                                                                }
                                                                            />

                                                                            <span className="personalization-price">
                                                                                {this.state.personalizationMap
                                                                                    .get(
                                                                                        JSON.stringify({
                                                                                            label: item.label,
                                                                                            personalizationId,
                                                                                        })
                                                                                    )
                                                                                    ?.find((x) => x.name === p)
                                                                                    ?.value && (
                                                                                    <React.Fragment>
                                                                                        $
                                                                                        {(
                                                                                            store.personalizationManagement.find(
                                                                                                (x) => x.name === p
                                                                                            )?.price || 0
                                                                                        ).toFixed(2)}
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}

                            <div className="form-group product__option">
                                <div className="product__actions">
                                    <div className="product__actions-item" />
                                    <div className="product__actions-item product__actions-item--addtocart">
                                        <AsyncAction
                                            action={async () => {
                                                if (
                                                    this.state.currentPrice > 0 ||
                                                    product?.data?.pricingManagement?.productCost?.xValues.length <= 1
                                                ) {
                                                    await cartAddItem(
                                                        product,
                                                        [{ personalization: personalizationList }],
                                                        selectedItems
                                                    );
                                                    return sleep(300).then(() => this.resetSelectedItems());
                                                }
                                                const productName = productData.productName;
                                                const { formulas, xValues } =
                                                    product?.data?.pricingManagement?.productCost ?? {};
                                                const minQuantity = findMinQuantityForCost(formulas, xValues);
                                                const errorMessage = `Error, ${productName} is not added to cart. Quantity must be at least ${minQuantity}.\n`;
                                                toast.error(errorMessage, {
                                                    position: "top-right",
                                                    autoClose: 10000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                    theme: "colored",
                                                });
                                                return sleep(300);
                                            }}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    onClick={run}
                                                    disabled={
                                                        loading || selectedItems.length === 0 || !isQuantityInRange
                                                    }
                                                    className={classNames("btn btn-primary btn-lg", {
                                                        "btn-loading": loading,
                                                    })}
                                                >
                                                    Add to cart
                                                </button>
                                            )}
                                        />
                                    </div>
                                    {/* <div className="product__actions-item product__actions-item--wishlist">
                                        <AsyncAction
                                            action={() => wishlistAddItem(product)}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    data-toggle="tooltip"
                                                    title="Wishlist"
                                                    onClick={run}
                                                    className={classNames('btn btn-secondary btn-svg-icon btn-lg', {
                                                        'btn-loading': loading,
                                                    })}
                                                >
                                                    <Wishlist16Svg />
                                                </button>
                                            )}
                                        />
                                    </div> */}
                                </div>
                                {totalQuantity > 0 && !isQuantityInRange && (
                                    <span className="unselected-color">
                                        *Please, select quantity between {minumumQuantity} and {maximumQuantity}!
                                    </span>
                                )}
                            </div>
                        </form>
                    </div>

                    {/* <div className="product__footer">
                        <div className="product__tags tags">
                            <div className="tags__list">
                                {productData.categories.map((category, idx) => (
                                    <Link to="/" key={idx}>{category}</Link>
                                ))}
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}

Product.contextType = StoreContext;

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
};

Product.defaultProps = {
    layout: "standard",
};

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);

function sortSizes(a, b) {
    const sizes = [
        "XXXS",
        "XXXS/XXS",
        "XXS/XXXS",
        "XXS",
        "XXS/XS",
        "XS/XXS",
        "XS",
        "XS/S",
        "S/SX",
        "S",
        "S/M",
        "M/S",
        "M",
        "M/L",
        "L/M",
        "L",
        "XL/L",
        "L/XL",
        "XL",
        "XL/XXL",
        "XXL/XL",
        "XXL",
        "2XL",
        "XXXL/XXL",
        "3XL",
        "XXL/XXXL",
        "XXXL",
        "4XL",
    ];

    const aIdx = sizes.indexOf(a.toUpperCase());
    const bIdx = sizes.indexOf(b.toUpperCase());

    if (aIdx < 0) {
        if (!isNaN(a) && !isNaN(b)) {
            return Number(a) - Number(b);
        }
        return !isNaN(a) ? -1 : 1;
    }
    if (bIdx < 0) {
        if (!isNaN(a) && !isNaN(b)) {
            return Number(a) - Number(b);
        }
        return !isNaN(b) ? -1 : 1;
    }
    return aIdx - bIdx;
}

function ProductPrices({ product, validPartPrices, minimumPartPrice }) {
    const foundedMinPartPrice = useMemo(() => {
        let price = product?.data?.pricingManagement?.productCost?.formulas?.[0]?.[0];

        if (typeof price === "string" && price?.includes("partPrice")) {
            const minPartPrice = Math.min(...validPartPrices, minimumPartPrice || 0);
            const { Parser } = require("expr-eval");
            const parser = new Parser();
            const expr = parser.parse(price);
            return expr.evaluate({
                partPrice: minPartPrice,
            });
        }

        return minimumPartPrice;
    }, [product, validPartPrices, minimumPartPrice]);

    return (
        <div className="product__prices">
            <Currency value={foundedMinPartPrice || 0} isFrom />
        </div>
    );
}
