// react
import React, { useContext } from "react";

// application
import FooterContacts from "./FooterContacts";
import FooterLinks from "./FooterLinks";
import FooterNewsletter from "./FooterNewsletter";
import ToTop from "./ToTop";

// data stubs
import StoreContext from "../../contexts/StoreContext";

export default function Footer() {
    const { store } = useContext(StoreContext);
    const footer = store?.footerManagement;
    const storeBranding = store?.storeBranding;

    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    {footer && (
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-4">
                                <FooterContacts copyrightSection={footer.footerCopyrightSection} />
                            </div>
                            {footer.footerSectionFirst.items && footer.footerSectionFirst.items.length > 0 && (
                                <div className="col-6 col-md-3 col-lg-2">
                                    <FooterLinks title={footer.footerSectionFirst.title} items={footer.footerSectionFirst.items} />
                                </div>
                            )}
                            {footer.footerSectionSecond.items && footer.footerSectionSecond.items.length > 0 && (
                                <div className="col-6 col-md-3 col-lg-2">
                                    <FooterLinks title={footer.footerSectionSecond.title} items={footer.footerSectionSecond.items} />
                                </div>
                            )}
                            {storeBranding?.displaySocialMedia && (
                                <div className="col-12 col-md-12 col-lg-4">
                                    <FooterNewsletter socialMedia={storeBranding.socialMedia} />
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        Powered by
                        {" "}
                        <a href="https://www.createanapi.com/" rel="noopener noreferrer" target="_blank">
                            createanapi
                        </a>
                    </div>
                </div>
            </div>
            <ToTop />
        </div>
    );
}
