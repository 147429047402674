export default [
    {
        key: 'toAddress',
        title: 'Shipping Address',
        icon: 'shipping',
    },
    {
        key: 'pickup',
        title: 'Pick Up',
        icon: 'store',
    },
];
