/* eslint-disable no-param-reassign */
// react
import React, { useContext, useEffect, useState } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// application
import filterApi from 'server/real/endpoints/filters';
import axios from 'axios';
import AppLink from '../shared/AppLink';
import languages from '../../i18n';
import Megamenu from './Megamenu';
import Menu from './Menu';
import { ArrowRoundedDown9x6Svg } from '../../svg';
import StoreContext from '../../contexts/StoreContext';
import IsAuthContext from '../../contexts/IsAuthContext';

function NavLinks(props) {
    const { store } = useContext(StoreContext);
    const { isAuth } = useContext(IsAuthContext);
    const content = store?.contentManagement;

    const [dynamicState, setDynamicState] = useState({
        store: { loading: 'idle', data: null, error: null },
        category: { loading: 'idle', data: null, error: null },
        storeURL: null,
    });

    const isLoading = dynamicState.store.loading === 'pending' || dynamicState.category.loading === 'pending';

    useEffect(() => {
        const fetchStore = async () => axios.get('api/promo/stores/domainsource');
        if (dynamicState.store.loading === 'idle') {
            setDynamicState((prev) => ({ ...prev, store: { loading: 'pending', data: null, error: null } }));
            fetchStore()
                .then(({ data }) => {
                    setDynamicState((prev) => ({
                        ...prev,
                        store: { loading: 'success', data, error: null },
                        storeURL: data?.data?.primaryInformation?.storeURL,
                    }));
                })
                .catch((error) => {
                    setDynamicState((prev) => ({
                        ...prev,
                        store: { loading: 'error', data: null, error },
                    }));
                });
        }
    }, [dynamicState.store.loading]);

    useEffect(() => {
        if (dynamicState.category.loading === 'idle' && dynamicState.storeURL) {
            setDynamicState((prev) => ({
                ...prev,
                category: { loading: 'pending', data: null, error: null },
            }));
            const uri = new URL('api/promo/store/products/filters', window.location.origin);
            uri.searchParams.set('domainId', dynamicState.storeURL);
            const request = axios.get(uri.toString());
            request.then(({ data }) => {
                setDynamicState((prev) => ({
                    ...prev,
                    category: {
                        loading: 'success',
                        error: null,
                        // data: filters?.categories?.find((x) => x?.id === paramsCategorySlug),
                        data: data?.categories,
                    },
                }));
            }).catch((error) => {
                setDynamicState((prev) => ({
                    ...prev,
                    category: { loading: 'error', data: null, error },
                }));
            });
        }
    }, [dynamicState.category.loading, dynamicState.storeURL]);

    const handleMouseEnter = (event) => {
        const { locale } = props;
        const { direction } = languages[locale];

        const item = event.currentTarget;
        const megamenu = item.querySelector('.nav-links__megamenu');

        if (megamenu) {
            const container = megamenu.offsetParent;
            const containerWidth = container.getBoundingClientRect().width;
            const megamenuWidth = megamenu.getBoundingClientRect().width;
            const itemOffsetLeft = item.offsetLeft;

            if (direction === 'rtl') {
                const itemPosition = containerWidth - (
                    itemOffsetLeft + item.getBoundingClientRect().width
                );

                const megamenuPosition = Math.round(
                    Math.min(itemPosition, containerWidth - megamenuWidth),
                );

                megamenu.style.left = '';
                megamenu.style.right = `${megamenuPosition}px`;
            } else {
                const megamenuPosition = Math.round(
                    Math.min(itemOffsetLeft, containerWidth - megamenuWidth),
                );

                megamenu.style.right = '';
                megamenu.style.left = `${megamenuPosition}px`;
            }
        }
    };

    const linksList = content && content.filter((data) => data.navbarVisibility).filter((link) => !(link.url === '/shop' && !isAuth)).sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0)).map((item, index) => {
        let arrow;
        let submenu;
        let categoryTitle = item.name;

        if (dynamicState.category.data) {
            const foundedCategory = dynamicState.category.data.find((x) => x.url === item.url);
            categoryTitle = foundedCategory?.name ?? item.name;
            if (item.submenu && item.submenu.type === 'menu') {
                item.submenu.menu = item.submenu.menu.map((subItem) => {
                    const foundedSubCategory = foundedCategory?.subCategory?.find((x) => x.url === subItem.url);
                    return foundedSubCategory ? { ...subItem, title: foundedSubCategory.name } : subItem;
                });
            }
        }

        if (item.submenu) {
            arrow = <ArrowRoundedDown9x6Svg className="nav-links__arrow" />;
        }

        if (item.submenu && item.submenu.type === 'menu') {
            submenu = (
                <div className="nav-links__menu">
                    <Menu items={item.submenu.menu} />
                </div>
            );
        }

        if (item.submenu && item.submenu.type === 'megamenu') {
            submenu = (
                <div className={`nav-links__megamenu nav-links__megamenu--size--${item.submenu.menu.size}`}>
                    <Megamenu menu={item.submenu.menu} />
                </div>
            );
        }

        const classes = classNames('nav-links__item', {
            'nav-links__item--with-submenu': item.submenu,
        });

        return (
            <li key={index} className={classes} onMouseEnter={handleMouseEnter}>
                <AppLink to={(item.productPage && !item.url.startsWith('/category')) ? `/category${item.url}` : item.url} {...item.props}>
                    <span>
                        {categoryTitle}
                        {arrow}
                    </span>
                </AppLink>
                {submenu}
            </li>
        );
    });

    return (
        <ul className="nav-links__list">
            {
                (false) && new Array(5).fill(0).map((_, index) => (
                    <li key={index} className="nav-links__item nav-links__item--loading">
                        <span className="fake-svg-icon" />
                    </li>
                ))

            }
            {!isLoading && linksList}
        </ul>
    );
}

NavLinks.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(NavLinks);
