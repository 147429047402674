// react
import React, { Suspense, useEffect, useState } from "react";

// third-party
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// application
import Footer from "../footer";
import Header from "../header";
import MobileHeader from "../mobile/MobileHeader";
import MobileMenu from "../mobile/MobileMenu";
import CloseStore from "../shared/CloseStore";
import Quickview from "../shared/Quickview";

// pages
import AccountLayout from "../account/AccountLayout";
import AccountPageLogin from "../account/AccountPageLogin";
import BlogPageCategory from "../blog/BlogPageCategory";
import BlogPagePost from "../blog/BlogPagePost";
import PageCart from "../shop/ShopPageCart";
import ShopPageCategory from "../shop/ShopPageCategory";
import PageCheckout from "../shop/ShopPageCheckout";
import PageCompare from "../shop/ShopPageCompare";
import ShopPageOrderSuccess from "../shop/ShopPageOrderSuccess";
import ShopPageProduct from "../shop/ShopPageProduct";
import ShopPageTrackOrder from "../shop/ShopPageTrackOrder";
import PageWishlist from "../shop/ShopPageWishlist";
import SitePageAboutUs from "../site/SitePageAboutUs";
import SitePageComponents from "../site/SitePageComponents";
import SitePageContactUs from "../site/SitePageContactUs";
import SitePageContactUsAlt from "../site/SitePageContactUsAlt";
import SitePageFaq from "../site/SitePageFaq";
import SitePageNotFound from "../site/SitePageNotFound";
import SitePageTerms from "../site/SitePageTerms";
import SitePageTypography from "../site/SitePageTypography";

// data stubs
import theme from "../../data/theme";

import IsAuthContext from "../../contexts/IsAuthContext";
import StoreContext from "../../contexts/StoreContext";

import storeApi from "../../server/real/endpoints/stores";
import Sections from "./Sections";
import { css } from "@emotion/react";
import ShopPageCategoryDynamic from "components/shop/ShopPageCategoryDynamic";

const PrivateRoute = ({ isAuth, path, render: Component, exact = false, strict }) => {
    return (
        <Route
            exact={exact}
            path={path}
            {...(typeof strict === "boolean" ? { strict } : {})}
            render={(props) => {
                if (isAuth) {
                    return <Component {...props} />;
                }
                return <Redirect to="/account/login" />;
            }}
        />
    );
};

function Layout(props) {
    const { match, headerLayout, defaultComponent } = props;
    const [store, setStore] = useState();
    const [isAuth, setIsAuth] = useState(true);
    const [isStoreClosed, setIsStoreClosed] = useState(false);

    useEffect(() => {
        storeApi.getStore().then((response) => {
            if (response && response.data) {
                const storeData = response.data;

                storeApi
                    .getConfig()
                    .then((resp) => {
                        if (resp && resp.data) {
                            setStore({
                                ...storeData.data,
                                sizes: resp.data,
                            });
                        } else {
                            setStore(storeData.data);
                        }
                    })
                    .catch(() => {
                        setStore(storeData.data);
                    });

                localStorage.setItem("storeId", storeData.id);
                setIsStoreClosed(!storeData.data?.isActive);

                if (storeData.data.storeBranding) {
                    const root = document.documentElement;
                    const { primaryColor, secondaryColor, tertiaryColor, textColor, buttonColor, buttonTextColor } =
                        storeData.data.storeBranding;
                    if (primaryColor) {
                        root?.style.setProperty("--primary-color", primaryColor);
                    }
                    if (secondaryColor) {
                        root?.style.setProperty("--secondary-color", secondaryColor);
                    }
                    if (tertiaryColor) {
                        root?.style.setProperty("--tertiary-color", tertiaryColor);
                    }
                    if (textColor) {
                        root?.style.setProperty("--font-color", textColor);
                    }
                    if (buttonColor) {
                        root?.style.setProperty("--button-color", buttonColor);
                    }
                    if (buttonTextColor) {
                        root?.style.setProperty("--button-text-color", buttonTextColor);
                    }
                }
                if (storeData.data?.primaryInformation?.privateStore) {
                    setIsAuth(Boolean(localStorage.getItem("authorization")));
                } else {
                    setIsAuth(true);
                }
            } else {
                setStore(null);
            }
        });
    }, []);

    const onCloseStoreModal = () => {
        setIsStoreClosed(false);
    };

    return (
        <React.Fragment>
            <StoreContext.Provider value={{ store, setStore }}>
                <IsAuthContext.Provider value={{ isAuth, setIsAuth }}>
                    <Helmet>
                        <title>{theme.name}</title>
                        <meta name="description" content={theme.fullName} />
                    </Helmet>

                    <ToastContainer autoClose={5000} hideProgressBar />

                    <Quickview />

                    <MobileMenu />

                    <div className="site">
                        <header className="site__header d-lg-none">
                            <MobileHeader />
                        </header>

                        <header className="site__header d-lg-block d-none">
                            <Header layout={headerLayout} />
                        </header>

                        <div className="site__body">
                            <CloseStore
                                isStoreClosed={isStoreClosed}
                                onCloseStoreModal={onCloseStoreModal}
                                closeMessage={store?.storeMessage?.closedMessage}
                            />
                            <Switch>
                                {/* <Route exact path={`${match.path}`} component={defaultComponent} />                                 */}
                                <PrivateRoute isAuth={isAuth} exact path={`${match.path}`} render={defaultComponent} />

                                {store?.contentManagement
                                    ?.filter((x) => x.url !== "/shop" && !x.productPage)
                                    .map((x) => (
                                        <Route exact path={`${x.url}`} component={defaultComponent} key={x.url} />
                                    ))}

                                <PrivateRoute
                                    isAuth={isAuth}
                                    exact={false}
                                    // path={`/category${y.url}`}
                                    path={`/category/:categorySlug/:subCategorySlug?`}
                                    strict={false}
                                    render={(props) => {
                                        return (
                                            <Suspense fallback={<div>loading..</div>}>
                                                <ShopPageCategoryDynamic
                                                    columns={3}
                                                    viewMode="grid"
                                                    sidebarPosition="start"
                                                    hideCategory
                                                    storeSpecificFeatures={store?.storeSpecificFeatures}
                                                />
                                            </Suspense>
                                        );
                                    }}
                                />

                                {store?.contentManagement
                                    ?.filter((x) => x.productPage)
                                    ?.map((x, index) => {
                                        return (
                                            <PrivateRoute
                                                key={index}
                                                isAuth={isAuth}
                                                exact
                                                path={`${x.url}`}
                                                render={(props) => (
                                                    <div
                                                        style={{
                                                            padding: "3.625rem",
                                                        }}
                                                    >
                                                        <Sections sections={x.sections} />
                                                        {(typeof x?.shopHidden !== "boolean" || !x?.shopHidden) && (
                                                            <ShopPageCategory
                                                                {...props}
                                                                columns={3}
                                                                viewMode="grid"
                                                                sidebarPosition="start"
                                                                categorySlug={x.name ?? x?.url?.substring(1)}
                                                                hideCategory
                                                                storeSpecificFeatures={store?.storeSpecificFeatures}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            />
                                        );
                                    })}

                                {/* {store
                                    ?.contentManagement
                                    ?.filter((x) => Array.isArray(x?.submenu?.menu) && x.submenu?.menu?.length && x.submenu.menu.length > 0)
                                    ?.map((x, index) => x.submenu.menu.map((y, yIndex) => {
                                        console.log(y.url === '/category/yugioh%20cards/Dark%20Magician')
                                        return (
                                            <PrivateRoute
                                                key={`xIndex-${index}/yIndex-${yIndex}`}
                                                isAuth={isAuth}
                                                exact={false}
                                                // path={`/category${y.url}`}
                                                // path={`/category/:categorySlug/:subCategorySlug?`}
                                                path={`${y.url}`}
                                                strict={false}
                                                render={(props) => {
                                                    return (
                                                        <Suspense fallback={<div>loading..</div>}>
                                                            <Sections x={x} sections={x.sections[{1:1}]} />
                                                            {JSON.stringify(x.sections, null, 2)}
                                                            <ShopPageCategoryDynamic
                                                                {...props}
                                                                columns={3}
                                                                viewMode="grid"
                                                                sidebarPosition="start"
                                                                categorySlug={y?.url?.substring(1) ?? y.name}
                                                                hideCategory
                                                                storeSpecificFeatures={store?.storeSpecificFeatures}
                                                            />
                                                        </Suspense>
                                                    )
                                                }}
                                            />
                                        )
                                    }))} */}

                                <PrivateRoute
                                    isAuth={isAuth}
                                    exact
                                    path="/shop"
                                    render={(props) => (
                                        <ShopPageCategory
                                            columns={3}
                                            viewMode="grid"
                                            sidebarPosition="start"
                                            storeSpecificFeatures={store?.storeSpecificFeatures}
                                            {...props}
                                        />
                                    )}
                                />

                                <PrivateRoute
                                    isAuth={isAuth}
                                    exact
                                    path="/shop/products/:productSlug"
                                    render={(props) => (
                                        <ShopPageProduct
                                            {...props}
                                            layout="standard"
                                            productSlug={props.match.params.productSlug}
                                        />
                                    )}
                                />
                                {/* Following product layouts only for demonstration. */}
                                {[
                                    ["/shop/product-standard", { layout: "standard" }],
                                    ["/shop/product-columnar", { layout: "columnar" }],
                                    ["/shop/product-sidebar", { layout: "sidebar" }],
                                ].map(([url, options]) => (
                                    <Route
                                        key={url}
                                        exact
                                        path={url}
                                        render={(props) => (
                                            <ShopPageProduct
                                                {...props}
                                                {...options}
                                                productSlug="brandix-screwdriver-screw1500acc"
                                            />
                                        )}
                                    />
                                ))}

                                <PrivateRoute isAuth={isAuth} exact path="/shop/cart" render={PageCart} />
                                <PrivateRoute isAuth={isAuth} exact path="/shop/checkout" render={PageCheckout} />
                                <PrivateRoute
                                    isAuth={isAuth}
                                    exact
                                    path="/shop/checkout/success"
                                    render={ShopPageOrderSuccess}
                                />
                                <PrivateRoute isAuth={isAuth} exact path="/shop/wishlist" render={PageWishlist} />
                                <PrivateRoute isAuth={isAuth} exact path="/shop/compare" render={PageCompare} />
                                <PrivateRoute
                                    isAuth={isAuth}
                                    exact
                                    path="/shop/track-order"
                                    render={ShopPageTrackOrder}
                                />
                                <PrivateRoute
                                    isAuth={isAuth}
                                    exact
                                    path="/shop/:categorySlug"
                                    render={(props) => (
                                        <ShopPageCategory
                                            {...props}
                                            columns={3}
                                            viewMode="grid"
                                            sidebarPosition="start"
                                            categorySlug={props.match.params.categorySlug}
                                            storeSpecificFeatures={store?.storeSpecificFeatures}
                                        />
                                    )}
                                />

                                {/*
                            // Blog
                            */}
                                <Redirect exact from="/blog" to="/blog/category-classic" />
                                <Route
                                    exact
                                    path="/blog/category-classic"
                                    render={(props) => (
                                        <BlogPageCategory {...props} layout="classic" sidebarPosition="end" />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/blog/category-grid"
                                    render={(props) => (
                                        <BlogPageCategory {...props} layout="grid" sidebarPosition="end" />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/blog/category-list"
                                    render={(props) => (
                                        <BlogPageCategory {...props} layout="list" sidebarPosition="end" />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/blog/category-left-sidebar"
                                    render={(props) => (
                                        <BlogPageCategory {...props} layout="classic" sidebarPosition="start" />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/blog/post-classic"
                                    render={(props) => (
                                        <BlogPagePost {...props} layout="classic" sidebarPosition="end" />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/blog/post-full"
                                    render={(props) => <BlogPagePost {...props} layout="full" />}
                                />

                                {/*
                            // Account
                            */}
                                <Route exact path="/account/login" component={AccountPageLogin} />
                                <Route path="/account" component={AccountLayout} />

                                {/*
                            // Site
                            */}
                                <Redirect exact from="/site" to="/site/about-us" />
                                <Route exact path="/site/about-us" component={SitePageAboutUs} />
                                <Route exact path="/site/components" component={SitePageComponents} />
                                <Route exact path="/site/contact-us" component={SitePageContactUs} />
                                <Route exact path="/site/contact-us-alt" component={SitePageContactUsAlt} />
                                <Route exact path="/site/not-found" component={SitePageNotFound} />
                                <Route exact path="/site/faq" component={SitePageFaq} />
                                <Route exact path="/site/terms" component={SitePageTerms} />
                                <Route exact path="/site/typography" component={SitePageTypography} />

                                {/* <Route path="*" component={SitePageNotFound} /> */}
                            </Switch>
                        </div>

                        <footer className="site__footer">
                            <Footer />
                        </footer>
                    </div>
                </IsAuthContext.Provider>
            </StoreContext.Provider>
        </React.Fragment>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(["default", "compact"]),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType,
};

Layout.defaultProps = {
    headerLayout: "default",
};

export default Layout;
