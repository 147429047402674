// react
import React from 'react';

export default function FooterContacts({ copyrightSection }) {
    const currentHost = window.location.host;
    const isIncludeFreethinkapparel = currentHost.includes('freethinkapparel');

    const footerLogo = isIncludeFreethinkapparel
        ? `${process.env.PUBLIC_URL}/images/logos/FreeThink.png`
        : copyrightSection?.footerLogo;

    return (
        <div className="site-footer__widget footer-contacts">
            <h5 className="footer-contacts__title">Contact Us</h5>

            {copyrightSection?.footerLogo && (
                <div className="nav-panel__logo">
                    <a href={copyrightSection?.copyRightCompanyLink}>
                        {/* todo */}
                        <img src={footerLogo} className='h-[55px]' height="55px" alt="logo" />
                    </a>
                </div>
            )}

            <ul className="footer-contacts__contacts">
                {copyrightSection?.copyRightCompanyAddress && (
                    <li>
                        <i className="footer-contacts__icon fas fa-globe-americas" />
                        <span style={{ whiteSpace: 'pre', lineHeight: 1.6 }}>{copyrightSection?.copyRightCompanyAddress}</span>
                    </li>
                )}
                {copyrightSection?.copyRightCompanyEmail && (
                    <li>
                        <i className="footer-contacts__icon far fa-envelope" />
                        {copyrightSection?.copyRightCompanyEmail}
                    </li>
                )}
                {copyrightSection?.copyRightCompanyPhone && (
                    <li>
                        <i className="footer-contacts__icon fas fa-mobile-alt" />
                        {copyrightSection?.copyRightCompanyPhone}
                    </li>
                )}
                {copyrightSection?.copyRightCompanyOpenHours && (
                    <li>
                        <i className="footer-contacts__icon far fa-clock" />
                        {copyrightSection?.copyRightCompanyOpenHours}
                    </li>
                )}
            </ul>
        </div>
    );
}
