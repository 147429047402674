// react
import React, {
    useEffect, useState, useContext,
} from 'react';
import { connect } from 'react-redux';

// third-party
import { Helmet } from 'react-helmet-async';

// data stubs
import Sections from './Sections';
import StoreContext from '../../contexts/StoreContext';
import PageHeader from '../shared/PageHeader';

function HomePage() {
    const location = `${window.location.pathname}`;

    const [page, setPage] = useState();
    const { store } = useContext(StoreContext);
    useEffect(() => {
        if (!store) return;
        const content = store.contentManagement;
        if (!content) return;
        const pageData = content.filter((data) => data.url === (location === '/' ? '/home' : location));
        if (pageData.length === 0) return;
        setPage(pageData[0]);
    }, [store, location]);

    const breadcrumb = [
        { title: 'Home', url: '/' },
        { title: page?.name, url: page?.url },
    ];

    return (
        <React.Fragment>
            <Helmet>
                {store && (<title>{`${store?.primaryInformation?.storeName}`}</title>)}
            </Helmet>
            <PageHeader header={page?.name} breadcrumb={breadcrumb} />
            {page && <Sections sections={page.sections} />}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
    region: state.region,
});

export default connect(mapStateToProps)(HomePage);
