// import programData from '../database/program';
import getApi from './api';
import { GetAPIEndpoint } from '../../configuration';

function getHeaders() {
    const Authorization = `Bearer ${localStorage.getItem('access_token')}`;
    return { Authorization };
}
const storeApi = {
    getConfig: () => getApi().get(`${GetAPIEndpoint()}/api/config/portalconfig`, { headers: getHeaders() }),
    getStores: () => getApi().get(`${GetAPIEndpoint()}/api/promo/stores`, { headers: getHeaders() }),
    getStore: () => getApi().get(`${GetAPIEndpoint()}/api/promo/stores/domainsource`, { headers: getHeaders() }),
    getStoreByDomain: (domain) => getApi().get(`${GetAPIEndpoint()}/api/promo/stores/domainsource/${domain}`, { headers: getHeaders() }),
    getContent: (program, language) => {
        if (!program) return undefined;
        if (!program.content) return undefined;
        const filtered = program.content.filter((data) => data.language === language);
        if (filtered.length > 0) return filtered[0];
        return undefined;
    },
};
export default storeApi;
