export const API_ENDPOINT = '';
export const ACTION_INVALID2 = 'This action is invalid!';

export const GetAPIEndpoint = () => {
    if (API_ENDPOINT.length > 0) {
        return API_ENDPOINT;
    }
    return window.location.origin;
};

export function getHeaders() {
    const Authorization = `Bearer ${localStorage.getItem('access_token')}`;
    const Accept = 'application/json';
    return { Authorization, Accept, 'Content-Type': 'application/json' };
}

export function getStoreId() {
    const storeId = localStorage.getItem('storeId');
    return storeId;
}
