// react
import React, { useState, useContext, useEffect } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { toast } from 'react-toastify';

// application
import { useHistory } from 'react-router-dom';
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';
import IsAuthContext from "../../contexts/IsAuthContext";

export default function AccountPageLogin() {
    const { isAuth, setIsAuth } = useContext(IsAuthContext);
    const history = useHistory();
    const [password, setPassword] = useState('');
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Login', url: '' },
    ];

    useEffect(() => {
        if (isAuth) {
            history.push(`/`);
        }
    }, [isAuth]);

    const handleLogin = () => {
        axios(`/api/promo/store/validate/${password}`).then((data) => {
            if (data?.data?.success) {
                setIsAuth(true);
                localStorage.setItem("authorization", true);
            } else {
                // eslint-disable-next-line no-alert
                toast.error("Invalid password! Please, contact with admin.");
            }
        });
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Login — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Login Page" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-8 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <h3 className="card-title">Login</h3>
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="login-password">Password</label>
                                            <input
                                                id="login-password"
                                                type="password"
                                                className="form-control"
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>
                                        <button type="button" className="btn btn-primary mt-2 mt-md-3 mt-lg-4" onClick={handleLogin}>
                                            Login
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
