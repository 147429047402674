// react
import React from 'react';

function RawHTML(props) {
    const { html } = props;
    return (
        <div className="container">
            <div className="sun-editor-editable" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    );
}

export default RawHTML;
