/* eslint-disable no-unused-vars */
import capitalize from 'lodash/capitalize';
import getApi from './api';
import { GetAPIEndpoint, getStoreId } from '../../configuration';

function getHeaders() {
    const Authorization = `Bearer ${localStorage.getItem('access_token')}`;
    return { Authorization };
}
const filterApi = {
    getFilters: () => getApi().get(`${GetAPIEndpoint()}/api/promo/store/products/filters?includeFilters=true`, { headers: getHeaders() }).then((data) => data.data),
    getFilterProducts: (options = {}, filterValues = {}, filter = [], includeFilters = true) => {
        const filters = [];
        let selector = '';
        let desc = false;

        switch (options.sort) {
        case 'price_desc':
            selector = 'data.productData.minimumPartPrice';
            desc = true;
            break;
        case 'price_asc':
            selector = 'data.productData.minimumPartPrice';
            desc = false;
            break;
        case 'name_desc':
            selector = 'data.productData.productName';
            desc = true;
            break;
        case 'name_asc':
            selector = 'data.productData.productName';
            desc = false;
            break;
        default:
            break;
        }

        const {
            category, productBrand, colors, price, tags,
        } = filterValues;

        const minPrice = price && price.split('-')[0];
        const maxPrice = price && price.split('-')[1];
        const tagsArray = tags && tags.split(',');

        // eski productlar için filtreleme
        // const categoryFilterKey = 'data.productData.categories';
        // yeni productlar için filtreleme
        const categoryFilterKey = 'data.productData.selectedCategories.id';

        if (category) {
            filters.push({
                key: categoryFilterKey,
                operation: 'eq',
                values: [
                    category,
                    ...[
                        category.includes('/')
                            ? category.split('/').slice(0, 1).join('')
                            : '',
                    ].filter(Boolean),
                ],
            });
        }
        if (productBrand && productBrand.length > 0) { filters.push({ key: 'data.productData.productBrand', operation: 'contains', values: productBrand }); }
        if (colors && colors.length > 0) { filters.push({ key: 'data.productData.colors', operation: 'contains', values: colors.split(",") }); }
        // if (minPrice) { filters.push({ key: 'data.productData.minimumPartPrice', operation: 'gte', value: minPrice }); }
        if (maxPrice) { filters.push({ key: 'data.productData.minimumPartPrice', operation: 'lte', value: maxPrice.toString() }); }
        if (tagsArray && tagsArray.length > 0) { filters.push({ key: 'data.productOptions.featuredProduct', operation: 'contains', values: tagsArray }); }
        if (options.search && options.search.length > 0) { filters.push({ key: 'data.productData.productName', operation: 'contains', values: options.search }); }
        filters.push({ key: 'data.productData.minimumPartPrice', operation: 'gt', value: (minPrice ? minPrice.toString() : "0") });
        filters.push({ key: 'data.productOptions.activeProduct', operation: '=', value: 'true' });

        return getApi().get(`${GetAPIEndpoint()}/api/promo/store/filter-products?&filters=${encodeURIComponent(JSON.stringify(filters))}`, { headers: getHeaders() }).then((data) => data.data);
    },
};

export default filterApi;
