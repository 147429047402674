import React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { identity } from 'lodash';
import {
    CART_ADD_ITEM, CART_REMOVE_ITEM, CART_CLEAR_ALL, CART_UPDATE_QUANTITIES,
} from './cartActionTypes';

export function cartAddItemSuccess(product, options = [], selectedItems) {
    const Component = () => (
        // eslint-disable-next-line react/jsx-filename-extension
        <div>
            <span style={{ marginBottom: '5px' }}>
                Product &quot;
                {product?.data?.productData?.productName}
                &quot; added to cart!
            </span>
            <Link className="btn btn-primary btn-sm view-cart" to="/shop/cart">View Cart</Link>
        </div>
    );

    toast.success(Component(), { theme: 'colored' });
    return {
        type: CART_ADD_ITEM,
        product,
        options,
        selectedItems,
    };
}

export function cartRemoveItemSuccess(itemId, color, label) {
    return {
        type: CART_REMOVE_ITEM,
        itemId,
        color,
        label,
    };
}

export function cartClearAll() {
    return {
        type: CART_CLEAR_ALL,
    };
}

export function cartUpdateQuantitiesSuccess(quantities) {
    return {
        type: CART_UPDATE_QUANTITIES,
        quantities,
    };
}

export function cartAddItem(product, options = [], selectedItems) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartAddItemSuccess(product, options, selectedItems));
                resolve();
            }, 500);
        })
    );
}

export function cartRemoveItem(itemId, color, label) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartRemoveItemSuccess(itemId, color, label));
                resolve();
            }, 500);
        })
    );
}

export function cartUpdateQuantities(quantities) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateQuantitiesSuccess(quantities));
                resolve();
            }, 500);
        })
    );
}
