/* eslint-disable global-require */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
// react
import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
// third-party
import { groupBy, values } from "lodash";
import { Helmet } from "react-helmet-async";
import { Redirect, useHistory } from "react-router-dom";

// application
import { toast } from "react-toastify";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { Check9x7Svg, Cross20Svg } from "../../svg";
import Collapse from "../shared/Collapse";
import Currency from "../shared/Currency";
import DisclaimerModal from "../shared/Disclaimer";
import PageHeader from "../shared/PageHeader";
import PaymentModal from "../shared/Payment";

// data stubs
import StoreContext from "../../contexts/StoreContext";
import shipments from "../../data/shopShipments";
import theme from "../../data/theme";
import { createOrderMail } from "../../server/mail";
import orderApi from "../../server/real/endpoints/orders";
import { calcSubtotal, calcTotal, calculatePartPrices } from "../../server/utils";
import { validateShipping } from "../../services/validates";
import { cartClearAll } from "../../store/cart";
import { giftCardChange } from "../../store/giftCard";

const getKey = (item) =>
    `${item?.product?.data?.productData?.productId || item.productId || item?.product?.id}-${item.color}-${
        item.label || item.size
    }`;

function formatPersonalization(orderLines, getMap = false) {
    const uniquePersonalization = new Map();
    orderLines.forEach((orderLine) => {
        orderLine.personalization?.forEach((personalizationItem) => {
            const key = `${orderLine.productId}-${orderLine.color}-${personalizationItem.size}`;
            if (!uniquePersonalization.has(key)) {
                uniquePersonalization.set(key, personalizationItem);
            }
        });
    });
    if (getMap) return uniquePersonalization;
    return Array.from(uniquePersonalization.values());
}

function formatLines(orderLines) {
    const unique = new Map();
    const personalizationMap = formatPersonalization(orderLines, true);

    orderLines.forEach((orderLine) => {
        orderLine.sizes.forEach((sizeItem) => {
            const key = `${orderLine.productId}-${orderLine.color}-${sizeItem.size}`;
            if (!unique.has(key)) {
                unique.set(key, {
                    ...orderLine,
                    personalization: personalizationMap.get(key) ? [personalizationMap.get(key)] : [],
                });
            }
        });
    });

    return Array.from(unique.values());
}

function formateOrderLines(orderLines) {
    const uniquesOrderLinesMap = new Map();
    // eslint-disable-next-line no-restricted-syntax
    for (const line of orderLines) {
        const key = `${line?.id}-${line?.color}-${line?.size}`;
        if (!uniquesOrderLinesMap.has(key)) {
            uniquesOrderLinesMap.set(key, line);
        } else if (uniquesOrderLinesMap.get(key).personalization?.length < line.personalization?.length) {
            uniquesOrderLinesMap.set(key, line);
        }
    }

    const uniquesOrderLines = Array.from(uniquesOrderLinesMap.values());
    return uniquesOrderLines;
}

const findProductPartId = ({ product, labelSize, partColor } = {}) => {
    const partInventoryArray = product?.data?.inventoryData?.partInventoryArray || [];
    return partInventoryArray.find((x) => x.labelSize === labelSize && x.partColor === partColor)?.partId;
};

const findProductPrice = ({ product, labelSize, partColor, _orderLines, store } = {}) => {
    if (!product) return 0;
    let price = product?.data?.pricingManagement?.productCost?.formulas?.[0]?.[0];

    if (typeof price === "string" && price?.includes("partPrice")) {
        try {
            const { Parser } = require("expr-eval");
            const parser = new Parser();
            const expr = parser.parse(price);
            // price = product?.data?.productData?.minimumPartPrice;

            const partArray = product?.data?.pricingData?.partArray || [];
            const partPrices = partArray.flatMap((a) => a?.partPriceArray).map((a) => a?.price);
            const validPartPrices = partPrices.filter(
                (a) => !Number.isNaN(Number.parseFloat(a)) && Number.parseFloat(a) > 0
            );
            const minimumPartPrice = product?.data?.productData?.minimumPartPrice;
            const foundedMinPartPrice = Math.min(...validPartPrices, minimumPartPrice || 0);

            // if (!price) {
            price = expr.evaluate({
                partPrice: foundedMinPartPrice,
            });
            // }
        } catch (error) {
            console.error(error);
        }
    }

    if (price && !Number.isNaN(Number(price)) && Number.parseFloat(price) > 0) return Number(price);

    if (_orderLines) {
        const productMap = _orderLines?.reduce((acc, item) => {
            const key = `${item.productId}-${item.color}-${labelSize}`;
            if (!acc[key]) {
                acc[key] = item.sizes?.reduce((sum, size) => sum + size.quantity, 0);
            } else {
                acc[key] += item.sizes?.reduce((sum, size) => sum + size.quantity, 0);
            }
            return acc;
        }, {});

        const sizes = _orderLines
            ?.flatMap((item) =>
                item.sizes.map((size) => ({
                    ...size,
                    productId: item.productId,
                    color: item.color,
                }))
            )
            .reduce((acc, curr) => {
                const isAlreadyExist = acc.find((x) => x.productId === curr.productId && x.size === curr.size);
                if (!isAlreadyExist) {
                    acc.push(curr);
                }
                return acc;
            }, []);

        const totalQuantityByProductIdColorSize = sizes.reduce((acc, item_) => {
            const key = getKey(item_);
            if (!acc[key]) {
                acc[key] = item_.quantity;
            } else {
                acc[key] += item_.quantity;
            }
            return acc;
        }, {});

        const totalQuantity =
            totalQuantityByProductIdColorSize[
                getKey({
                    productId: product?.data?.productData?.productId,
                    color: partColor,
                    label: labelSize,
                })
            ] || 0;

        // const totalQuantity = productMap[product?.data?.productData?.productId];

        const storePricing = {
            productCost: {
                formulas: store?.pricingManagement?.productCost?.formulas?.[0],
                xValues: store?.pricingManagement?.productCost?.xValues,
            },
        };

        if (storePricing.productCost.formulas && storePricing.productCost.xValues) {
            let quantityIndex = storePricing.productCost.xValues?.findIndex((x) => x === totalQuantity);

            if (quantityIndex === -1) {
                quantityIndex = storePricing.productCost.xValues?.findIndex(
                    (x, i, arr) => totalQuantity > x && totalQuantity < arr[i + 1]
                );
            }

            if (quantityIndex > storePricing.productCost.xValues?.length - 1) {
                quantityIndex = storePricing.productCost.xValues?.length - 1;
            }

            const xValueindex = quantityIndex <= -1 ? storePricing.productCost.xValues?.length - 1 : quantityIndex;

            return Number(storePricing.productCost.formulas?.[xValueindex] || 0);
        }
    }

    if (labelSize && partColor) {
        const partId = findProductPartId({ product, labelSize, partColor });
        if (partId) {
            const partArray = product?.data?.pricingData?.partArray || [];

            const foundedPrice = partArray?.find((x) => x.partId === partId)?.partPriceArray?.[0]?.price;

            if (foundedPrice && !Number.isNaN(Number(foundedPrice))) return Number(foundedPrice);
        }
    }

    const minimumPartPrice = product?.data?.productData?.minimumPartPrice;
    return Number(minimumPartPrice) || 0;
};

function ShopPageCheckout() {
    const cart = useSelector((state) => state.cart);
    const giftCardKey = useSelector((state) => state.giftCard);
    const dispatch = useDispatch();
    const [order, setOrder] = useState({
        billingAddress: { country: "US" },
        contact: {},
        lines: [],
        shippingAddress: { country: "US" },
        shippingAmount: 0,
        status: "Processing",
        totalTax: 0,
        payment: "Credit Card",
        shippingCarrier: "",
        shippingService: "",
        totalAmount: 0,
        orderKey: "",
        note: "",
    });
    const { store } = useContext(StoreContext);
    const history = useHistory();
    const taxActive = store?.taxManagement?.tax;
    const [isSameBillingAddress, setIsSameBillingAddress] = useState(true);
    const [isAgreeTermsConditions, setIsAgreeTermsConditions] = useState(false);
    const [shippingMethodList, setShippingMethodList] = useState(null);
    const [cartItems, setCartItems] = useState([]);
    const [taxLoading, setTaxLoading] = useState(false);
    const [shippingLoading, setShippingLoading] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [disclaimerModal, setDisclaimerModal] = useState(false);
    const [shippingType, setShippingType] = useState("toAddress");
    const [giftCard, setGiftCard] = useState("");
    const [isOrderSubmitting, setIsOrderSubmitting] = useState(false);
    const validateLength = Object.keys(validateShipping(order.shippingAddress)).length === 0;

    const getOrderFundraiser = (fundraiser, item) => {
        if (fundraiser?.isEnabled) {
            let itemTotal = 0;
            let percentageAmount = 0;

            const { perItem, percentage } = fundraiser;

            if (perItem) {
                itemTotal = Number((perItem * item.quantity).toFixed(2));
            }

            if (percentage) {
                percentageAmount = Number((percentage * item.total).toFixed(2));
            }

            return {
                itemTotal,
                percentageAmount,
            };
        }
        return null;
    };

    const getOrderTotalFundraiser = (items) => {
        if (store?.fundraiser?.store) {
            const { amountPerItem, amountPerOrder, percentage } = store?.fundraiser || {};

            const itemTotal = items.reduce(
                (curr, item) =>
                    curr + ((item.fundraiser ? item.fundraiser?.itemTotal : (amountPerItem || 0) * item.quantity) || 0),
                0
            );
            const orderTotal = amountPerOrder || 0;
            const percentageAmount = items.reduce(
                (curr, item) =>
                    curr +
                    ((item.fundraiser ? item.fundraiser?.percentageAmount : (percentage || 0) * item.totalPrice) || 0),
                0
            );

            return {
                itemTotal,
                orderTotal,
                percentageAmount,
            };
        }

        return {
            itemTotal: items.reduce((acc, item) => acc + (item.fundraiser?.itemTotal || 0), 0),
            percentageAmount: items.reduce((acc, item) => acc + (item.fundraiser?.percentageAmount || 0), 0),
        };
    };

    const { data: storeData, isLoading: storeIsLoading } = useQuery({
        queryKey: ["store"],
        queryFn: async () => axios.get("/api/promo/stores/domainsource").then((res) => res.data),
    });

    const setOrderLines = () => {
        const items = calculatePartPrices(cart.items, store);
        const uniqueItems = items.filter(
            (obj, index) => index === items.findIndex((o) => obj.id === o.id && obj.color === o.color)
        );
        const orderLines = uniqueItems.map((item) => {
            const product = item.product?.data?.productData;
            const productClassType = product?.productType?.id;
            const productClassTypeString = Number.isInteger(productClassType) ? productClassType.toString() : "1";
            const enabledFilterByLabel = false;

            const filterByLabel = (x) => {
                if (!enabledFilterByLabel) return true;
                return x.label === item.label;
            };
            const personalization = item?.options?.[0]?.personalization?.filter(filterByLabel) || [];
            const designs = item.product?.data?.designs || [];
            const isCustomProduct = item.product?.data?.productData?.supplier === "custom";
            const sizes = items
                .filter((x) => x.product.id === item.product.id && x.color === item.color)
                .map((x) => ({
                    size: x.label,
                    quantity: x.quantity,
                    type: "",
                    unitPrice:
                        x.price ||
                        findProductPrice({
                            labelSize: x.label,
                            partColor: x?.color,
                            product: x?.product,
                            _orderLines: uniqueItems,
                            store,
                        }),
                    weight: isCustomProduct
                        ? Number.parseFloat(item.product?.data?.productData?.weight, 10) ?? x.weight
                        : x.weight,
                    weightUom: x.weightUom || "LB",
                    _product: x.product,
                }));
            return {
                id: item.product?.id,
                color: item.color,
                decorationType: designs && designs.length > 0 ? designs[0].locations[0].designType : "",
                decorations: designs,
                productName: product?.productName,
                description: product?.descriptionText,
                primaryImage: item.imageUrl,
                productId: product?.productId,
                sizeType: "",
                sizes,
                quantity: sizes.reduce((acc, curr) => acc + curr.quantity, 0),
                price: Number((sizes.reduce((acc, curr) => acc + curr.unitPrice, 0) || 0).toFixed(2)),
                totalPrice: Number(
                    (sizes.reduce((acc, curr) => acc + curr.quantity * curr.unitPrice, 0) || 0).toFixed(2)
                ),
                totalFee: 0,
                personalization,
                fundraiser: getOrderFundraiser(product?.fundraiser, item),
                productClassType: productClassTypeString,
            };
        });

        items.forEach((item, index, cartItems) => {
            const totalQuantity = cartItems.reduce(
                (acc, item_) => (item.product.id === item_.product.id ? acc + item_.quantity : acc),
                0
            );
            const xValues = item.product?.data?.pricingManagement?.productCost?.xValues;
            let xValueIndex = xValues?.findIndex((x) => x === totalQuantity);

            if (xValueIndex === -1) {
                xValueIndex = xValues?.findIndex((x, index) => totalQuantity > x && totalQuantity < xValues[index + 1]);
            }

            if (xValueIndex === -1) {
                xValueIndex = xValues?.length - 1;
            }

            const currentPrice = item.product?.data?.pricingManagement?.productCost?.formulas?.[0]?.[xValueIndex];

            if (typeof currentPrice === "string" && currentPrice?.includes("partPrice")) {
                try {
                    const { Parser } = require("expr-eval");
                    const parser = new Parser();
                    const expr = parser.parse(currentPrice);
                    // item.price = item?.product?.data?.productData?.minimumPartPrice;

                    const product = item?.product;
                    const partArray = product?.data?.pricingData?.partArray || [];
                    const partPrices = partArray.flatMap((a) => a?.partPriceArray).map((a) => a?.price);
                    const validPartPrices = partPrices.filter(
                        (a) => !Number.isNaN(Number.parseFloat(a)) && Number.parseFloat(a) > 0
                    );
                    const minimumPartPrice = product?.data?.productData?.minimumPartPrice;
                    const foundedMinPartPrice = Math.min(...validPartPrices, minimumPartPrice || 0);

                    // if (!item.price) {
                    item.price = expr.evaluate({
                        partPrice: foundedMinPartPrice,
                    });
                    // }
                } catch (error) {
                    console.error(error);
                }
            } else {
                item.price = Number(currentPrice ?? 0) + item.sizeMarkup;
            }

            item.total = item.currentPrice_ * item.quantity;
        });

        orderLines.forEach((item) => {
            const totalQuantity = item.quantity;

            const o = items.find((x) => x.product.id === item.id && x.color === item.color);

            const xValues = o.product?.data?.pricingManagement?.productCost?.xValues;
            let xValueIndex = xValues?.findIndex((x) => x === totalQuantity);

            if (xValueIndex === -1) {
                xValueIndex = xValues?.findIndex((x, index) => totalQuantity > x && totalQuantity < xValues[index + 1]);
            }

            if (xValueIndex === -1) {
                xValueIndex = xValues?.length - 1;
            }

            let currentPrice = o.product?.data?.pricingManagement?.productCost?.formulas?.[0]?.[xValueIndex];
            if (!currentPrice) {
                currentPrice = findProductPrice({
                    labelSize: o.label,
                    partColor: o.color,
                    product: o.product,
                    _orderLines: orderLines,
                    store,
                });
            }

            if (typeof currentPrice === "string" && currentPrice?.includes("partPrice")) {
                try {
                    const { Parser } = require("expr-eval");
                    const parser = new Parser();
                    const expr = parser.parse(currentPrice);

                    // currentPrice = o?.product?.data?.productData?.minimumPartPrice;

                    const product = o?.product;
                    const partArray = product?.data?.pricingData?.partArray || [];
                    const partPrices = partArray.flatMap((a) => a?.partPriceArray).map((a) => a?.price);
                    const validPartPrices = partPrices.filter(
                        (a) => !Number.isNaN(Number.parseFloat(a)) && Number.parseFloat(a) > 0
                    );
                    const minimumPartPrice = product?.data?.productData?.minimumPartPrice;
                    const foundedMinPartPrice = Math.min(...validPartPrices, minimumPartPrice || 0);

                    // if (!currentPrice) {
                    currentPrice = expr.evaluate({
                        partPrice: foundedMinPartPrice,
                    });
                    // }
                } catch (error) {
                    console.error(error);
                }
            }

            item.sizes = item.sizes?.map((size) => ({
                ...size,
                unitPrice:
                    Number(size.unitPrice ?? currentPrice) +
                    Number(
                        o.product?.data?.productOptions?.priceDataFormatted?.find((x) => x.label === size.size)
                            ?.markup ?? 0
                    ),
            }));
            item.totalPrice = Number(
                (item.sizes.reduce((acc, curr) => acc + curr.quantity * curr.unitPrice, 0) || 0).toFixed(2)
            );
        });

        setCartItems(items);
        setOrder((preOrderState) => ({
            ...order,
            lines: orderLines,
            totalAmount: calcTotal(formateOrderLines(items), cart.extraLines, storeData?.data),
            orderFundraiser: getOrderTotalFundraiser(orderLines),
            payment: store?.storeSpecificFeatures?.paymentMethods,
        }));
    };

    // const { isLoading: taxLoading, data: taxData } = useDebouncedQuery({
    //     queryKey: [
    //         "taxEstimate",
    //         {
    //             taxActive,
    //             isSameBillingAddress,
    //             shippingCarrier: order?.shippingCarrier,
    //             amount: order?.shippingAmount,
    //             // todo
    //         },
    //     ],
    //     queryFn: () => orderApi.getTaxEstimate({
    //         lines: order.lines,
    //         shippingAddress: order?.shippingCarrier === "Pick Up" ? order?.billingAddress : order.shippingAddress,
    //         shippingAmount: order.shippingAmount,
    //     }),
    //     enabled: !!taxActive && !!order.shippingCarrier && !!(order.billingAddress?.zip || order.shippingAddress?.zip) && !!(order.billingAddress?.state || order.shippingAddress?.state),
    //     onerror: (error) => {
    //         console.log(error);
    //         toast.error(error?.message);
    //     },
    // }, 500);

    // useEffect(() => {
    //     if (taxData) {
    //         setOrder((prev) => ({
    //             ...prev,
    //             totalTax: taxData.data.taxAmount,
    //             taxJarEstimate: taxData.data.taxJarAPIResponse,
    //         }));
    //     }
    // }, [taxData]);

    const onEstimateTax = async (amount) => {
        if (taxLoading) return;
        setTaxLoading(true);
        const pickUpKeywords = ["Pick Up", "Group Pick Up", "Store Pick-up", "pickup"];

        const storeShippingAddresses = storeData?.data?.shippingMethods?.shippingAddresses;
        const storeSalesPersonInfo = storeData?.data?.storeSalesPersonInfo;
        const isMatchForPickUpKeywords = pickUpKeywords.includes(order?.shippingCarrier);

        const shippingAddress = isMatchForPickUpKeywords
        ? {
              ...storeShippingAddresses,
              firstName: storeSalesPersonInfo?.firstName ?? "",
              lastName: storeSalesPersonInfo?.lastName ?? "",
              email: storeSalesPersonInfo?.email ?? "",
              phone: storeSalesPersonInfo?.phone ?? "",
          }
        : order?.shippingAddress;

        await orderApi

            .getTaxEstimate({
                lines: formateOrderLines(order.lines).map((orderLine) => {
                    orderLine.personalization;
                    return orderLine;
                }),
                shippingAddress: pickUpKeywords.includes(order?.shippingCarrier)
                    ? shippingAddress
                    : order?.shippingAddress || order?.billingAddress,
                shippingAmount: amount,
            })
            .then((result) => {
                setOrder({
                    ...order,
                    totalTax: result.data.taxAmount,
                    taxJarEstimate: result.data.taxJarAPIResponse,
                });
            })
            .finally(() => {
                setTaxLoading(false);
            })
            .catch((error) => {
                console.log(error);
                toast.error(error?.message);
            });
    };

    const onEstimateShippingMethods = async (shippingType) => {
        if (shippingLoading) return;
        setShippingLoading(true);
        setOrder({
            ...order,
            shippingCarrier: "",
            shippingService: "",
            shippingAmount: 0,
            totalTax: 0,
        });
        const storeShippingAddresses = storeData?.data?.shippingMethods?.shippingAddresses;
        const storeSalesPersonInfo = storeData?.data?.storeSalesPersonInfo;

        const uniquesOrderLines = formateOrderLines(order.lines);
        const pickUpKeywords = ["Pick Up", "Group Pick Up", "Store Pick-up", "pickup"];

        const isMatchForPickUpKeywords = pickUpKeywords.includes(shippingType);
        const shippingAddress = isMatchForPickUpKeywords
            ? {
                  ...storeShippingAddresses,
                  firstName: storeSalesPersonInfo?.firstName ?? "",
                  lastName: storeSalesPersonInfo?.lastName ?? "",
                  email: storeSalesPersonInfo?.email ?? "",
                  phone: storeSalesPersonInfo?.phone ?? "",
              }
            : order?.shippingAddress;
        await orderApi
            .getShippingEstimate({ lines: uniquesOrderLines, shippingAddress })
            .then(async (result) => {
                let methodList = result?.data?.shippingEstimate
                    ?.sort((a, b) => a.shippingAmount - b.shippingAmount)
                    .filter((active) => active.success === true);

                methodList = methodList
                    .filter((x) => {
                        const isPickup = pickUpKeywords.includes(x.shippingMethod);
                        return shippingType === "toAddress" ? !isPickup : isPickup;
                    })
                    .map((x) => ({ ...x, shippingAmount: Number((x.shippingAmount || 0).toFixed(2)) }));

                if (methodList && methodList.length > 0) {
                    setShippingMethodList(methodList);
                    setOrder((prev) => ({
                        ...prev,
                        shippingCarrier: methodList[0].shippingMethod,
                        shippingService: methodList[0].shippingCompany,
                        shippingAmount: methodList[0].shippingAmount,
                    }));
                }
            })
            .finally(() => {
                setShippingLoading(false);
            });
    };

    const onHandlePlaceOrder = async (orderTransaction) => {
        setIsOrderSubmitting(true);
        const customer = { data: { Reference: { shopWorksId: store?.storeSpecificFeatures?.swCustomerID } } };
        const orderLines = formateOrderLines(order.lines) || [];
        const mailData = createOrderMail(
            {
                ...order,
                lines: orderLines,
                customer,
                orderDate: new Date(),
                grandTotal:
                    calcTotal(formateOrderLines(cartItems), cart.extraLines, storeData?.data) +
                    order.shippingAmount +
                    order.totalTax -
                    (order.giftCodeBalance || 0),
                totalAmount: calcTotal(formateOrderLines(cartItems), cart.extraLines, storeData?.data),
            },
            store
        );
        orderApi
            .placeOrder({
                order: {
                    ...order,
                    contact: {
                        firstName: order.billingAddress?.firstName,
                        lastName: order.billingAddress?.lastName,
                        email: order.billingAddress?.email,
                        phone: order.billingAddress?.phone,
                    },
                    customer,
                    orderDate: new Date(),
                    orderTransaction,
                    grandTotal:
                        calcTotal(formateOrderLines(cartItems), cart.extraLines, storeData?.data) +
                        order.shippingAmount +
                        order.totalTax -
                        (order.giftCodeBalance || 0),
                    totalAmount: calcTotal(formateOrderLines(cartItems), cart.extraLines, storeData?.data),
                    lines:
                        orderLines?.map((line) => ({
                            ...line,
                            personalization: line.personalization?.map((personalization) => {
                                if (personalization && Array.isArray(personalization) && personalization.length > 0) {
                                    return personalization.map((item) => ({
                                        ...item,
                                        name: item?.name?.trim(),
                                    }));
                                }
                                return personalization;
                            }),
                        })) || [],

                    shippingAddress: ["Store Pick-up", "Pick Up", "Group Pick Up"].includes(order?.shippingCarrier)
                        ? undefined
                        : order.shippingAddress,
                },
                mailHtml: mailData,
            })
            .then((result) => {
                if (result.data?.success) {
                    dispatch(cartClearAll());
                    toast.success("Payment successful!");
                    history.push("/shop/checkout/success", {
                        orderId: result.data?.orderNumber,
                        ...order,
                        orderDate: new Date(),
                        shippingType,
                        totalAmount: calcTotal(formateOrderLines(cartItems), cart.extraLines, storeData?.data),
                    });
                } else {
                    toast.error(result.data?.message);
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error(error?.message);
            })
            .finally(() => {
                setIsOrderSubmitting(false);
            });
    };

    const onHandleApplyGiftCode = () => {
        if (!giftCard) {
            toast.error("Please enter valid gift card number.");
        } else {
            orderApi
                .applyGiftCode({
                    orderAmount: order.totalAmount + order.shippingAmount + order.totalTax,
                    code: giftCard,
                })
                .then((result) => {
                    if (result.data?.isSuccess) {
                        toast.success(result.data?.message);
                        const orderKey = result.data?.orderKey;
                        const giftCodeBalance = result.data?.appliedAmount;
                        const giftCodeRemaining = result.data?.remainingBalance;
                        dispatch(giftCardChange(orderKey));
                        setOrder({
                            ...order,
                            orderKey,
                            giftCodeBalance,
                            giftCodeRemaining,
                        });
                    } else {
                        toast.error(result.data?.message);
                    }
                });
        }
    };

    const onHandleGetGiftCode = () => {
        if (giftCardKey && order.totalAmount + order.shippingAmount + order.totalTax !== 0) {
            orderApi.getGiftCode(giftCardKey).then((result) => {
                if (result.data?.code) {
                    const blockedParts = result.data?.blockedParts;
                    const selectedPart = blockedParts.find((x) => x.orderKey === giftCardKey);
                    if (
                        selectedPart &&
                        selectedPart.totalAmount === order.totalAmount + order.shippingAmount + order.totalTax
                    ) {
                        const giftCodeRemaining = result.data?.balance - selectedPart.totalAmount;
                        setOrder({
                            ...order,
                            orderKey: giftCardKey,
                            giftCodeBalance: selectedPart.totalAmount,
                            giftCodeRemaining,
                        });
                        setGiftCard(result.data?.code);
                    } else if (
                        selectedPart &&
                        selectedPart.totalAmount !== order.totalAmount + order.shippingAmount + order.totalTax
                    ) {
                        orderApi
                            .updateGiftCode(giftCardKey, order.totalAmount + order.shippingAmount + order.totalTax)
                            .then((result) => {
                                if (result.data?.code) {
                                    const blockedParts = result.data?.blockedParts;
                                    const selectedPart = blockedParts.find((x) => x.orderKey === giftCardKey);
                                    const giftCodeRemaining = result.data?.balance - selectedPart.totalAmount;
                                    setGiftCard(result.data?.code);
                                    setOrder({
                                        ...order,
                                        orderKey: giftCardKey,
                                        giftCodeBalance: selectedPart.totalAmount,
                                        giftCodeRemaining,
                                    });
                                } else {
                                    toast.error(result.data?.message);
                                }
                            });
                    }
                } else {
                    toast.error(result.data?.message);
                }
            });
        }
    };

    const onHandleRemoveGiftCode = () => {
        orderApi.removeGiftCode({ orderKey: order.orderKey, code: giftCard }).then((result) => {
            if (result.data?.isSuccess) {
                setGiftCard("");
                dispatch(giftCardChange(""));
                setOrder({
                    ...order,
                    orderKey: null,
                    giftCodeBalance: null,
                    giftCodeRemaining: null,
                });
            } else {
                toast.error(result.data?.message);
            }
        });
    };

    const onHandlePayment = (cardInfo) => {
        const paymentAmount = Number(
            (order.totalAmount + order.shippingAmount + order.totalTax - (order.giftCodeBalance || 0)).toFixed(2)
        );

        if (window.payjunction) {
            setIsOrderSubmitting(true);

            window.payjunction
                .createToken({
                    cardNumber: cardInfo.number,
                    cardExpMonth: cardInfo.expiryMonth,
                    cardExpYear: cardInfo.expiryYear,
                    cardCvv: cardInfo.cvv,
                })
                .then((result) => {
                    if (result.tokenId) {
                        const orderTransaction = {
                            tokenId: result.tokenId,
                            // TODO
                            // order anindaki paymentAmount farkli buradan
                            // tax'li orderlar
                            paymentAmount,
                        };
                        onHandlePlaceOrder(orderTransaction);
                    } else {
                        toast.error("Invalid credit card.");
                    }
                })
                .finally(() => {
                    setIsOrderSubmitting(false);
                });
        } else {
            toast.error("Payjunction is not loaded.");
        }
    };

    const onChangeShippingAddress = (name, value) => {
        setOrder({
            ...order,
            shippingAddress: { ...order.shippingAddress, [name]: value },
            billingAddress: isSameBillingAddress
                ? { ...order.billingAddress, [name]: value }
                : { ...order.billingAddress },
        });
    };

    const onChangeBillingAddress = (name, value) => {
        setOrder((prev) => ({ ...prev, billingAddress: { ...order.billingAddress, [name]: value } }));
    };

    const onChangeIsSameBillingAddress = (value) => {
        if (value) {
            setOrder((prev) => ({ ...prev, billingAddress: order.shippingAddress }));
        }
        setIsSameBillingAddress(value);
    };

    const handleShipmentTypeChange = async (event) => {
        if (event.target.checked) {
            setShippingType(event.target.value);

            if (event.target.value === "toAddress") {
                setIsSameBillingAddress(true);
            } else {
                setIsSameBillingAddress(false);
            }
            setShippingMethodList(null);
            onEstimateShippingMethods(event.target.value);

        }
    };

    const handleShippingChange = (carrier, service, amount) => {
        if (taxLoading || shippingLoading) return;
        setOrder((prevState) => ({
            ...prevState,
            shippingCarrier: carrier,
            shippingService: service,
            shippingAmount: amount,
        }));
    };

    const handleDisclaimerModal = (state) => {
        if (state === "open") {
            setDisclaimerModal(true);
        } else if (state === "close") {
            setDisclaimerModal(false);
        } else if (state === "accept") {
            setDisclaimerModal(false);
            setIsAgreeTermsConditions(true);
        }
    };

    useEffect(() => {
        if (store && Array.isArray(cart.items)) {
            cartClearAll();
            setOrderLines();
        }
    }, [store, cart, storeIsLoading]);

    useEffect(() => {
        onHandleGetGiftCode();
    }, [giftCardKey, order.totalAmount, order.shippingAmount, order.totalTax]);

    useEffect(() => {
        if (validateLength) {
            onEstimateShippingMethods("toAddress");
        } else {
            setShippingMethodList(null);
        }
    }, [validateLength, order.shippingAddress?.zip, order.shippingAddress?.state]);

    useEffect(() => {
        if (taxActive && order.shippingCarrier) {
            // onEstimateTax(order.shippingAmount);
        }
    }, [order.shippingCarrier, taxActive, order.shippingAmount]);

    const pickUpKeywords = ["Pick Up", "Group Pick Up", "Store Pick-up"];
    const selectedAddress = pickUpKeywords.includes(order?.shippingCarrier)
        ? order?.billingAddress
        : order?.shippingAddress;
    const selectedZip = selectedAddress?.zip;
    const selectedState = selectedAddress?.state;

    useEffect(() => {
        if (
            taxActive &&
            order.shippingCarrier &&
            selectedZip &&
            selectedState &&
            selectedZip?.length === 5 &&
            selectedState?.length === 2
        ) {
            onEstimateTax(order.shippingAmount);
        } else {
            setOrder((prev) => ({
                ...prev,
                taxJarEstimate: 0,
                totalTax: 0,
                shippingAmount: 0,
            }));
        }
    }, [order.shippingCarrier, taxActive, order.shippingAmount, selectedZip, selectedState]);

    const renderTotals = () => (
        <React.Fragment>
            <tbody className="checkout__totals-subtotals">
                <tr>
                    <th>Subtotal</th>
                    <td>
                        <Currency value={calcSubtotal(cartItems, store)} />
                    </td>
                </tr>
                {console.log("order?.lines", order?.lines)}
                {order?.lines.some((item) => item.personalization && item.personalization.length > 0) && (
                    <tr>
                        <th>Personalization</th>
                        <td>
                            <Currency
                                value={Number(
                                    (
                                        formatLines(order?.lines).reduce(
                                            (total, item) =>
                                                total +
                                                (item.personalization.reduce((total, item) => total + item.price, 0) ||
                                                    0),
                                            0
                                        ) || 0
                                    ).toFixed(2)
                                )}
                            />
                        </td>
                    </tr>
                )}
                <tr>
                    <th>Shipping</th>
                    <td>
                        {shippingLoading ? (
                            <span className="loader" />
                        ) : order.shippingCarrier === "" ? (
                            "Choose method"
                        ) : (
                            <Currency value={order.shippingAmount} />
                        )}
                    </td>
                </tr>
                <tr>
                    <th>Tax</th>
                    <td>{taxLoading ? <span className="loader" /> : <Currency value={order.totalTax} />}</td>
                </tr>
                {order.orderKey && (
                    <tr>
                        <th>Gift Card</th>
                        <td>
                            <Currency value={order.giftCodeBalance} />
                        </td>
                    </tr>
                )}
                {/* {cart.extraLines.map((extraLine, index) => (
                    <tr key={index}>
                        <th>{extraLine.title}</th>
                        <td>
                            <Currency value={extraLine.price} />
                        </td>
                    </tr>
                ))} */}
            </tbody>
        </React.Fragment>
    );

    const renderCart = () => {
        const grouppedItems = values(groupBy(cartItems, (item) => item.product.id));
        console.log("🚀 ~ renderCart ~ grouppedItems:", grouppedItems);
        const items =
            Array.isArray(grouppedItems) &&
            grouppedItems.map((items, idx) => {
                const product = items[0].product?.data?.productData;
                items.forEach((item, index, cartItems) => {
                    // const totalQuantity = cartItems.reduce(
                    //     (acc, item_) => (item.product.id === item_.product.id ? acc + item_.quantity : acc),
                    //     0,
                    // );

                    const totalQuantityByProductIdColorSize = cartItems.reduce((acc, item_) => {
                        const key = getKey(item_);
                        if (!acc[key]) {
                            acc[key] = item_.quantity;
                        } else {
                            acc[key] += item_.quantity;
                        }
                        return acc;
                    }, {});

                    const totalQuantity = totalQuantityByProductIdColorSize[getKey(item)] || 0;

                    const xValues = item.product?.data?.pricingManagement?.productCost?.xValues;
                    let xValueIndex = xValues?.findIndex((x) => x === totalQuantity);

                    if (xValueIndex === -1) {
                        xValueIndex = xValues?.findIndex(
                            (x, index) => totalQuantity > x && totalQuantity < xValues[index + 1]
                        );
                    }

                    if (xValueIndex === -1) {
                        xValueIndex = xValues?.length - 1;
                    }

                    let currentPrice = item.price;

                    const storePricing = {
                        productCost: {
                            formulas: store?.pricingManagement?.productCost?.formulas?.[0],
                            xValues: store?.pricingManagement?.productCost?.xValues,
                        },
                    };

                    if (xValues?.[0] === 0 && storePricing.productCost.formulas && storePricing.productCost.xValues) {
                        let quantityIndex = storePricing.productCost.xValues?.findIndex((x) => x === totalQuantity);

                        if (quantityIndex === -1) {
                            quantityIndex = storePricing.productCost.xValues?.findIndex(
                                (x, i, arr) => totalQuantity > x && totalQuantity < arr[i + 1]
                            );
                        }

                        if (quantityIndex > storePricing.productCost.xValues?.length - 1) {
                            quantityIndex = storePricing.productCost.xValues?.length - 1;
                        }

                        const xValueindex =
                            quantityIndex <= -1 ? storePricing.productCost.xValues?.length - 1 : quantityIndex;

                        let calculatedPrice = storePricing.productCost.formulas?.[xValueindex];

                        if (!currentPrice) {
                            if (typeof calculatedPrice === "string" && calculatedPrice?.includes("partPrice")) {
                                try {
                                    const { Parser } = require("expr-eval");
                                    const parser = new Parser();
                                    const expr = parser.parse(calculatedPrice);

                                    // currentPrice = item?.product?.data?.productData?.minimumPartPrice;

                                    const product = item?.product;
                                    const partArray = product?.data?.pricingData?.partArray || [];
                                    const partPrices = partArray.flatMap((a) => a?.partPriceArray).map((a) => a?.price);
                                    const validPartPrices = partPrices.filter(
                                        (a) => !Number.isNaN(Number.parseFloat(a)) && Number.parseFloat(a) > 0
                                    );
                                    const minimumPartPrice = product?.data?.productData?.minimumPartPrice;
                                    const foundedMinPartPrice = Math.min(...validPartPrices, minimumPartPrice || 0);

                                    // if (!currentPrice) {
                                    currentPrice = expr.evaluate({
                                        partPrice: foundedMinPartPrice,
                                    });
                                    // }
                                } catch (error) {
                                    console.error(error);
                                }
                            }

                            currentPrice = Number(calculatedPrice);
                        }
                    } else {
                        let calculatedPrice =
                            item.product?.data?.pricingManagement?.productCost?.formulas?.[0]?.[xValueIndex] || 0;

                        if (typeof calculatedPrice === "string" && calculatedPrice?.includes("partPrice")) {
                            try {
                                const { Parser } = require("expr-eval");
                                const parser = new Parser();
                                const expr = parser.parse(calculatedPrice);

                                // currentPrice = item?.product?.data?.productData?.minimumPartPrice;

                                const product = item?.product;
                                const partArray = product?.data?.pricingData?.partArray || [];
                                const partPrices = partArray.flatMap((a) => a?.partPriceArray).map((a) => a?.price);
                                const validPartPrices = partPrices.filter(
                                    (a) => !Number.isNaN(Number.parseFloat(a)) && Number.parseFloat(a) > 0
                                );
                                const minimumPartPrice = product?.data?.productData?.minimumPartPrice;
                                const foundedMinPartPrice = Math.min(...validPartPrices, minimumPartPrice || 0);

                                // if (!currentPrice) {
                                currentPrice = expr.evaluate({
                                    partPrice: foundedMinPartPrice,
                                });
                                // }
                            } catch (error) {
                                console.error(error);
                            }
                        }
                        currentPrice = Number(calculatedPrice);
                    }

                    item.currentPrice_ = Number(currentPrice ?? 0) + item.sizeMarkup;
                    item.currentTotal_ = item.currentPrice_ * item.quantity;
                });
                return (
                    <React.Fragment key={idx}>
                        <tr>
                            <td colSpan={2} style={{ textAlign: "left" }}>{`- ${product.productName}`}</td>
                        </tr>
                        {items.map((item) => (
                            <tr key={item.id}>
                                <td style={{ fontSize: "small", paddingLeft: "16px" }}>
                                    {`${item.label} - ${item.color} × ${item.quantity}`}
                                </td>
                                <td>
                                    <Currency
                                        value={
                                            item.product?.data?.pricingManagement?.productCost?.xValues?.length > 1 ||
                                            store?.pricingManagement?.productCost?.xValues?.length > 1
                                                ? item.currentTotal_
                                                : item.total
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                    </React.Fragment>
                );
            });

        return (
            <table className="checkout__totals">
                <thead className="checkout__totals-header">
                    <tr>
                        <th>Product</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody className="checkout__totals-products">{items}</tbody>
                {renderTotals()}
                <tfoot className="checkout__totals-footer">
                    <tr>
                        <th>Total</th>
                        <td>
                            <Currency
                                value={
                                    calcTotal(cartItems, cart.extraLines, store) +
                                    order.shippingAmount +
                                    order.totalTax -
                                    (order.giftCodeBalance || 0)
                                }
                            />
                        </td>
                    </tr>
                </tfoot>
            </table>
        );
    };

    const renderGiftCard = () => (
        <div className="shipment-types">
            <div>
                <div className="form-group d-flex" style={{ marginBottom: "4px" }}>
                    <div className="w-100" style={{ position: "relative" }}>
                        <input
                            value={giftCard}
                            type="text"
                            id="giftCardNumber"
                            className="form-control"
                            placeholder="Gift Card Number"
                            onChange={(event) => {
                                setGiftCard(event.target.value);
                            }}
                            disabled={order.orderKey}
                        />
                        {order.orderKey && (
                            <button
                                type="button"
                                className="close gift-card__cancel"
                                data-dismiss="alert"
                                aria-label="Close"
                                onClick={onHandleRemoveGiftCode}
                            >
                                <Cross20Svg />
                            </button>
                        )}
                    </div>

                    <button
                        type="button"
                        className="ml-2 btn btn-primary"
                        onClick={onHandleApplyGiftCode}
                        disabled={order.orderKey}
                    >
                        {order.orderKey ? "Applied" : "Apply"}
                    </button>
                </div>
                <div style={{ display: "flex" }}>
                    {order.orderKey && (
                        <span style={{ marginLeft: "4px", fontSize: "smaller", color: "green" }}>
                            Gift card code applied.
                        </span>
                    )}
                    {order.giftCodeRemaining != null && (
                        <span style={{ marginLeft: "auto", fontSize: "smaller" }}>
                            <b>Remaining Balance: </b>${order.giftCodeRemaining.toFixed(2)}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );

    const renderShippingList = () => {
        const shippingMethodDom = shippingMethodList?.map((shippingItem) => {
            const renderShipping = ({ setItemRef, setContentRef }) => (
                <li className="shipment-types__item" ref={setItemRef}>
                    <label className="shipment-types__item-header">
                        <span className="shipment-types__item-radio input-radio">
                            <span className="input-radio__body">
                                <input
                                    type="radio"
                                    className="input-radio__input"
                                    name="checkout_shipping_method"
                                    value={shippingItem.shippingMethod}
                                    checked={order.shippingCarrier === shippingItem.shippingMethod}
                                    onChange={() =>
                                        handleShippingChange(
                                            shippingItem.shippingMethod,
                                            shippingItem.shippingCompany,
                                            shippingItem.shippingAmount
                                        )
                                    }
                                />
                                <span className="input-radio__circle" />
                            </span>
                        </span>
                        <span
                            className="shipment-types__item-title"
                            style={{ display: "flex", width: "100%", justifyContent: "space-between" }}
                        >
                            <span>{shippingItem.shippingMethod}</span>
                            <span>${shippingItem.shippingAmount}</span>
                        </span>
                    </label>
                    <div className="shipment-types__item-container" ref={setContentRef}>
                        <div className="shipment-types__item-description text-muted">
                            {shippingItem.shippingDescription}
                        </div>
                    </div>
                </li>
            );

            return (
                <Collapse
                    key={shippingItem.key}
                    open={order.shippingCarrier === shippingItem.key}
                    toggleClass="shipment-types__item--active"
                    render={renderShipping}
                />
            );
        });

        return (
            <div className="shipment-types">
                <div
                    style={{
                        fontWeight: 600,
                        paddingBottom: "12px",
                        marginBottom: "16px",
                        borderBottom: "1px solid #ebebeb",
                    }}
                >
                    Shipping Methods
                </div>
                {shippingLoading ? (
                    <div className="justify-align-center">
                        <span className="loader" />
                    </div>
                ) : (
                    !shippingMethodList && (
                        <div style={{ marginLeft: "5px", marginBottom: "10px" }}>
                            * Please, fill all shipping address fields.
                        </div>
                    )
                )}
                {!shippingLoading && <ul className="shipment-types__list">{shippingMethodDom}</ul>}
            </div>
        );
    };

    const renderShipmentTypeList = () => {
        const shippingCompanies = store?.shippingMethods?.shippingCompanies;
        const shippingMethods = store?.shippingMethods;

        const pickUp = (shippingCompanies?.pickUp || shippingCompanies?.groupPickUp) ?? false;
        const isHidden = !shippingMethods?.shippingCompanies?.shipStation?.serviceTypes?.filter(
            (x) => x?.carrierCode !== "UPS_Walleted"
        ).length;

        const shipmentTypes = shipments.filter((shipmentType) => {
            if (!pickUp && ["pickup", "groupPickUp"].includes(shipmentType.key)) {
                return false;
            }

            if (shipmentType.key === "toAddress" && isHidden) {
                return false;
            }

            return true;
        });
        const shipmentsDom = shipmentTypes.map((shipmentType) => {
            const renderShipment = ({ setItemRef, setContentRef }) => (
                <li className="shipment-types__item" ref={setItemRef}>
                    <label className="shipment-types__item-header">
                        <span className="shipment-types__item-radio input-radio">
                            <span className="input-radio__body">
                                <input
                                    type="radio"
                                    className="input-radio__input"
                                    name="checkout_payment_method"
                                    value={shipmentType.key}
                                    checked={shippingType === shipmentType.key}
                                    onChange={handleShipmentTypeChange}
                                />
                                <span className="input-radio__circle" />
                            </span>
                        </span>
                        <div className="items-center shipment-types__item-box" ref={setContentRef}>
                            <span>
                                <img
                                    src={`/images/icons/${shipmentType.icon}.png`}
                                    alt="icon"
                                    height="30"
                                    className="h-[30px]"
                                />
                            </span>
                            <span className="shipment-types__item-title">{shipmentType.title}</span>
                        </div>
                    </label>
                </li>
            );

            return (
                <Collapse
                    key={shipmentType.key}
                    open={shippingType === shipmentType.key}
                    toggleClass="shipment-types__item--active"
                    render={renderShipment}
                />
            );
        });

        return (
            <div className="shipment-types">
                <div
                    className="flex items-center p-[16px] text-sm text-gray-800 !border border-gray-300 !rounded-[8px] bg-[#f9fafb] my-2"
                    role="alert"
                >
                    <svg
                        className="flex-shrink-0 inline w-4 h-4 me-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                    >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>Choose your delivery option</div>
                </div>

                <ul className="grid shipment-types__list">{shipmentsDom}</ul>
            </div>
        );
    };

    if (cart.items.length < 1) {
        return <Redirect to="cart" />;
    }

    const breadcrumb = [
        { title: "Home", url: "" },
        { title: "Shopping Cart", url: "/shop/cart" },
        { title: "Checkout", url: "" },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Checkout — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Checkout" breadcrumb={breadcrumb} />
            <div className="block checkout">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6 col-xl-7">
                            {(store?.shippingMethods?.shippingCompanies?.pickUp ||
                                store?.shippingMethods?.shippingCompanies?.groupPickUp) &&
                                renderShipmentTypeList()}
                            <div className="card mb-lg-0">
                                {shippingType === "toAddress" && (
                                    <fieldset id="shipping">
                                        <div className="card-body">
                                            <h3 className="card-title">Shipping details</h3>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="checkout-first-name">
                                                        First Name
                                                        {validateShipping(order.shippingAddress)?.firstName && (
                                                            <span
                                                                style={{
                                                                    fontSize: "smaller",
                                                                    color: "red",
                                                                    marginLeft: "4px",
                                                                }}
                                                            >
                                                                {validateShipping(order.shippingAddress).firstName}
                                                            </span>
                                                        )}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="checkout-first-name"
                                                        placeholder="First Name"
                                                        value={order.shippingAddress?.firstName || ""}
                                                        onChange={(e) =>
                                                            onChangeShippingAddress("firstName", e.target.value)
                                                        }
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="checkout-last-name">
                                                        Last Name
                                                        {validateShipping(order.shippingAddress)?.lastName && (
                                                            <span
                                                                style={{
                                                                    fontSize: "smaller",
                                                                    color: "red",
                                                                    marginLeft: "4px",
                                                                }}
                                                            >
                                                                {validateShipping(order.shippingAddress).lastName}
                                                            </span>
                                                        )}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="checkout-last-name"
                                                        placeholder="Last Name"
                                                        value={order.shippingAddress?.lastName || ""}
                                                        onChange={(e) =>
                                                            onChangeShippingAddress("lastName", e.target.value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="checkout-company-name">
                                                    Company Name <span className="text-muted">(Optional)</span>
                                                    {validateShipping(order.shippingAddress)?.company && (
                                                        <span
                                                            style={{
                                                                fontSize: "smaller",
                                                                color: "red",
                                                                marginLeft: "4px",
                                                            }}
                                                        >
                                                            {validateShipping(order.shippingAddress).company}
                                                        </span>
                                                    )}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-company-name"
                                                    placeholder="Company Name"
                                                    value={order.shippingAddress?.company || ""}
                                                    onChange={(e) => onChangeShippingAddress("company", e.target.value)}
                                                />
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="checkout-email">
                                                        Email address
                                                        {validateShipping(order.shippingAddress)?.email && (
                                                            <span
                                                                style={{
                                                                    fontSize: "smaller",
                                                                    color: "red",
                                                                    marginLeft: "4px",
                                                                }}
                                                            >
                                                                {validateShipping(order.shippingAddress).email}
                                                            </span>
                                                        )}
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="checkout-email"
                                                        placeholder="Email address"
                                                        value={order.shippingAddress?.email || ""}
                                                        onChange={(e) =>
                                                            onChangeShippingAddress("email", e.target.value)
                                                        }
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="checkout-phone">
                                                        Phone
                                                        {validateShipping(order.shippingAddress)?.phone && (
                                                            <span
                                                                style={{
                                                                    fontSize: "smaller",
                                                                    color: "red",
                                                                    marginLeft: "4px",
                                                                }}
                                                            >
                                                                {validateShipping(order.shippingAddress).phone}
                                                            </span>
                                                        )}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="checkout-phone"
                                                        placeholder="Phone"
                                                        value={order.shippingAddress?.phone || ""}
                                                        onChange={(e) =>
                                                            onChangeShippingAddress("phone", e.target.value)
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="checkout-country">
                                                    Country
                                                    {validateShipping(order.shippingAddress)?.country && (
                                                        <span
                                                            style={{
                                                                fontSize: "smaller",
                                                                color: "red",
                                                                marginLeft: "4px",
                                                            }}
                                                        >
                                                            {validateShipping(order.shippingAddress).country}
                                                        </span>
                                                    )}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-country-name"
                                                    placeholder="Country"
                                                    value={order.shippingAddress?.country || ""}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="checkout-street-address">
                                                    Street Address
                                                    {validateShipping(order.shippingAddress)?.address1 && (
                                                        <span
                                                            style={{
                                                                fontSize: "smaller",
                                                                color: "red",
                                                                marginLeft: "4px",
                                                            }}
                                                        >
                                                            {validateShipping(order.shippingAddress).address1}
                                                        </span>
                                                    )}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-street-address"
                                                    placeholder="Street Address"
                                                    value={order.shippingAddress?.address1 || ""}
                                                    onChange={(e) =>
                                                        onChangeShippingAddress("address1", e.target.value)
                                                    }
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="checkout-address">
                                                    Apartment, suite, unit etc.{" "}
                                                    <span className="text-muted">(Optional)</span>
                                                    {validateShipping(order.shippingAddress)?.address2 && (
                                                        <span
                                                            style={{
                                                                fontSize: "smaller",
                                                                color: "red",
                                                                marginLeft: "4px",
                                                            }}
                                                        >
                                                            {validateShipping(order.shippingAddress).address2}
                                                        </span>
                                                    )}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-address"
                                                    value={order.shippingAddress?.address2 || ""}
                                                    onChange={(e) =>
                                                        onChangeShippingAddress("address2", e.target.value)
                                                    }
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="checkout-city">
                                                    Town / City
                                                    {validateShipping(order.shippingAddress)?.city && (
                                                        <span
                                                            style={{
                                                                fontSize: "smaller",
                                                                color: "red",
                                                                marginLeft: "4px",
                                                            }}
                                                        >
                                                            {validateShipping(order.shippingAddress).city}
                                                        </span>
                                                    )}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-city"
                                                    value={order.shippingAddress?.city || ""}
                                                    onChange={(e) => onChangeShippingAddress("city", e.target.value)}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="checkout-state">
                                                    State
                                                    {validateShipping(order.shippingAddress)?.state && (
                                                        <span
                                                            style={{
                                                                fontSize: "smaller",
                                                                color: "red",
                                                                marginLeft: "4px",
                                                            }}
                                                        >
                                                            {validateShipping(order.shippingAddress).state}
                                                        </span>
                                                    )}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-state"
                                                    value={order.shippingAddress?.state || ""}
                                                    onChange={(e) => {
                                                        const { value } = e.target;
                                                        if (value.length > 2) return;
                                                        onChangeShippingAddress("state", value?.toUpperCase());
                                                    }}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="checkout-postcode">
                                                    Postcode / ZIP
                                                    {validateShipping(order.shippingAddress)?.zip && (
                                                        <span
                                                            style={{
                                                                fontSize: "smaller",
                                                                color: "red",
                                                                marginLeft: "4px",
                                                            }}
                                                        >
                                                            {validateShipping(order.shippingAddress).zip}
                                                        </span>
                                                    )}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-postcode"
                                                    value={order.shippingAddress?.zip || ""}
                                                    onChange={(e) => onChangeShippingAddress("zip", e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="card-divider" />
                                    </fieldset>
                                )}
                                <div className="card-body">
                                    <h3 className="card-title">Billing Details</h3>

                                    {shippingType === "toAddress" && (
                                        <div className="form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input
                                                            className="input-check__input"
                                                            type="checkbox"
                                                            id="checkout-different-address"
                                                            checked={isSameBillingAddress}
                                                            onChange={(e) => {
                                                                onChangeIsSameBillingAddress(e.target.checked);
                                                            }}
                                                        />
                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon" />
                                                    </span>
                                                </span>
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="checkout-different-address"
                                                >
                                                    My billing address is the same as my shipping address
                                                </label>
                                            </div>
                                        </div>
                                    )}

                                    {!isSameBillingAddress && (
                                        <fieldset id="billing" disabled={shippingLoading}>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="checkout-first-name">
                                                        First Name
                                                        {validateShipping(order.billingAddress)?.firstName && (
                                                            <span
                                                                style={{
                                                                    fontSize: "smaller",
                                                                    color: "red",
                                                                    marginLeft: "4px",
                                                                }}
                                                            >
                                                                {validateShipping(order.billingAddress).firstName}
                                                            </span>
                                                        )}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="checkout-first-name"
                                                        placeholder="First Name"
                                                        value={order.billingAddress?.firstName || ""}
                                                        onChange={(e) =>
                                                            onChangeBillingAddress("firstName", e.target.value)
                                                        }
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="checkout-last-name">
                                                        Last Name
                                                        {validateShipping(order.billingAddress)?.lastName && (
                                                            <span
                                                                style={{
                                                                    fontSize: "smaller",
                                                                    color: "red",
                                                                    marginLeft: "4px",
                                                                }}
                                                            >
                                                                {validateShipping(order.billingAddress).lastName}
                                                            </span>
                                                        )}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="checkout-last-name"
                                                        placeholder="Last Name"
                                                        value={order.billingAddress?.lastName || ""}
                                                        onChange={(e) =>
                                                            onChangeBillingAddress("lastName", e.target.value)
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="checkout-company-name">
                                                    Company Name <span className="text-muted">(Optional)</span>
                                                    {validateShipping(order.billingAddress)?.company && (
                                                        <span
                                                            style={{
                                                                fontSize: "smaller",
                                                                color: "red",
                                                                marginLeft: "4px",
                                                            }}
                                                        >
                                                            {validateShipping(order.billingAddress).company}
                                                        </span>
                                                    )}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-company-name"
                                                    placeholder="Company Name"
                                                    value={order.billingAddress?.company || ""}
                                                    onChange={(e) => onChangeBillingAddress("company", e.target.value)}
                                                />
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="checkout-email">
                                                        Email address
                                                        {validateShipping(order.billingAddress)?.email && (
                                                            <span
                                                                style={{
                                                                    fontSize: "smaller",
                                                                    color: "red",
                                                                    marginLeft: "4px",
                                                                }}
                                                            >
                                                                {validateShipping(order.billingAddress).email}
                                                            </span>
                                                        )}
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="checkout-email"
                                                        placeholder="Email address"
                                                        value={order.billingAddress?.email || ""}
                                                        onChange={(e) =>
                                                            onChangeBillingAddress("email", e.target.value)
                                                        }
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="checkout-phone">
                                                        Phone
                                                        {validateShipping(order.billingAddress)?.phone && (
                                                            <span
                                                                style={{
                                                                    fontSize: "smaller",
                                                                    color: "red",
                                                                    marginLeft: "4px",
                                                                }}
                                                            >
                                                                {validateShipping(order.billingAddress).phone}
                                                            </span>
                                                        )}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="checkout-phone"
                                                        placeholder="Phone"
                                                        value={order.billingAddress?.phone || ""}
                                                        onChange={(e) =>
                                                            onChangeBillingAddress("phone", e.target.value)
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="checkout-country">
                                                    Country
                                                    {validateShipping(order.billingAddress)?.country && (
                                                        <span
                                                            style={{
                                                                fontSize: "smaller",
                                                                color: "red",
                                                                marginLeft: "4px",
                                                            }}
                                                        >
                                                            {validateShipping(order.billingAddress).country}
                                                        </span>
                                                    )}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-country-name"
                                                    placeholder="Country"
                                                    value={order.billingAddress?.country || ""}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="checkout-street-address">
                                                    Street Address
                                                    {validateShipping(order.billingAddress)?.address1 && (
                                                        <span
                                                            style={{
                                                                fontSize: "smaller",
                                                                color: "red",
                                                                marginLeft: "4px",
                                                            }}
                                                        >
                                                            {validateShipping(order.billingAddress).address1}
                                                        </span>
                                                    )}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-street-address"
                                                    placeholder="Street Address"
                                                    value={order.billingAddress?.address1 || ""}
                                                    onChange={(e) => onChangeBillingAddress("address1", e.target.value)}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="checkout-address">
                                                    Apartment, suite, unit etc.{" "}
                                                    <span className="text-muted">(Optional)</span>
                                                    {validateShipping(order.billingAddress)?.address2 && (
                                                        <span
                                                            style={{
                                                                fontSize: "smaller",
                                                                color: "red",
                                                                marginLeft: "4px",
                                                            }}
                                                        >
                                                            {validateShipping(order.billingAddress).address2}
                                                        </span>
                                                    )}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-address"
                                                    value={order.billingAddress?.address2 || ""}
                                                    onChange={(e) => onChangeBillingAddress("address2", e.target.value)}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="checkout-city">
                                                    Town / City
                                                    {validateShipping(order.billingAddress)?.city && (
                                                        <span
                                                            style={{
                                                                fontSize: "smaller",
                                                                color: "red",
                                                                marginLeft: "4px",
                                                            }}
                                                        >
                                                            {validateShipping(order.billingAddress).city}
                                                        </span>
                                                    )}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-city"
                                                    value={order.billingAddress?.city || ""}
                                                    onChange={(e) => onChangeBillingAddress("city", e.target.value)}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="checkout-state">
                                                    State / County
                                                    {validateShipping(order.billingAddress)?.state && (
                                                        <span
                                                            style={{
                                                                fontSize: "smaller",
                                                                color: "red",
                                                                marginLeft: "4px",
                                                            }}
                                                        >
                                                            {validateShipping(order.billingAddress).state}
                                                        </span>
                                                    )}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-state"
                                                    value={order.billingAddress?.state || ""}
                                                    onChange={(e) =>
                                                        onChangeBillingAddress("state", e.target.value?.toUpperCase())
                                                    }
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="checkout-postcode">
                                                    Postcode / ZIP
                                                    {validateShipping(order.billingAddress)?.zip && (
                                                        <span
                                                            style={{
                                                                fontSize: "smaller",
                                                                color: "red",
                                                                marginLeft: "4px",
                                                            }}
                                                        >
                                                            {validateShipping(order.billingAddress).zip}
                                                        </span>
                                                    )}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="checkout-postcode"
                                                    value={order.billingAddress?.zip || ""}
                                                    onChange={(e) => onChangeBillingAddress("zip", e.target.value)}
                                                />
                                            </div>
                                        </fieldset>
                                    )}

                                    <div className="form-group">
                                        <label htmlFor="checkout-comment">
                                            Order notes <span className="text-muted">(Optional)</span>
                                        </label>
                                        <textarea
                                            id="checkout-comment"
                                            className="form-control"
                                            rows="4"
                                            value={order.note}
                                            onChange={(e) => {
                                                setOrder({
                                                    ...order,
                                                    note: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4 col-12 col-lg-6 col-xl-5 mt-lg-0">
                            <div className="mb-0 card">
                                <div className="card-body">
                                    <h3 className="card-title">Your Order</h3>

                                    {renderShippingList()}

                                    {renderCart()}

                                    {renderGiftCard()}

                                    <div className="checkout__agree form-group">
                                        <div className="form-check">
                                            <span className="form-check-input input-check">
                                                <span className="input-check__body">
                                                    <input
                                                        className="input-check__input"
                                                        type="checkbox"
                                                        id="checkout-terms"
                                                        checked={isAgreeTermsConditions}
                                                        onChange={(e) => setIsAgreeTermsConditions(e.target.checked)}
                                                    />
                                                    <span className="input-check__box" />
                                                    <Check9x7Svg className="input-check__icon" />
                                                </span>
                                            </span>
                                            <label className="form-check-label">
                                                I have read and agree to the website
                                                <span
                                                    className="link"
                                                    onClick={() => handleDisclaimerModal("open")}
                                                    onKeyDown={() => handleDisclaimerModal("open")}
                                                    role="link"
                                                    tabIndex={0}
                                                >
                                                    {" "}
                                                    terms and conditions
                                                </span>
                                                *
                                            </label>
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-xl btn-block"
                                        disabled={
                                            isOrderSubmitting ||
                                            !isAgreeTermsConditions ||
                                            !order.shippingCarrier ||
                                            Object.keys(validateShipping(order.billingAddress)).length !== 0 ||
                                            !store?.isActive
                                        }
                                        onClick={() => {
                                            const paymentAmount = Number(
                                                (
                                                    order.totalAmount +
                                                    order.shippingAmount +
                                                    order.totalTax -
                                                    (order.giftCodeBalance || 0)
                                                ).toFixed(2)
                                            );
                                            if (paymentAmount === 0 || order.payment === "Terms") {
                                                onHandlePlaceOrder({
                                                    tokenId: null,
                                                    paymentAmount: 0,
                                                });
                                            } else {
                                                setPaymentModal(true);
                                            }
                                        }}
                                    >
                                        Place Order
                                    </button>
                                    {store?.storeMessage?.active && (
                                        <p style={{ color: "red", padding: "8px" }}>
                                            {store?.storeMessage?.closedMessage}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PaymentModal open={paymentModal} close={() => setPaymentModal(false)} handlePayment={onHandlePayment} />
            <DisclaimerModal disclaimerModal={disclaimerModal} handleDisclaimerModal={handleDisclaimerModal} />
        </React.Fragment>
    );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartClearAll,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout);
