import { GIFTCARD_CHANGE } from './giftCardActionTypes';

const initialState = '';

export default function giftCardReducer(state = initialState, action) {
    if (action.type === GIFTCARD_CHANGE) {
        return action.giftCard;
    }

    return state;
}
