export function validateShipping(values) {
    const errors = {};
    if (values?.company && values?.company.length > 50) {
        errors.company = '*Company name is longer than usual';
    }
    if (!values?.address1) {
        errors.address1 = '*Required';
    } else if (values?.address1.length > 50) {
        errors.address1 = '*Address is longer than usual';
    }
    if (values?.address2 && values?.address2.length > 50) {
        errors.address2 = '*Address is longer than usual';
    }
    if (!values?.state) {
        errors.state = '*Required';
    } else if (values?.state.length !== 2) {
        errors.state = '*State can be only 2 characters';
    } else if (
        !/^[a-zA-Z ]*$/.test(values?.state)
    ) {
        errors.state = '*Numbers not allowed';
    }
    if (!values?.city) {
        errors.city = '*Required';
    } else if (
        !/^[a-zA-Z ]*$/.test(values.city)
    ) {
        errors.city = '*Numbers not allowed';
    }
    if (!values?.phone) {
        errors.phone = '*Required';
    } else if (
        !/^[0-9\b]+$/.test(values?.phone)
    ) {
        errors.phone = '*Only numbers allowed';
    } else if (values?.phone.length !== 10) {
        errors.phone = '*Phone cannot be longer or shorter than 10 digits';
    }
    if (!values?.zip) {
        errors.zip = '*Required';
    } else if (
        !/^[0-9\b]+$/.test(values?.zip)
    ) {
        errors.zip = '*Only numbers allowed';
    } else if (values?.zip.length < 5) {
        errors.zip = '*ZIP cannot be shorter than 5 digits';
    }
    if (!values?.lastName) {
        errors.lastName = '*Required';
    } else if (
        !/^[a-zA-Z]*$/.test(values?.lastName)
    ) {
        errors.lastName = '*Numbers not allowed';
    }
    if (!values?.firstName) {
        errors.firstName = '*Required';
    } else if (
        !/^[a-zA-Z ]*$/.test(values?.firstName)
    ) {
        errors.firstName = '*Numbers not allowed';
    }
    if (values?.country === 'Select a country') {
        errors.country = '*Required';
    }
    if (!values?.email) {
        errors.email = '*Required';
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values?.email)
    ) {
        errors.email = '*Invalid email address';
    }
    return errors;
}

export function validateCreditCard(values) {
    const errors = {};
    if (!values?.name) {
        errors.name = '*Card holder is required field';
    } else if (values?.name.length > 40) {
        errors.name = '*Card holder name is longer than usual';
    }

    if (!values?.number) {
        errors.number = '*Card number is required field';
    } else if (
        !/^[0-9\b]+$/.test(values?.number)
    ) {
        errors.number = '*Only numbers allowed';
    } else if (!(values?.number.length === 15 || values?.number.length === 16)) {
        errors.number = '*Card number must be 15 or 16 digits';
    }

    if (!values?.expiryMonth) {
        errors.expiryMonth = '*Expiry month is required field';
    } else if (
        !/^[0-9\b]+$/.test(values?.expiryMonth)
    ) {
        errors.expiryMonth = '*Only numbers allowed';
    } else if (values?.expiryMonth.length !== 2) {
        errors.expiryMonth = '*Expiry month must be 2 digits';
    } else if (values?.expiryMonth > 12) {
        errors.expiryYear = '*Expiry month must be valid month';
    }

    if (!values?.expiryYear) {
        errors.expiryYear = '*Expiry year is required field';
    } else if (
        !/^[0-9\b]+$/.test(values?.expiryYear)
    ) {
        errors.expiryYear = '*Only numbers allowed';
    } else if (values?.expiryYear.length !== 2) {
        errors.expiryYear = '*Expiry year must be 2 digits';
    } else if (values?.expiryYear < 20 && values?.expiryYear > 40) {
        errors.expiryYear = '*Expiry year must be valid year';
    }

    if (!values?.cvv) {
        errors.cvv = '*CVV is required field';
    } else if (
        !/^[0-9\b]+$/.test(values?.cvv)
    ) {
        errors.cvv = '*Only numbers allowed';
    } else if (values?.cvv.length !== 3) {
        errors.cvv = '*CVV must be 3 digits';
    }

    return errors;
}

export function validateLogin(values) {
    const errors = {};
    if (!values?.email) {
        errors.email = '*Required';
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values?.email)
    ) {
        errors.email = '*Invalid email address';
    }
    return errors;
}
