import React from 'react';
import {
    Modal, ModalHeader, ModalBody,
} from 'reactstrap';

function CloseStore({
    isStoreClosed, onCloseStoreModal, closeMessage,
}) {
    return (
        <Modal isOpen={isStoreClosed} backdrop centered size="lg" toggle={onCloseStoreModal}>
            <ModalHeader
                className="container closedMessage"
                toggle={onCloseStoreModal}
            >
                <span className="exclamationMark">!</span>
                {closeMessage || 'Store is closed'}
            </ModalHeader>
            <ModalBody>
                <img src="/images/close-store.jpg" alt="closed" style={{ width: '100%' }} />
            </ModalBody>
        </Modal>
    );
}

export default CloseStore;
