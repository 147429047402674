/* eslint-disable global-require */
/* eslint-disable no-underscore-dangle */
// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import { Check100Svg } from '../../svg';
import Currency from '../shared/Currency';

import theme from '../../data/theme';
import { url } from '../../services/utils';

function formateOrderLines(orderLines) {
    const uniquesOrderLinesMap = new Map();
    // eslint-disable-next-line no-restricted-syntax
    for (const line of orderLines) {
        const key = `${line?.id}-${line?.color}-${line?.size}`;
        if (!uniquesOrderLinesMap.has(key)) {
            uniquesOrderLinesMap.set(key, line);
        } else if (uniquesOrderLinesMap.get(key).personalization?.length < line.personalization?.length) {
            uniquesOrderLinesMap.set(key, line);
        }
    }

    const uniquesOrderLines = Array.from(uniquesOrderLinesMap.values());
    return uniquesOrderLines;
}

export default function ShopPageOrderSuccess({ location }) {
    const findProductPartId = ({ product, labelSize, partColor } = {}) => {
        const partInventoryArray = product?.data?.inventoryData?.partInventoryArray || [];
        return partInventoryArray.find((x) => x.labelSize === labelSize && x.partColor === partColor)?.partId;
    };

    const findProductPrice = ({ product, labelSize, partColor } = {}) => {
        if (!product) return 0;
        let price = product?.data?.pricingManagement?.productCost?.formulas?.[0]?.[0];

        if (typeof price === 'string' && price?.includes('partPrice')) {
            try {
                const { Parser } = require('expr-eval');
                const parser = new Parser();
                const expr = parser.parse(price);
                // price = product?.data?.productData?.minimumPartPrice;

                const partArray = product?.data?.pricingData?.partArray || [];
                const partPrices = partArray.flatMap((a) => a?.partPriceArray).map((a) => a?.price);
                const validPartPrices = partPrices.filter((a) => !Number.isNaN(Number.parseFloat(a)) && Number.parseFloat(a) > 0);
                const minimumPartPrice = product?.data?.productData?.minimumPartPrice;
                const foundedMinPartPrice = Math.min(...validPartPrices, minimumPartPrice || 0);

                // if (!price) {
                price = expr.evaluate({
                    partPrice: foundedMinPartPrice,
                });
                // }
            } catch (error) {
                console.error(error);
            }
        }

        if (price && !Number.isNaN(Number(price)) && Number.parseFloat(price) > 0) return Number(price);

        if (labelSize && partColor) {
            const partId = findProductPartId({ product, labelSize, partColor });
            if (partId) {
                const partArray = product?.data?.pricingData?.partArray || [];

                const foundedPrice = partArray?.find((x) => x.partId === partId)?.partPriceArray?.[0]?.price;

                if (foundedPrice && !Number.isNaN(Number(foundedPrice))) return Number(foundedPrice);
            }
        }

        const minimumPartPrice = product?.data?.productData?.minimumPartPrice;
        return Number(minimumPartPrice) || 0;
    };

    const orderDetail = location.state;
    const items = formateOrderLines(orderDetail?.lines || [])
        .reduce((acc, curr) => {
            curr.sizes.forEach((size) => {
                const line = { ...curr };
                line.sizes = [size];
                acc.push(line);
            });
            return acc;
        }, [])
        .map((item, index) => {
            const groupByGroupIdAndSize = item.personalization.reduce((acc, curr) => {
                const size = curr.label;
                const key = `${curr.groupId}-${size}`;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(curr);
                return acc;
            }, {});

            const options = Object.entries(groupByGroupIdAndSize).map(([key], index) => {
                const group = groupByGroupIdAndSize[key];
                const size = group?.[0]?.label;
                const sizeText = size ? `(${size})` : '';

                if (Array.isArray(item?.sizes) && item?.sizes?.length > 0) {
                    if (item.sizes?.[0]?.size !== size) return null;
                }
                return (
                    <div
                        key={sizeText}
                        style={{
                            display: "flex", flexDirection: "column", marginBlock: '10px', width: '100%',
                        }}
                    >
                        <h6
                            style={{
                                fontWeight: 600,
                                fontSize: 15,
                                paddingBottom: 3,
                                borderBottom: '1px solid',
                            }}
                        >
                            {`${index + 1}. Personalization ${sizeText}`}
                        </h6>
                        {group.map((option) => (
                            <div
                                key={index}
                                style={{
                                    display: "flex", flexDirection: "row", justifyContent: "flex-start", width: '100%', gap: 10,
                                }}
                            >
                                <span style={{
                                    padding: 0,
                                    fontWeight: 600,
                                    textWrap: "nowrap",
                                    display: "inline-block",
                                    whiteSpace: "nowrap",
                                    textAlign: "start",
                                    flexBasis: `${group?.reduce((acc, curr) => Math.max(acc, curr?.name?.length), 0)}ch`,
                                }}
                                >
                                    {option.name}
                                    {': '}
                                </span>
                                <span style={{
                                    padding: 0, display: 'flex', flexGrow: 1, textAlign: "start",
                                }}
                                >
                                    {option.value}
                                </span>
                            </div>
                        ))}
                    </div>
                );
            });
            return (
                <tr key={index}>
                    <td style={{ verticalAlign: "top" }} className="order-list__column-image">
                        <div className="product-image">
                            <Link to={url.product(item.id)} className="product-image__body">
                                <img className="product-image__img" src={item.primaryImage} alt="" />
                            </Link>
                        </div>
                    </td>
                    <td className="order-list__column-product">
                        <Link to={url.product(item.id)}>{item.productName}</Link>
                        {options.length > 0 && (
                            <div style={{ widows: "100%" }} className="order-list__options">
                                <ul className="order-list__options-list">
                                    {options}
                                </ul>
                            </div>
                        )}
                    </td>

                    <td style={{ verticalAlign: "top" }} className="!hidden xs-end:!table-cell order-list__column-quantity" data-title="Color:">{item.color}</td>
                    <td style={{ verticalAlign: "top", textWrap: "nowrap" }} className="!hidden xs-end:!table-cell order-list__column-quantity" data-title="Size:">{item.sizes.map((x) => x.size).join(', ')}</td>
                    <td style={{ verticalAlign: "top" }} className="!hidden xs-end:!table-cell order-list__column-quantity" data-title="Qty:">
                        {item.sizes[0].quantity}
                    </td>
                    <td style={{ verticalAlign: "top" }} className="!hidden xs-end:!table-cell order-list__column-quantity" data-title="Price:">
                        <Currency value={
                            item.sizes?.[0]?.unitPrice
                        }
                        />
                    </td>
                    <td style={{ verticalAlign: "top" }} className="!hidden xs-end:!table-cell order-list__column-total">
                        <Currency value={item.sizes.reduce((acc, cur) => acc + (cur.quantity * (cur.unitPrice || findProductPrice({
                            labelSize: item?.sizes?.[0]?.size,
                            partColor: item?.color,
                            product: item?.sizes?.[0]?._product,
                        }))), 0)}
                        />
                    </td>

                    <table className="block xs-end:hidden !my-2">
                        <tr>
                            <td className="!flex w-full align-top order-list__column-quantity" data-title="Color:">
                                <div className="text-end grow">
                                    {item.color}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textWrap: "nowrap" }} className="!flex w-full align-top order-list__column-quantity" data-title="Size:">
                                <div className="text-end grow">{item.sizes.map((x) => x.size).join(', ')}</div>
                            </td>
                        </tr>
                        <tr>
                            <td className="!flex w-full align-top order-list__column-quantity" data-title="Qty:">
                                <div className="text-end grow">{item.quantity}</div>
                            </td>
                        </tr>
                        <tr>
                            <td className="!flex w-full align-top order-list__column-quantity" data-title="Price:">
                                <div className="text-end grow">
                                    <Currency value={item.price} />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="!flex w-full align-top order-list__column-quantity" data-title="Total Price:">
                                <div className="text-end grow">
                                    <Currency value={item.totalPrice} />
                                </div>
                            </td>
                        </tr>
                    </table>

                </tr>
            );
        });

    return (
        <div className="block order-success">
            <Helmet>
                <title>{`Order Success — ${theme.name}`}</title>
            </Helmet>

            <div className="container">
                <div className="order-success__body">
                    <div className="order-success__header">
                        <Check100Svg className="order-success__icon" />
                        <h1 className="order-success__title">Thank you</h1>
                        <div className="order-success__subtitle">Your order has been received</div>
                        <div className="order-success__actions">
                            <Link to="/" className="btn btn-xs btn-secondary">Go To Homepage</Link>
                        </div>
                    </div>

                    <div className="order-success__meta">
                        <ul className="order-success__meta-list">
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Order number:</span>
                                <span className="order-success__meta-value">{`#${orderDetail.orderId}`}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Created at:</span>
                                <span className="order-success__meta-value">{orderDetail.orderDate.toLocaleString()}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Total:</span>
                                <span className="order-success__meta-value"><Currency value={orderDetail.totalAmount + orderDetail.totalTax + orderDetail.shippingAmount} /></span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Payment method:</span>
                                <span className="order-success__meta-value">{orderDetail.payment}</span>
                            </li>
                        </ul>
                    </div>

                    <div className="card">
                        <div className="overflow-auto order-list">
                            <table>
                                <thead className="order-list__header">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="2">Product</th>
                                        <th className="order-list__column-quantity">Color</th>
                                        <th className="order-list__column-quantity">Size</th>
                                        <th className="order-list__column-quantity">Qty</th>
                                        <th className="order-list__column-quantity">Price</th>
                                        <th className="order-list__column-total">Total</th>
                                    </tr>
                                </thead>
                                <tbody className="order-list__products">
                                    {items}
                                </tbody>
                                <tbody className="order-list__subtotals">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="6">Subtotal</th>
                                        <td className="order-list__column-total"><Currency value={orderDetail.totalAmount} /></td>
                                    </tr>
                                    <tr>
                                        <th className="order-list__column-label" colSpan="6">Tax</th>
                                        <td className="order-list__column-total"><Currency value={orderDetail.totalTax} /></td>
                                    </tr>
                                    <tr>
                                        <th className="order-list__column-label" colSpan="6">Shipping</th>
                                        <td className="order-list__column-total"><Currency value={orderDetail.shippingAmount} /></td>
                                    </tr>
                                    <tr>
                                        <th className="order-list__column-label" colSpan="6">Gift Card</th>
                                        <td className="order-list__column-total"><Currency value={orderDetail.giftCodeBalance} /></td>
                                    </tr>
                                </tbody>
                                <tfoot className="order-list__footer">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="6">Total</th>
                                        <td className="order-list__column-total"><Currency value={orderDetail.totalAmount + orderDetail.totalTax + orderDetail.shippingAmount - (orderDetail.giftCodeBalance || 0)} /></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    <div className="mt-3 row no-gutters mx-n2">
                        {orderDetail.shippingType === 'toAddress' && (
                            <div className="px-2 col-sm-6 col-12">
                                <div className="card address-card">
                                    <div className="address-card__body">
                                        <div className="address-card__badge address-card__badge--muted">
                                            Shipping Address
                                        </div>
                                        <div className="address-card__name">
                                            {`${orderDetail.shippingAddress.firstName} ${orderDetail.shippingAddress.lastName}`}
                                        </div>
                                        <div className="address-card__row">
                                            {orderDetail.shippingAddress.address1}
                                            <br />
                                            {orderDetail.shippingAddress.address2}
                                            <br />
                                            {`${orderDetail.shippingAddress.city}, , ${orderDetail.shippingAddress.state}, ${orderDetail.shippingAddress.zip}`}
                                            <br />
                                            {orderDetail.shippingAddress.country}
                                        </div>
                                        {orderDetail.shippingAddress?.phone && (
                                            <div className="address-card__row">
                                                <div className="address-card__row-title">Phone Number</div>
                                                <div className="address-card__row-content">{orderDetail.shippingAddress.phone}</div>
                                            </div>
                                        )}
                                        {orderDetail.shippingAddress.email && (
                                            <div className="address-card__row">
                                                <div className="address-card__row-title">Email Address</div>
                                                <div className="address-card__row-content">{orderDetail.shippingAddress.email}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={`${orderDetail.shippingType === 'toAddress' && "col-sm-6"} col-12 px-2 mt-sm-0 mt-3`}>
                            <div className="card address-card">
                                <div className="address-card__body">
                                    <div className="address-card__badge address-card__badge--muted">
                                        Billing Address
                                    </div>
                                    <div className="address-card__name">
                                        {`${orderDetail.billingAddress.firstName} ${orderDetail.billingAddress.lastName}`}
                                    </div>
                                    <div className="address-card__row">
                                        {orderDetail.billingAddress.address1}
                                        <br />
                                        {orderDetail.billingAddress.address2}
                                        <br />
                                        {`${orderDetail.billingAddress.city}, ${orderDetail.billingAddress.state}, ${orderDetail.billingAddress.zip}`}
                                        <br />
                                        {orderDetail.billingAddress.country}
                                    </div>
                                    {orderDetail.billingAddress?.phone && (
                                        <div className="address-card__row">
                                            <div className="address-card__row-title">Phone Number</div>
                                            <div className="address-card__row-content">{orderDetail.billingAddress.phone}</div>
                                        </div>
                                    )}
                                    {orderDetail.billingAddress?.email && (
                                        <div className="address-card__row">
                                            <div className="address-card__row-title">Email Address</div>
                                            <div className="address-card__row-content">{orderDetail.billingAddress.email}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {orderDetail.note && (
                        <div className="mt-3 row no-gutters mx-n2">
                            <div className="px-2 mt-3 col-12 mt-sm-0">
                                <div className="card address-card">
                                    <div className="address-card__body">
                                        <div className="address-card__name">
                                            Order Notes
                                        </div>
                                        <div className="address-card__row">
                                            {orderDetail.note}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
