const tierCalculator = {
    getFormulaIndexes: (config, data) => {
        if (!config) { return; }
        if (!data) { return; }
        const { xValues, yValues, formulas } = config;
        const x = data[config.xField.key];
        const y = data[config.yField.key];

        let xPreviewValueFound = false;
        let newFormulaXIndex = 0;
        for (let i = 0; i < xValues.length; i += 1) {
            if (xValues[i]) {
                if (xValues[i] > x) {
                    newFormulaXIndex = i - 1;
                    xPreviewValueFound = true;
                    break;
                }
            }
        }
        if (!xPreviewValueFound) newFormulaXIndex = xValues.length - 1;

        let yPreviewValueFound = false;
        let newFormulaYIndex = 0;
        if (config.type === 'dropdown') {
            for (let i = 0; i < yValues.length; i += 1) {
                if (yValues[i]) {
                    if (yValues[i] === y) {
                        newFormulaYIndex = i;
                        yPreviewValueFound = true;
                        break;
                    }
                }
            }
        }

        if (config.type === 'number') {
            for (let i = 0; i < yValues.length; i += 1) {
                if (yValues[i]) {
                    if (yValues[i] > y) {
                        newFormulaYIndex = i - 1;
                        yPreviewValueFound = true;
                        break;
                    }
                }
            }
        }
        if (!yPreviewValueFound) newFormulaYIndex = yValues.length - 1;
        const newFormula = formulas[newFormulaYIndex][newFormulaXIndex];

        return {
            xIndex: newFormulaXIndex,
            yIndex: newFormulaYIndex,
            formula: newFormula,
        };
    },
    calculate: (config, data) => {
        try {
        const result = tierCalculator.getFormulaIndexes(config, data);
        if (!result) return 0;

        if (!result.formula) return 0;

            const { Parser } = require('expr-eval');
            const parser = new Parser();
            const expr = parser.parse(
                result.formula?.replace(/,/g, '.'),
            );
            const exprResult = expr.evaluate(data);
            return exprResult;
        } catch (error) {
            console.log(error.message);
            return undefined;
        }
    },
};
export default tierCalculator;
