import getApi from './api';
import { GetAPIEndpoint, getHeaders } from '../../configuration';

const orderApi = {
    getOrder: (id) => {
        const headers = getHeaders();
        return getApi().get(`${GetAPIEndpoint()}/api/orders/${id}`, { headers });
    },
    getOrders: () => {
        const headers = getHeaders();
        return getApi().get(`${GetAPIEndpoint()}/api/orders`, { headers });
    },
    getTransactions: () => {
        const headers = getHeaders();
        return getApi().get(`${GetAPIEndpoint()}/api/transactions`, { headers });
    },
    getShippingEstimate: (order) => getApi().put(`${GetAPIEndpoint()}/api/promo/shipping-estimate`,
        order, { headers: getHeaders() }),
    getTaxEstimate: (order) => getApi().put(`${GetAPIEndpoint()}/api/promo/tax-estimate`,
        order, { headers: getHeaders() }),
    placeOrder: (order) => getApi().post(`${GetAPIEndpoint()}/api/promo/ecommerce/orders`,
        order, { headers: getHeaders() }),
    applyGiftCode: (order) => getApi().post(`${GetAPIEndpoint()}/api/promo/ecommerce/gift-code/apply`,
        order, { headers: getHeaders() }),
    updateGiftCode: (orderkey, totalAmount) => getApi().put(`${GetAPIEndpoint()}/api/promo/ecommerce/cart-gift-code/${orderkey}`,
        totalAmount, { headers: getHeaders() }),
    getGiftCode: (orderkey) => getApi().get(`${GetAPIEndpoint()}/api/promo/ecommerce/gift-code/${orderkey}`,
        { headers: getHeaders() }),
    removeGiftCode: (order) => getApi().post(`${GetAPIEndpoint()}/api/promo/ecommerce/gift-code/remove`,
        order, { headers: getHeaders() }),
};

export default orderApi;
