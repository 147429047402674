/* eslint-disable jsx-a11y/anchor-is-valid */
// react
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

// application
import Indicator from './Indicator';
import { Person20Svg } from '../../svg';
import IsAuthContext from '../../contexts/IsAuthContext';

export default function IndicatorAccount() {
    const { isAuth, setIsAuth } = useContext(IsAuthContext);
    const history = useHistory();
    const [password, setPassword] = useState('');

    const handleLogin = () => {
        axios(`/api/promo/store/validate/${password}`).then((data) => {
            if (data?.data?.success) {
                document.getElementsByClassName("indicator indicator--trigger--click account-indicator")[0].classList.remove('indicator--opened');
                setIsAuth(true);
                localStorage.setItem("authorization", true);
            } else {
                // eslint-disable-next-line no-alert
                toast.error("Invalid password! Please, contact with admin.");
            }
        });
    };

    const handleLogout = () => {
        document.getElementsByClassName("indicator indicator--trigger--click account-indicator")[0].classList.remove('indicator--opened');
        setIsAuth(false);
        localStorage.removeItem("authorization");
        history.push('/');
    };

    const dropdown = (
        <div className="account-menu">
            {!isAuth && (
                <form className="account-menu__form">
                    <div className="account-menu__form-title">Log In</div>
                    <div className="form-group">
                        <label htmlFor="header-signin-password" className="sr-only">Password</label>
                        <div className="account-menu__form-forgot">
                            <input
                                id="header-signin-password"
                                type="password"
                                className="form-control form-control-sm"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-group account-menu__form-button">
                        <button type="button" className="btn btn-primary btn-sm" onClick={handleLogin}>Login</button>
                    </div>
                </form>
            )}
            {isAuth && (
                <ul className="account-menu__links">
                    <li><a href="#" onClick={handleLogout}>Logout</a></li>
                </ul>
            )}
        </div>
    );

    return (
        <Indicator className="account-indicator" url="/account" dropdown={dropdown} icon={<Person20Svg />} />
    );
}
