import axios from 'axios';
import { GetAPIEndpoint, getHeaders } from '../../configuration';

const getApi = () => {
    const api = axios.create({
        baseURL: `${GetAPIEndpoint()}`,
        timeout: 30000,
        headers: getHeaders(),
    });

    // api.interceptors.response.use(
    //     (response) => response,
    //     (error) => {
    //         if (error && error.response && (error.response.status === 500 || error.response.status === 401)) {
    //             localStorage.removeItem('access_token');
    //             if (window.location.href.indexOf('login') === -1) {
    //                 window.location = '/account/login';
    //             }
    //         }
    //         return error;
    //     },
    // );
    return api;
};

export default getApi;
