/* eslint-disable global-require */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { tierCalculator } from '../services/tier-pricing';

export function makeIdGenerator() {
    let lastId = 0;

    return () => {
        lastId += 1;

        return lastId;
    };
}

export function nameToSlug(name) {
    return name.toLowerCase().replace(/[^a-z0-9]/, '-').replace(/-+/, '-');
}

export function categoryHasProducts() {
    return true;
}

const findProductPartId = ({ product, labelSize, partColor } = {}) => {
    const partInventoryArray = product?.data?.inventoryData?.partInventoryArray || [];
    return partInventoryArray.find((x) => x.labelSize === labelSize && x.partColor === partColor)?.partId;
};

const findProductPrice = ({
    product, labelSize, partColor, _orderLines, store,
} = {}) => {
    if (!product) return 0;
    let price = product?.data?.pricingManagement?.productCost?.formulas?.[0]?.[0];

    if (typeof price === 'string' && price?.includes('partPrice')) {
        try {
            const { Parser } = require('expr-eval');
            const parser = new Parser();
            const expr = parser.parse(price);
            // price = product?.data?.productData?.minimumPartPrice;

            const partArray = product?.data?.pricingData?.partArray || [];
            const partPrices = partArray.flatMap((a) => a?.partPriceArray).map((a) => a?.price);
            const validPartPrices = partPrices.filter((a) => !Number.isNaN(Number.parseFloat(a)) && Number.parseFloat(a) > 0);
            const minimumPartPrice = product?.data?.productData?.minimumPartPrice;
            const foundedMinPartPrice = Math.min(...validPartPrices, minimumPartPrice || 0);

            // if (!price) {
            price = expr.evaluate({
                partPrice: foundedMinPartPrice,
            });
            // }
        } catch (error) {
            console.error(error);
        }
    }
    if (price && !Number.isNaN(Number(price)) && Number.parseFloat(price) > 0) return Number(price);

    if (_orderLines) {
        const productMap = _orderLines?.reduce((acc, item) => {
            // const key = `${item.productId}-${item.color}-${item.label}`;
            const productId = item?.product?.data?.productData?.productId ?? item.productId;
            const key = `${productId}-${item.color}-${item.label}`;
            if (!acc[key]) {
                acc[key] = item.sizes?.reduce((sum, size) => sum + size.quantity, 0) || (item?.quantity ?? 0);
            } else {
                acc[key] += item.sizes?.reduce((sum, size) => sum + size.quantity, 0) || (item?.quantity ?? 0);
            }
            return acc;
        }, {});

        const productId = product?.data?.productData?.productId;
        const key = `${productId}-${partColor}-${labelSize}`;

        const totalQuantity = productMap[key];

        const storePricing = {
            productCost: {
                formulas: store?.pricingManagement?.productCost?.formulas?.[0],
                xValues: store?.pricingManagement?.productCost?.xValues,
            },
        };

        if (storePricing.productCost.formulas && storePricing.productCost.xValues) {
            let quantityIndex = storePricing.productCost.xValues?.findIndex((x) => x === totalQuantity);

            if (quantityIndex === -1) {
                quantityIndex = storePricing.productCost.xValues?.findIndex((x, i, arr) => totalQuantity > x && totalQuantity < arr[i + 1]);
            }

            if (quantityIndex > storePricing.productCost.xValues?.length - 1) {
                quantityIndex = storePricing.productCost.xValues?.length - 1;
            }

            const xValueindex = quantityIndex <= -1 ? storePricing.productCost.xValues?.length - 1 : quantityIndex;

            return Number(storePricing.productCost.formulas?.[xValueindex] || 0);
        }
    }

    if (labelSize && partColor) {
        const partId = findProductPartId({ product, labelSize, partColor });
        if (partId) {
            const partArray = product?.data?.pricingData?.partArray || [];

            const foundedPrice = partArray?.find((x) => x.partId === partId)?.partPriceArray?.[0]?.price;

            if (foundedPrice && !Number.isNaN(Number(foundedPrice))) return Number(foundedPrice);
        }
    }

    const minimumPartPrice = product?.data?.productData?.minimumPartPrice;
    return Number(minimumPartPrice) || 0;
};

export function calculatePartPrices(cartItems, store) {
    const storePricing = store?.pricingManagement;
    const newCartItems = cartItems.map((cartItem) => {
        const newCartItem = { ...cartItem };
        const { designs, pricingManagement: productPricing } = newCartItem.product.data;

        if (store && (Object.keys(storePricing).length > 0 || Object.keys(productPricing).length > 0)) {
            const productCostField = productPricing?.productCost?.enabled ? productPricing?.productCost : storePricing?.productCost;
            const numberOfColorsField = productPricing?.numberOfColors?.enabled ? productPricing?.numberOfColors : storePricing?.numberOfColors;
            const stitchCountField = productPricing?.stitchCount?.enabled ? productPricing?.stitchCount : storePricing?.stitchCount;

            let designCost = 0;
            if (designs && designs.length > 0) {
                designs?.forEach((selectedDesign) => {
                    selectedDesign?.locations?.forEach((location) => {
                        if (location.colorsTotal && location.colorsTotal > 0) {
                            designCost += tierCalculator.calculate(numberOfColorsField, {
                                numberOfColors: location.colorsTotal,
                                quantity: cartItems.filter((x) => x.product.id === cartItem.product.id).length,
                            });
                        }
                        if (location.stitchesTotal && location.stitchesTotal > 0) {
                            designCost += tierCalculator.calculate(stitchCountField, {
                                stitchCount: location.stitchesTotal,
                                quantity: cartItems.filter((x) => x.product.id === cartItem.product.id).length,
                            });
                        }
                    });
                });
            }

            let productCost = 0;

            if (productCostField) {
                // productCost += tierCalculator.calculate(productCostField, {
                //     partPrice: cartItem.price,
                //     quantity: cartItem.quantity,
                //     sizeMarkup: 0,
                // });
                productCost += findProductPrice({
                    product: cartItem.product,
                    labelSize: cartItem.label,
                    partColor: cartItem.color,
                    _orderLines: cartItems,
                    store,
                });
                productCost += cartItem.sizeMarkup || 0;
            } else {
                productCost = cartItem.price;
            }

            newCartItem.price = productCost + designCost;
            newCartItem.total = newCartItem.price * cartItem.quantity;
        }

        return newCartItem;
    });

    return newCartItems;
}

export function calculateProductPrices(product, store, sizes) {
    let headers = [];
    const rows = [];
    const storePricing = store?.pricingManagement;

    const { designs, pricingManagement: productPricing } = product.data;

    if (Object.keys(storePricing).length > 0 || Object.keys(productPricing).length > 0) {
        const productCostField = productPricing?.productCost?.enabled ? productPricing?.productCost : storePricing?.productCost;
        const numberOfColorsField = productPricing?.numberOfColors?.enabled ? productPricing?.numberOfColors : storePricing?.numberOfColors;
        const stitchCountField = productPricing?.stitchCount?.enabled ? productPricing?.stitchCount : storePricing?.stitchCount;

        if (designs && designs.length > 0) {
            headers = productCostField?.xValues || [];

            if (Array.isArray(headers)) {
                sizes.forEach((size) => {
                    const sizeRow = { size: size.label, prices: [] };
                    headers.forEach((quantity) => {
                        let designCost = 0;

                        designs?.forEach((selectedDesign) => {
                            selectedDesign?.locations?.forEach((location) => {
                                if (location.colorsTotal && location.colorsTotal > 0) {
                                    designCost += tierCalculator.calculate(numberOfColorsField, {
                                        numberOfColors: location.colorsTotal,
                                        quantity,
                                    });
                                }
                                if (location.stitchesTotal && location.stitchesTotal > 0) {
                                    designCost += tierCalculator.calculate(stitchCountField, {
                                        stitchCount: location.stitchesTotal,
                                        quantity,
                                    });
                                }
                            });
                        });

                        let productCost = 0;

                        if (productCostField) {
                            productCost += tierCalculator.calculate(productCostField, {
                                partPrice: size.price,
                                quantity,
                                sizeMarkup: 0,
                            });
                            productCost += size.sizeMarkup || 0;
                        }

                        sizeRow.prices.push(productCost + designCost);
                    });
                    rows.push(sizeRow);
                });
            }
        }
    }

    return { headers, rows };
}

export function calculatePartPrice(product, store, price, quantity, sizeMarkup) {
    let productCost = 0;
    let designCost = 0;
    const storePricing = store?.pricingManagement;
    const { designs, pricingManagement: productPricing } = product.data;

    if ((storePricing && Object.keys(storePricing).length > 0) || (productPricing && Object.keys(productPricing).length > 0)) {
        const productCostField = productPricing?.productCost?.enabled ? productPricing?.productCost : storePricing?.productCost;
        const numberOfColorsField = productPricing?.numberOfColors?.enabled ? productPricing?.numberOfColors : storePricing?.numberOfColors;
        const stitchCountField = productPricing?.stitchCount?.enabled ? productPricing?.stitchCount : storePricing?.stitchCount;

        if (designs && designs.length > 0) {
            designs?.forEach((selectedDesign) => {
                selectedDesign?.locations?.forEach((location) => {
                    if (location.colorsTotal && location.colorsTotal > 0) {
                        const calculated = tierCalculator.calculate(numberOfColorsField, {
                            numberOfColors: location.colorsTotal,
                            quantity,
                        });
                        designCost += calculated;
                    }
                    if (location.stitchesTotal && location.stitchesTotal > 0) {
                        const calculated = tierCalculator.calculate(stitchCountField, {
                            stitchCount: location.stitchesTotal,
                            quantity,
                        });
                        designCost += calculated;
                    }
                });
            });
        }

        if (productCostField) {
            const calculated = tierCalculator.calculate(productCostField, {
                partPrice: price,
                quantity,
                sizeMarkup: 0,
            });
            productCost += calculated;
        } else {
            productCost = price;
        }
    } else {
        productCost = price;
    }

    return productCost + sizeMarkup + designCost;
}

export function calcSubtotal(items, store) {
    // eslint-disable-next-line arrow-body-style
    const result = items.reduce((subtotal, item) => {
        return (subtotal + ((item.product?.data?.pricingManagement?.productCost?.xValues?.length > 1 || store?.pricingManagement?.productCost?.xValues?.length > 1)
            ? (item.currentTotal_ ?? (item.total ?? item.currentTotal_))
            : (item.total ?? item.currentTotal_))
        );
    }, 0);
    return result;
}

export function calcQuantity(items) {
    return items.reduce((quantity, item) => quantity + item.quantity, 0);
}

export function calcTotal(items, extraLines, store) {
    const subtotal = Number(calcSubtotal(items, store).toFixed(2));
    // const personalization = Number((items.reduce((total, item) => total + (item.options[0].personalization.reduce((total, item) => total + item.price, 0) || 0), 0) || 0).toFixed(2));

    const uniquePersonalizationItems = new Set();

    items?.forEach((item) => {
        item?.options?.[0]?.personalization?.forEach((pItem) => {
            if (!pItem.price) return;
            if (!pItem.value) return;
            uniquePersonalizationItems.add(JSON.stringify(pItem));
        });
    });

    const totalPersonalization = [...uniquePersonalizationItems].reduce((total, jsonString) => {
        const item = JSON.parse(jsonString);
        return total + (item.price || 0);
    }, 0);

    return subtotal + totalPersonalization + extraLines.reduce((total, extraLine) => total + extraLine.price, 0);
}

export function getProductColors(product) {
    const hexColorArray = product?.colorsHex;
    if (hexColorArray && hexColorArray.length > 0) { return hexColorArray; }

    const productColorArray = product?.productPartArray && product?.productPartArray.map((x) => x.colorArray).flat();
    const uniqueArray = productColorArray && productColorArray.filter((value, index, self) => self.findIndex((m) => m.colorName === value.colorName) === index);
    return uniqueArray || [];
}

export function getProductSizes(productData, inventoryData, mediaData, pricingData, productOptions, color, store) {
    const { partInventoryArray } = inventoryData;
    const sizesConfig = ['XS', 'S', 'M', 'L', 'XL', 'XXL', '2XL', 'XXXL', '3XL', '4XL', '5XL', '6XL', 'CUSTOM', 'Other'];
    const sizePriceArray = pricingData?.partArray;
    let sizeInventoryArray = [];
    let sizePartArray = [];
    let sizes = [];

    if (color && partInventoryArray && partInventoryArray.length > 0) {
        sizeInventoryArray = partInventoryArray.filter((x) => x.partColor === color);
        sizePartArray = productData?.productPartArray.filter((x) => x.colorArray.find((y) => y.colorName === color)) || [];

        sizes = sizePartArray.sort((a, b) => {
            const aOrder = sizesConfig?.findIndex((x) => x === a.apparelSize?.labelSize) ?? 0;
            const bOrder = sizesConfig?.findIndex((x) => x === b.apparelSize?.labelSize) ?? 0;

            return aOrder - bOrder;
        }).map((x) => {
            const sizePrice = sizePriceArray ? sizePriceArray.find((y) => y.partId === x.partId)?.partPriceArray[0].price : (productData?.minimumPartPrice);
            const weightArray = x.shippingPackageArray && x.shippingPackageArray[0];
            const sizeLabel = x.apparelSize?.labelSize === 'CUSTOM'
                ? x.apparelSize?.customSize
                : x.apparelSize?.labelSize;
            const sizeMarkup = store?.sizes.find((y) => y.name === sizeLabel)?.markup ?? 0;
            const enableColors = productOptions?.enableColor && productOptions.colors;
            let media = mediaData?.media.filter((x) => x.mediaType === 'Image' && !x.color && !x.url.includes("watch") && !x.url.includes("sw")).sort((a, b) => (b.order ?? 0) - (a.order ?? 0));
            if (enableColors) { media = media.filter((x) => enableColors?.includes(x.color)); }
            if (color) { media = [...media, ...mediaData?.media.filter((x) => x.mediaType === 'Image' && x.color === color && !x.url.includes("watch") && !x.url.includes("sw") && !x.url.includes("catalog"))]; }
            if (media.length === 0 && productData?.primaryImageUrl) {
                media = [{ url: productData?.primaryImageUrl }];
            } else if (media.length === 0) {
                media = [{ url: 'https://via.placeholder.com/700x700/ADBDBE/5A5A5A?text=No+Image' }];
            }

            return {
                label: sizeLabel,
                inventory: sizeInventoryArray.find((y) => (y.labelSize === (x.apparelSize?.labelSize === 'CUSTOM'
                    ? x.apparelSize?.customSize
                    : x.apparelSize?.labelSize)))?.quantityAvailable?.quantity?.value ?? 0,
                price: sizePrice,
                weight: typeof weightArray?.weight === "number" ? Number((weightArray.weight / (weightArray.quantity ?? 1)).toFixed(2)) : 0,
                weightUom: x.shippingPackageArray && x.shippingPackageArray[0] ? x.shippingPackageArray[0].weightUom : '',
                color,
                sizeMarkup,
                imageUrl: media[0].url,
            };
        });
    }

    return sizes;
}

export function getCustom1Options(product) {
    if (!product || !product.options) {
        return [];
    }
    const values = product.options.map((a) => a.optionCustom1Value);
    const unique = values.filter((value, index, self) => self.indexOf(value) === index && value.length > 0);
    return unique;
}
export function getCustom2Options(product) {
    if (!product || !product.options) {
        return [];
    }
    const values = product.options.map((a) => a.optionCustom2Value);
    const unique = values.filter((value, index, self) => self.indexOf(value) === index && value.length > 0);
    return unique;
}
export function getCustom3Options(product) {
    if (!product || !product.options) {
        return [];
    }
    const values = product.options.map((a) => a.optionCustom3Value);
    const unique = values.filter((value, index, self) => self.indexOf(value) === index && value.length > 0);
    return unique;
}

export function getAvailableOptions(product, color, size, custom1, custom2, custom3) {
    if (!product || !product.options) {
        return [];
    }
    const options = product.options.filter((data) => {
        const colorValid = color === undefined || data.optionColorValue === color;
        const sizeValid = size === undefined || data.optionSizeValue === size;
        const custom1Valid = custom1 === undefined || data.optionCustom1Value === custom1;
        const custom2Valid = custom2 === undefined || data.optionCustom2Value === custom2;
        const custom3Valid = custom3 === undefined || data.optionCustom3Value === custom3;
        return colorValid && sizeValid && custom1Valid && custom2Valid && custom3Valid;
    });
    return options;
}
export function getProductOption(product, color, size, custom1, custom2, custom3) {
    const options = getAvailableOptions(product, color, size, custom1, custom2, custom3);

    const colorExists = product.options.filter((data) => data.optionSizeValue && data.optionSizeValue.length > 0) > 0;
    const sizeExists = product.options.filter((data) => data.optionColorValue && data.optionColorValue.length > 0) > 0;
    const custom1Exists = product.optionsCustom1Label && product.optionsCustom1Label.length > 0;
    const custom2Exists = product.optionsCustom2Label && product.optionsCustom2Label.length > 0;
    const custom3Exists = product.optionsCustom3Label && product.optionsCustom3Label.length > 0;

    const colorValid = (colorExists && color && color.length > 0) || !colorExists;
    const sizeValid = (sizeExists && size && size.length > 0) || !colorExists;
    const custom1Valid = (custom1Exists && custom1 && custom1.length > 0) || !custom1Exists;
    const custom2Valid = (custom2Exists && custom2 && custom2.length > 0) || !custom2Exists;
    const custom3Valid = (custom3Exists && custom3 && custom3.length > 0) || !custom3Exists;

    if (!colorValid || !sizeValid || !custom1Valid || !custom2Valid || !custom3Valid) return undefined;

    if (options.length === 1) return options[0];
    return undefined;
}

export function getCostByRegion(product, region) {
    if (!region) return undefined;
    const { costs } = product;
    if (costs) {
        const selectedCosts = costs.filter((data) => data.regionName === region.country);
        if (selectedCosts.length === 0) return undefined;
        return selectedCosts[0];
    }
    return undefined;
}

export function getProductPrice(product, option, region) {
    let { price } = product;
    if (!price) price = 0;
    if (option) {
        const optionCost = getCostByRegion(option, region);
        if (optionCost) {
            return optionCost.price;
        }
    }
    const cost = getCostByRegion(product, region);
    if (cost) {
        return cost.price;
    }
    return price;
}

export function getProductDiscountPrice(product, option, region) {
    let { discountPrice } = product;
    const cost = getCostByRegion(product, region);
    if (cost) {
        discountPrice += cost.costHandling;
    }
    if (option && option.price) {
        discountPrice += option.price;
    }
    return discountPrice;
}

export function selectedLanguage(product, locale, defaultData, data) {
    if (product.langs === undefined) {
        return defaultData;
    }
    const selectedLang = product.langs[locale];

    if (selectedLang) {
        if (selectedLang[data] === '') {
            return defaultData;
        }
        return selectedLang[data];
    }
    return defaultData;
}

export function createUniqueId() {
    return crypto.randomUUID();
}
