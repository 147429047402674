import { GetAPIEndpoint, getHeaders } from '../../configuration';
import getApi from './api';

const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

function formatCategory(str) {
    return str?.replace(/(^[a-z]|[-/][a-z])/g, (match) => match.toUpperCase()) ?? str;
}

const productApi = {
    getProductBySlug: (slug) => {
        const include = encodeURIComponent(JSON.stringify([
            'productData.productName',
            'productData.productBrand',
            'productData.minimumPartPrice',
            'productData.colors',
            'productData.categories',
            'productData.description',
            'productData.supplier',
            'productData.sizes',
            'productData.productPartArray',
            'productData.isCloseout',
            'mediaData.media',
            'pricingData.partArray.partPriceArray',
            'inventoryData.partInventoryArray',
            'designs',
            'pricingManagement',
        ]));

        return getApi().get(`${GetAPIEndpoint()}/api/promo/store/products/${slug}?include=${include}`, { headers: getHeaders() });
    },

    getProductsList: (options = {}, filterValues = {}, filter = [], includeFilters = true) => {
        // const optional = encodeURIComponent(JSON.stringify({
        //     options, filterValues, filter,
        // }));

        const filters = [];
        let selector = '';
        let desc = false;

        switch (options.sort) {
        case 'price_desc':
            selector = 'data.productData.minimumPartPrice';
            desc = true;
            break;
        case 'price_asc':
            selector = 'data.productData.minimumPartPrice';
            desc = false;
            break;
        case 'name_desc':
            selector = 'data.productData.productName';
            desc = true;
            break;
        case 'name_asc':
            selector = 'data.productData.productName';
            desc = false;
            break;
        default:
            break;
        }

        const {
            category, productBrand, colors, price, tags,
        } = filterValues;

        const minPrice = price && price.split('-')[0];
        const maxPrice = price && price.split('-')[1];
        const tagsArray = tags && tags.split(',');
        const sort = encodeURIComponent(JSON.stringify([{ selector, desc }]));
        const include = encodeURIComponent(JSON.stringify([
            'productData.productName',
            'productData.productBrand',
            'productData.minimumPartPrice',
            'productData.primaryImageUrl',
            'mediaData.media',
            'pricingData.partArray.partPriceArray',
            'productOptions',
            'designs',
            'pricingManagement',
        ]));

        // eski productlar için filtreleme
        // const categoryFilterKey = 'data.productData.categories';
        // yeni productlar için filtreleme
        const categoryFilterKey = 'data.productData.selectedCategories.id';

        if (category) {
            filters.push({
                key: categoryFilterKey,
                operation: 'eq',
                values: [
                    category,
                    ...[
                        category.includes('/')
                            ? category.split('/').slice(0, 1).join('')
                            : '',
                    ].filter(Boolean),
                ],
            });
        }
        if (productBrand && productBrand.length > 0) { filters.push({ key: 'data.productData.productBrand', operation: 'contains', values: productBrand }); }
        if (colors && colors.length > 0) { filters.push({ key: 'data.productData.colors', operation: 'contains', values: colors.split(",") }); }
        // if (minPrice) { filters.push({ key: 'data.productData.minimumPartPrice', operation: 'gte', value: minPrice }); }
        if (maxPrice) { filters.push({ key: 'data.productData.minimumPartPrice', operation: 'lte', value: maxPrice.toString() }); }
        if (tagsArray && tagsArray.length > 0) { filters.push({ key: 'data.productOptions.featuredProduct', operation: 'contains', values: tagsArray }); }
        if (options.search && options.search.length > 0) {
            filters.push({
                key: 'data.productData.productName',
                operation: 'contains',
                ...(Array.isArray(options.search) ? { values: options.search } : { value: options.search }),
            });
        }
        filters.push({ key: 'data.productData.minimumPartPrice', operation: 'gt', value: (minPrice ? minPrice.toString() : "0") });
        filters.push({ key: 'data.productOptions.activeProduct', operation: '=', value: 'true' });

        return getApi().get(`${GetAPIEndpoint()}/api/promo/store/products-store?skip=${options.skip || 0}&take=${options.limit || 20}&requireTotalCount=true&includeFilters=${includeFilters}&sort=${selector && sort}&include=${include}&filters=${encodeURIComponent(JSON.stringify(filters))}`, { headers: getHeaders() });
    },
    getSuggestions: (query, options) => getApi().get(`${GetAPIEndpoint()}/api/promo/store/products-store?filters=%5B%7B"key"%3A"data.productData.productName"%2C"operation"%3A"contains"%2C"value"%3A"${query}"%7D%2C%7B"key"%3A"data.productOptions.activeProduct"%2C"operation"%3A"="%2C"value"%3A"true"%7D%5D&skip=0&take=10&requireTotalCount=true?o=${JSON.stringify({ options })}&q=${query}`, { headers: getHeaders() }),
    getProductsByTag: (skip, limit, tag) => {
        const include = encodeURIComponent(JSON.stringify([
            'productData.productName',
            'productData.productBrand',
            'productData.minimumPartPrice',
            'productData.primaryImageUrl',
            'mediaData.media',
            'pricingData.partArray.partPriceArray',
            'productOptions',
            'pricingManagement',
        ]));

        const filters = [];
        filters.push({ key: 'data.productOptions.activeProduct', operation: '=', value: 'true' });
        // filters.push({ key: 'data.productOptions.featuredProduct', operation: 'contains', value: tag });

         // eski productlar için filtreleme
        // const categoryFilterKey = 'data.productData.categories';
        // yeni productlar için filtreleme
        const categoryFilterKey = 'data.productData.selectedCategories.id';

        filters.push({
            key: categoryFilterKey,
            operation: "contains",
            values: [
                tag,
                ...[
                    tag.includes('/')
                        ? tag.split('/').slice(0, 1).join('')
                        : '',
                ].filter(Boolean),
            ],
        });

        return getApi().get(`${GetAPIEndpoint()}/api/promo/store/products-store?skip=${skip || 0}&take=${limit || 20}&include=${include}&filters=${encodeURIComponent(JSON.stringify(filters))}&requireTotalCount=true`, { headers: getHeaders() });
    },
    getRelatedProducts: (designId, options) => getApi().get(`${GetAPIEndpoint()}/api/promo/store/products-store?skip=0&take=${options?.limit}&filters=%5B%7B"key"%3A"data.designs.mainDesignId"%2C"operation"%3A"="%2C"value"%3A"${designId}"%7D%5D&requireTotalCount=true`, { headers: getHeaders() }),
};
export default productApi;
