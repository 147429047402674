// react
import React, { useState, useEffect } from "react";

// third-party
import { Modal } from "reactstrap";
import styled from '@emotion/styled';
// application
import { Cross20Svg } from "../../svg";
import { validateCreditCard } from '../../services/validates';

const StyledCard = styled.div`
  font-family: "Space Mono", monospace;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  height: 200px;
  width: 320px;
  flex: 0 0 auto;
  padding: 0 1em;
  margin-right: 2em;
  margin-bottom: 2em;
  align-self: center;
  .card {
    height: 100%;
    border-radius: 8px;
    box-shadow: 1px 1px #aaa3a3;
    background: linear-gradient(45deg, #343a40, #666666, #343a40);
    color: #fff;

    .cardNumber {
      position: relative;
      top: 75px;
      display: flex;
      justify-content: space-between;
      font-size: 1.2em;
      word-spacing: 4px;
      letter-spacing: 2px;
      padding: 0 1em;
    }

    .cardInfo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      letter-spacing: 1px;
      line-height: 18px;
      text-transform: uppercase;
      position: relative;
      top: 110px;
      padding: 0 1em;

      span {
        font-size: 11px;
      }

      p {
        margin-top: 8px;
        font-size: 16px;
      }

      .cardExpiry {
        text-align: right;
      }
    }
  }
`;

const StyledTextInput = styled.div`
  color: #343a40;

  label {
    display: inline;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    outline: none;
    border: 1px solid #ebecee;
    padding: 10px;
    margin: 10px 0;
  }

  input:focus {
    border-color: #64b5f6;
}
`;

const StyledCardForm = styled.div`
  flex: 1 0 auto;
  background-color: var(--primary-color);
  border-radius: 8px;
  max-width: 750px;
  overflow: hidden;
  padding: 1em 2em;
  box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.5);
  h2 {
    color: #343a40;
    margin: 0;
    padding-top: .25em;
    border-bottom: 1px solid #aeaeae;
    padding-bottom: .75em;
  }
  
  ul {
    list-style: none;
    padding: 0;
  
    li:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`;

const StyledApp = styled.main`
display: flex;
flex-direction: row;
flex-wrap: wrap;
padding: 3em;
justify-content: space-around;

  & > div:not(:last-child) {
    margin-bottom: 2em;
  }
`;

const FlexLine = styled.div`
    display: flex;
    @media (max-width: 1000px) {
        display: block;
    }
`;

const TextInput = ({
    label, type = "text", id, value, ...props
}) => (
    <StyledTextInput>
        {label && <label htmlFor={id}>{label}</label>}
        <input id={id} type={type} value={value} {...props} />
    </StyledTextInput>
);

function Payment({ open, close, handlePayment }) {
    const [cardInfo, setCardInfo] = useState({
        name: "",
        number: "",
        expiryMonth: "",
        expiryYear: "",
        cvv: "",
    });

    useEffect(() => {
        setCardInfo({
            name: "",
            number: "",
            expiryMonth: "",
            expiryYear: "",
            cvv: "",
        });
    }, [open]);

    const getDisplayCardNumber = () => {
        const placeholder = "****************";
        const newPlaceholder = placeholder.substr(cardInfo.number.length);

        return cardInfo.number.concat("", newPlaceholder).match(/.{1,4}/g);
    };

    const cardName = cardInfo.name < 1 ? "Name" : cardInfo.name;
    const expiry = cardInfo.expiryMonth < 1 && cardInfo.expiryYear < 1
        ? "00/00"
        : `${cardInfo.expiryMonth}/${cardInfo.expiryYear}`;

    const onChange = ({ key, value }) => {
        const isNumber = /^[0-9\b]+$/.test(value);

        if ((key === "name" && !isNumber) || (key !== "name" && isNumber)) {
            setCardInfo({ ...cardInfo, [key]: value });
        }
    };

    return (
        <Modal
            isOpen={open}
            toggle={close}
            centered
            size="lg"
        >
            <button className="quickview__close" type="button" onClick={close} style={{ backgroundColor: 'unset' }}>
                <Cross20Svg />
            </button>
            <StyledApp>
                <StyledCardForm>
                    <h3>Card Details</h3>
                    <FlexLine>
                        <StyledCard>
                            <div className="card">
                                <div className="cardNumber">
                                    <span className="numberSection">{getDisplayCardNumber()[0]}</span>
                                    <span className="numberSection">{getDisplayCardNumber()[1]}</span>
                                    <span className="numberSection">{getDisplayCardNumber()[2]}</span>
                                    <span className="numberSection">{getDisplayCardNumber()[3]}</span>
                                    {getDisplayCardNumber()[4] && <span className="numberSection">{getDisplayCardNumber()[4]}</span>}
                                </div>
                                <div className="cardInfo">
                                    <div className="cardName">
                                        <span>Card Holder</span>
                                        <p>{cardName}</p>
                                    </div>
                                    <div className="cardExpiry">
                                        <span>Expires</span>
                                        <p>{expiry}</p>
                                    </div>
                                </div>
                            </div>
                        </StyledCard>
                        <form>
                            <ul>
                                <li>
                                    <TextInput
                                        label="Card Holder Name"
                                        id="name"
                                        type="text"
                                        value={cardInfo.name}
                                        onChange={(e) => onChange({ key: "name", value: e.target.value })}
                                        minLength="3"
                                        maxLength="40"
                                        required
                                    />
                                </li>
                                <li>
                                    <TextInput
                                        label="Card Number"
                                        id="number"
                                        type="text"
                                        value={cardInfo.number}
                                        onChange={(e) => onChange({ key: "number", value: e.target.value })}
                                        placeholder="**** **** **** ****"
                                        minLength="15"
                                        maxLength="16"
                                        required
                                    />
                                </li>
                                <li>
                                    <FlexLine>
                                        <TextInput
                                            label="Expiry Month"
                                            id="expiryMonth"
                                            type="text"
                                            value={cardInfo.expiryMonth}
                                            onChange={(e) => onChange({ key: "expiryMonth", value: e.target.value })}
                                            placeholder="MM"
                                            minLength="2"
                                            maxLength="2"
                                            required
                                        />
                                        <TextInput
                                            label="Expiry Year"
                                            id="expiryYear"
                                            type="text"
                                            value={cardInfo.expiryYear}
                                            onChange={(e) => onChange({ key: "expiryYear", value: e.target.value })}
                                            placeholder="YY"
                                            minLength="2"
                                            maxLength="2"
                                            required
                                        />
                                        <TextInput
                                            label="CVV"
                                            id="cvv"
                                            type="text"
                                            value={cardInfo.cvv}
                                            onChange={(e) => onChange({ key: "cvv", value: e.target.value })}
                                            placeholder="CVV"
                                            minLength="3"
                                            maxLength="3"
                                        />
                                    </FlexLine>
                                </li>
                            </ul>
                        </form>

                    </FlexLine>
                </StyledCardForm>
            </StyledApp>
            {Object.keys(validateCreditCard(cardInfo)).length > 0 && (
                <p style={{ color: 'red', paddingInline: '10px' }}>
                    {validateCreditCard(cardInfo).name}
                    <br />
                    {validateCreditCard(cardInfo).number}
                    <br />
                    {validateCreditCard(cardInfo).expiryYear}
                    <br />
                    {validateCreditCard(cardInfo).expiryMonth}
                    <br />
                    {validateCreditCard(cardInfo).cvv}
                </p>
            )}

            <button type="button" className="btn btn-primary btn-xl btn-block" onClick={() => handlePayment(cardInfo)} disabled={Object.keys(validateCreditCard(cardInfo)).length > 0}>
                Order
            </button>
        </Modal>
    );
}

export default Payment;
