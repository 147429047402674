/* eslint-disable max-len */
// react
import React, { useContext, useState, useEffect } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import { groupBy, values } from "lodash";

// application
import { toast } from "react-toastify";
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import Indicator from "./Indicator";
import { Cart20Svg } from "../../svg";
import { cartRemoveItem } from "../../store/cart";
import { url } from "../../services/utils";
import { calculatePartPrices, calcTotal } from "../../server/utils";
import StoreContext from "../../contexts/StoreContext";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

function IndicatorCart(props) {
    const location = useLocation();
    const history = useHistory();
    const { cart, cartRemoveItem } = props;
    const { store } = useContext(StoreContext);
    const [cartItems, setCartItems] = useState([]);
    let dropdown;
    // let totals;

    const {
        data: storeData,
        isLoading: storeIsLoading,
    } = useQuery({
        queryKey: ["store"],
        queryFn: async () => axios.get("/api/promo/stores/domainsource").then((res) => res.data),
    });

    useEffect(() => {
        if (store && Array.isArray(cart.items)) {
            setCartItems(calculatePartPrices(cart.items, store));
        }
    }, [cart, store]);

    // if (cart.extraLines.length > 0) {
    //     const extraLines = cart.extraLines.map((extraLine, index) => (
    //         <tr key={index}>
    //             <th>{extraLine.title}</th>
    //             <td><Currency value={extraLine.price} /></td>
    //         </tr>
    //     ));

    //     totals = (
    //         <React.Fragment>
    //             <tr>
    //                 <th>Subtotal</th>
    //                 <td><Currency value={calcSubtotal(cartItems)} /></td>
    //             </tr>
    //             {extraLines}
    //         </React.Fragment>
    //     );
    // }

    if (storeIsLoading) return null

    const grouppedItems = values(groupBy(cartItems, (item) => item.product.id));

    const items = Array.isArray(grouppedItems)
        && grouppedItems.map((items) => {
            let options;
            const id = items[0].product?.id;
            const currentProduct = items[0].product?.data;
            const product = currentProduct?.productData;

            if (items[0].options && items[0].options[0] && items[0].options[0].personalization) {
                options = (
                    <ul className="dropcart__product-options">
                        {items[0].options[0].personalization.map(
                            (option, index) => option.value && <li key={index}>{`${option.name}: ${option.value}`}</li>,
                        )}
                    </ul>
                );
            }

            const removeButton = (id, color, label) => (
                <AsyncAction
                    action={() => cartRemoveItem(id, color, label)}
                    render={({ run, loading }) => {
                        const classes = classNames("dropcart__product-remove btn btn-light btn-sm btn-svg-icon", {
                            "btn-loading": loading,
                        });

                        return (
                            <button type="button" onClick={run} className={classes}>
                                <i className="fa fa-trash" style={{ fontSize: "smaller" }} />
                            </button>
                        );
                    }}
                />
            );

            return (
                <div key={items[0].id} className="dropcart__product">
                    {/* {image} */}
                    {product && (
                        <div className="dropcart__product-info">
                            <div className="dropcart__product-name">
                                <Link to={url.product(id)}>{product.productName}</Link>
                            </div>
                            {options}
                            {items.map((item) => (
                                <div className="dropcart__product-meta" key={item.id}>
                                    <span className="dropcart__product-quantity" style={{ verticalAlign: "sub" }}>
                                        {`${item.label} - ${item.color} - `}
                                    </span>
                                    <span className="dropcart__product-price" style={{ verticalAlign: "sub" }}>
                                        {`${item.quantity} x `}
                                        <Currency value={item.price} />
                                    </span>
                                    {removeButton(id, item.color, item.label)}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            );
        });

    if (cart.quantity) {
        const findMinQuantityForCost = (formulas, xValues) => {
            const findZeroIndex = formulas[0].findIndex((x) => x === "0");
            if (findZeroIndex !== -1) {
                return xValues[findZeroIndex + 1];
            }
            return 1;
        };

        const areCartCostsAcceptable = () => {
            let isValid = true;
            let errorMessage = "";

            cartItems.forEach((item) => {
                const { quantity } = item;
                const productCost = item?.product?.data?.pricingManagement?.productCost;
                const productName = item?.product?.data?.productData?.productName;
                if (!productCost) return;
                const { formulas, xValues } = productCost;
                let quantityIndex = xValues.findIndex((x) => x === quantity);
                if (quantityIndex === -1) {
                    quantityIndex = xValues.findIndex((x, i, arr) => quantity > x && quantity < arr[i + 1]);
                }

                if (quantityIndex > xValues.length - 1) {
                    quantityIndex = xValues.length - 1;
                }

                const cost = Number(formulas[0][quantityIndex]);

                if (cost <= 0) {
                    isValid = false;
                    const minQuantity = findMinQuantityForCost(formulas, xValues);
                    errorMessage = `The cost of ${productName} is $0.\n \n Please remove it from your cart or increase the quantity to at least ${minQuantity}.\n`;
                }
            });

            return { isValid, errorMessage };
        };
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__products-list">{items}</div>

                <div className="dropcart__totals">
                    <table>
                        <tbody>
                            {/* {totals} */}
                            <tr>
                                <th>Total</th>
                                <td>
                                    <Currency value={calcTotal(cartItems, cart.extraLines, storeData)} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="dropcart__buttons">
                    {location?.pathname !== "/shop/cart" && (
                        <Link className="btn btn-secondary" to="/shop/cart">
                            View Cart
                        </Link>
                    )}
                    {!(location?.pathname === "/shop/checkout" || !store?.isActive) && (
                        <Link
                            className="btn btn-primary"
                            to={{
                                pathname: "/shop/cart",
                                state: { to: "/shop/checkout" },
                            }}
                        >
                            Checkout
                        </Link>
                    )}
                </div>
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__empty">Your shopping cart is empty!</div>
            </div>
        );
    }

    return <Indicator url="/shop/cart" dropdown={dropdown} value={cart.quantity} icon={<Cart20Svg />} />;
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
