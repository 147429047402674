/* eslint-disable max-len */
// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import Currency from '../shared/Currency';
import { ArrowRoundedRight8x13Svg } from '../../svg';
import { cartAddItem } from '../../store/cart';
import { url } from '../../services/utils';

function Suggestions(props) {
    const {
        context, className, products,
    } = props;
    const rootClasses = classNames(`suggestions suggestions--location--${context}`, className);

    const list = products
        && products.map((product) => {
            const { productData, mediaData, productOptions } = product.data;
            let media = mediaData?.media.filter((x) => x.mediaType === 'Image' && !x.url.includes("watch") && !x.url.includes("sw"));
            let mediaUrl = productData?.primaryImageUrl;
            if (productOptions?.enableColor && productOptions?.colors.length > 0) {
                media = media.find((x) => productOptions?.colors?.includes(x.color));
                mediaUrl = productData?.primaryImageUrl || media?.url;
            }

            return (
                <li key={product.id} className="suggestions__item">
                    <div className="suggestions__item-image product-image">
                        <div className="product-image__body">
                            <img className="product-image__img" src={mediaUrl} alt="" />
                        </div>
                    </div>

                    <div className="suggestions__item-info">
                        <Link className="suggestions__item-name" to={url.product(product.id)}>
                            {productData.productName}
                        </Link>
                        <div className="suggestions__item-meta">
                            SKU:
                            {' '}
                            {productData.productId}
                        </div>
                    </div>
                    <div className="suggestions__item-price">
                        <Currency value={productData?.minimumPartPrice || 0} />
                    </div>
                    {context === 'header' && (
                        <div className="suggestions__item-actions">
                            <Link className="suggestions__item-name" to={url.product(product.id)}>
                                <button
                                    type="button"
                                    onClick={() => {}}
                                    title="Add to cart"
                                    className={classNames('btn btn-primary btn-sm btn-svg-icon')}
                                >
                                    <ArrowRoundedRight8x13Svg />
                                </button>
                            </Link>
                        </div>
                    )}
                </li>
            );
        });

    return (
        <div className={rootClasses}>
            <ul className="suggestions__list">
                {products.length > 0 ? list : <li className="suggestions__item">No results found</li>}
            </ul>
        </div>
    );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(Suggestions);
