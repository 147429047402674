/* eslint-disable max-len */
import { CART_ADD_ITEM, CART_CLEAR_ALL, CART_REMOVE_ITEM, CART_UPDATE_QUANTITIES } from './cartActionTypes';

/**
 * @param {array} items
 * @param {object} product
 * @param {array} options
 * @return {number}
 */
function findItemIndex(items, product, selectedItem, options) {
    return items.findIndex((item) => {
        if (item.product.id !== product.id || item.options.length !== options.length) {
            return false;
        }

        for (let i = 0; i < options.length; i += 1) {
            const option = options[i];
            const itemOption = item.options.find((itemOption) => (
                itemOption.optionId === option.optionId && itemOption.valueId === option.valueId
            ));

            if (!itemOption) {
                return false;
            }
        }

        if (item.product.id === product.id && item.label === selectedItem.label && item.color === selectedItem.color) { return true; }

        return false;
    });
}

function calcSubtotal(items) {
    return items.reduce((subtotal, item) => subtotal + item.total, 0);
}

function calcQuantity(items) {
    return items.reduce((quantity, item) => quantity + item.quantity, 0);
}

function calcTotal(subtotal, extraLines) {
    return subtotal + extraLines.reduce((total, extraLine) => total + extraLine.price, 0);
}

function addItem(state, product, options, selectedItems) {
    let newItems = [...state.items];
    let { lastItemId } = state;

    selectedItems.filter((x) => x.quantity > 0).forEach((item) => {
        const productClassType = product?.data?.productData?.productType?.id;
        const productClassTypeString = Number.isInteger(productClassType) ? productClassType.toString() : "1";
        const itemIndex = findItemIndex(newItems, product, item, options);
        if (itemIndex === -1) {
            lastItemId += 1;
            newItems = [...newItems, {
                id: lastItemId,
                product: JSON.parse(JSON.stringify(product)),
                options: JSON.parse(JSON.stringify(options)),
                label: item.label,
                color: item.color,
                price: item.price,
                total: item.price * item.quantity,
                quantity: Number(item.quantity),
                weight: item.weight,
                weightUom: item.weightUom,
                imageUrl: item.imageUrl,
                sizeMarkup: item.sizeMarkup,
                productClassType: productClassTypeString,
            }];
        } else {
            const oldItem = newItems[itemIndex];
            const newOptions = oldItem.options;
            newOptions[0].personalization = [...newOptions[0].personalization, ...options[0].personalization];

            newItems = [
                ...newItems.slice(0, itemIndex),
                {
                    ...oldItem,
                    options: JSON.parse(JSON.stringify(newOptions)),
                    quantity: Number(oldItem.quantity) + Number(item.quantity),
                    total: (Number(oldItem.quantity) + Number(item.quantity)) * item.price,
                },
                ...newItems.slice(itemIndex + 1),
            ];
        }
    });

    const subtotal = calcSubtotal(newItems);
    const total = calcTotal(subtotal, state.extraLines);

    console.log('newItems', newItems);
    return {
        ...state,
        lastItemId,
        subtotal,
        total,
        items: newItems,
        quantity: calcQuantity(newItems),
    };
}

function removeItem(state, itemId, color, label) {
    const { items } = state;
    const newItems = items.filter((item) => !(item.product.id === itemId && item.color === color && item.label === label));

    const subtotal = calcSubtotal(newItems);
    const total = calcTotal(subtotal, state.extraLines);

    return {
        ...state,
        items: newItems,
        quantity: calcQuantity(newItems),
        subtotal,
        total,
    };
}

function updateQuantities(state, quantities) {
    let needUpdate = false;

    const newItems = state.items.map((item) => {
        const quantity = quantities.find((x) => x.itemId === item.id && x.value !== item.quantity);

        if (!quantity) {
            return item;
        }

        needUpdate = true;

        return {
            ...item,
            quantity: quantity.value,
            total: quantity.value * item.price,
        };
    });

    if (needUpdate) {
        const subtotal = calcSubtotal(newItems);
        const total = calcTotal(subtotal, state.extraLines);

        return {
            ...state,
            items: newItems,
            quantity: calcQuantity(newItems),
            subtotal,
            total,
        };
    }

    return state;
}

/*
* item example:
* {
*   id: 1,
*   product: {...}
*   options: [
*     {optionId: 1, optionTitle: 'Color', valueId: 1, valueTitle: 'Red'}
*   ],
*   price: 250,
*   quantity: 2,
*   total: 500
* }
* extraLine example:
* {
*   type: 'shipping',
*   title: 'Shipping',
*   price: 25
* }
*/
const initialState = {
    lastItemId: 0,
    quantity: 0,
    items: [],
    subtotal: 0,
    extraLines: [
        {
            type: 'tax',
            title: 'Tax',
            price: 0,
        },
    ],
    total: 0,
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
    case CART_ADD_ITEM:
        return addItem(state, action.product, action.options, action.selectedItems);

    case CART_REMOVE_ITEM:
        return removeItem(state, action.itemId, action.color, action.label);

    case CART_UPDATE_QUANTITIES:
        return updateQuantities(state, action.quantities);

    case CART_CLEAR_ALL:
        return initialState;

    default:
        return state;
    }
}
