/* eslint-disable max-len */
// react
import React, { useCallback } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import Collapse from '../shared/Collapse';
import FilterCategory from '../filters/FilterCategory';
import FilterCheck from '../filters/FilterCheck';
import FilterColor from '../filters/FilterColor';
import FilterRadio from '../filters/FilterRadio';
import FilterRange from '../filters/FilterRange';
import getFilterHandler from '../../services/filters';
import { ArrowRoundedDown12x7Svg } from '../../svg';

const filterComponents = {
    category: FilterCategory,
    range: FilterRange,
    check: FilterCheck,
    radio: FilterRadio,
    color: FilterColor,
};

function WidgetFilters(props) {
    const {
        dispatch,
        categories,
        colors,
        price,
        tags,
        values,
        title,
        offcanvas,
        categorySlug,
        hideCategory,
    } = props;

    const handleValueChange = useCallback(({ filtersData, value, type, slug }) => {
        const handler = getFilterHandler(type);
        if (handler) {
            dispatch({
                type: 'SET_FILTER_VALUE',
                filter: slug,
                value: handler.isDefaultValue(filtersData, value) ? undefined : handler.serialize(value),
            });
        }
    }, [dispatch]);

    const handleResetFilters = () => {
        dispatch({ type: 'RESET_FILTERS' });
    };

    const getFilter = (filtersData, type, slug) => {
        const filterName = slug.toLowerCase();
        let filterView;
        let { value } = filtersData;
        const handler = getFilterHandler(type);

        if (handler && filterName in values) {
            value = handler.deserialize(values[filterName]) || handler.getDefaultValue(filtersData);
        }

        const FilterComponent = filterComponents[type];

        if (FilterComponent) {
            filterView = (
                <FilterComponent
                    data={filtersData}
                    type={type}
                    value={value}
                    slug={filterName}
                    onChangeValue={handleValueChange}
                    categorySlug={categorySlug}
                />
            );
        }

        return (
            <div key={filtersData.slug} className="widget-filters__item">
                <Collapse
                    toggleClass="filter--opened"
                    render={({ toggle, setItemRef, setContentRef }) => (
                        <div className="filter filter--opened" ref={setItemRef}>
                            <button type="button" className="filter__title" onClick={toggle}>
                                {slug}
                                <ArrowRoundedDown12x7Svg className="filter__arrow" />
                            </button>
                            <div className="filter__body" ref={setContentRef}>
                                <div className="filter__container">
                                    {filterView}
                                </div>
                            </div>
                        </div>
                    )}
                />
            </div>
        );
    };

    const classes = classNames('widget-filters widget', {
        'widget-filters--offcanvas--always': offcanvas === 'always',
        'widget-filters--offcanvas--mobile': offcanvas === 'mobile',
    });

    return (
        <div className={classes}>
            <h4 className="widget-filters__title widget__title">{title}</h4>

            <div className="widget-filters__list">
                {!hideCategory && getFilter(categories, 'category', 'Categories')}
                {getFilter(colors, 'check', 'Colors')}
                {getFilter(price, 'range', 'Price')}
                {/* {getFilter(tags, 'check', 'Tags')} */}
            </div>

            <div className="widget-filters__actions d-flex mb-n2">
                <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={handleResetFilters}
                >
                    Reset
                </button>
            </div>
        </div>
    );
}

WidgetFilters.propTypes = {
    /**
     * widget title
     */
    title: PropTypes.node,
    /**
     * Category page dispatcher.
     */
    dispatch: PropTypes.func,
    /**
     * Products list filters.
     */
    categories: PropTypes.array,
    colors: PropTypes.array,
    price: PropTypes.array,
    tags: PropTypes.array,
    /**
     * Products list filter values.
     */
    values: PropTypes.object,
    /**
     * indicates when sidebar bar should be off canvas
     */
    offcanvas: PropTypes.oneOf(['always', 'mobile']),
};

WidgetFilters.defaultProps = {
    offcanvas: 'mobile',
};

export default WidgetFilters;
