// react
import React, { useContext } from 'react';

// third-party
import PropTypes from 'prop-types';

// application
import AppLink from '../shared/AppLink';
import Collapse from '../shared/Collapse';
import StoreContext from '../../contexts/StoreContext';
import IsAuthContext from '../../contexts/IsAuthContext';

function MobileLinks(props) {
    const { store } = useContext(StoreContext);
    const { isAuth } = useContext(IsAuthContext);
    const content = store?.contentManagement;
    const { links, level, onItemClick } = props;

    const handleItemClick = (item) => {
        if (onItemClick) {
            onItemClick(item);
        }
    };

    const linksList = content && content.filter((data) => data.navbarVisibility).filter((link) => !(link.url === '/shop' && !isAuth)).sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0)).map((link, index) => {
        const item = (
            <Collapse
                toggleClass="mobile-links__item--open"
                render={({ toggle, setItemRef, setContentRef }) => {
                    const linkOrButton = (
                        <AppLink
                            to={link.url}
                            className="mobile-links__item-link"
                            onClick={() => handleItemClick(link)}
                        >
                            {link.name}
                        </AppLink>
                    );

                    return (
                        <div className="mobile-links__item" ref={setItemRef}>
                            <div className="mobile-links__item-title">
                                {linkOrButton}
                            </div>
                        </div>
                    );
                }}
            />
        );

        return <li key={index}>{item}</li>;
    });

    return (
        <ul className={`mobile-links mobile-links--level--${level}`}>
            {linksList}
        </ul>
    );
}

MobileLinks.propTypes = {
    links: PropTypes.array,
    level: PropTypes.number,
    onItemClick: PropTypes.func,
};

MobileLinks.defaultProps = {
    links: [],
    level: 0,
};

export default MobileLinks;
