// third-party
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// application
import React, { useContext } from "react";
import StoreContext from "../../contexts/StoreContext";
import NavPanel from "./NavPanel";

function Header(props) {
    const { layout } = props;
    const { store } = useContext(StoreContext);

    if (!store) {
        return null;
    }

    const currentHost = window.location.host;
    const isIncludeFreethinkapparel = currentHost.includes('freethinkapparel');

    const freeThinkHeaderLogo = `${process.env.PUBLIC_URL}/images/logos/FreeThink.png`;
    const headerLogo = isIncludeFreethinkapparel
        ? freeThinkHeaderLogo
        : store?.storeBranding?.storeLogo;

    return (
        <div className="site-header">
            {store?.primaryInformation?.showMainLogo && (
                <div className="container site-header__middle">
                    <div className="site-header__logo">
                        <Link to="/">
                            <img
                                style={{ height: "60px", maxHeight: "60px" }}
                                src={headerLogo}
                                alt="logo"
                            />
                        </Link>
                    </div>
                    {store?.footerManagement?.footerCopyrightSection?.copyRightCompanyPhone && (
                        <div className="site-header__phone">
                            <div className="site-header__phone-title">Customer Service</div>
                            <div className="site-header__phone-number">
                                {store?.footerManagement?.footerCopyrightSection?.copyRightCompanyPhone}
                            </div>
                        </div>
                    )}
                </div>
            )}
            <div className="site-header__nav-panel">
                <NavPanel layout={layout} />
            </div>
        </div>
    );
}

Header.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(["default", "compact"]),
};

Header.defaultProps = {
    layout: "default",
};

export default Header;
