/* eslint-disable max-len */
// react
import React, { useContext } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import { calculatePartPrice, getProductDiscountPrice, getProductPrice } from '../../server/utils';
import { url } from '../../services/utils';
import { cartAddItem } from '../../store/cart';
import { quickviewOpen } from '../../store/quickview';
import { Quickview16Svg } from '../../svg';
import AsyncAction from './AsyncAction';

// context
import StoreContext from '../../contexts/StoreContext';
import Currency from './Currency';

function ProductCard(props) {
    const {
        product, layout, quickviewOpen, region, id,
    } = props;
    const { store } = useContext(StoreContext);
    const containerClasses = classNames('product-card', {
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });
    let badges = [];
    let image;
    let price;

    const {
        productData, mediaData, productOptions, pricingManagement,
    } = product;
    const enabledProductCost = pricingManagement?.productCost?.enabled;
    const cost = enabledProductCost ? pricingManagement?.productCost?.formulas?.at(0)?.at(0) : 0;
    if (productOptions?.featuredProduct && productOptions?.featuredProduct.includes('Best Seller')) {
        badges.push(
            <div key="discount" className="product-card__badge product-card__badge--sale">
                Best Seller
            </div>,
        );
    }
    if (productOptions?.featuredProduct && productOptions?.featuredProduct.includes('Featured')) {
        badges.push(
            <div key="featured" className="product-card__badge product-card__badge--hot">
                Featured
            </div>,
        );
    }
    if (productOptions?.featuredProduct && productOptions?.featuredProduct.includes('New')) {
        badges.push(
            <div key="new" className="product-card__badge product-card__badge--new">
                New
            </div>,
        );
    }

    badges = badges.length ? <div className="product-card__badges-list">{badges}</div> : null;

    if (productData) {
        let media = mediaData?.media.filter((x) => x.mediaType === 'Image' && !x.url.includes("watch") && !x.url.includes("sw"));
        let mediaUrl = productData?.primaryImageUrl;
        if (productOptions?.enableColor && productOptions?.colors.length > 0) {
            media = media.find((x) => productOptions?.colors?.includes(x.color));
            mediaUrl = productData?.primaryImageUrl || media?.url;
        }

        image = (
            <div className="product-card__image product-image">
                <Link to={url.product(id)} className="product-image__body">
                    <img className="product-image__img" src={mediaUrl} alt="" />
                </Link>
            </div>
        );
    }

    if (store) {
        if (productData?.discountPrice) {
            price = (
                <div className="product-card__prices">
                    <span className="product-card__new-price">
                        <Currency value={getProductPrice(product, [], region)} />
                    </span>
                    {' '}
                    <span className="product-card__old-price">
                        <Currency value={getProductDiscountPrice(product, [], region)} />
                    </span>
                </div>
            );
        } else {
            price = (
                <div className="product-card__prices">
                    $
                    {(+cost || productData?.minimumPartPrice || 0).toFixed(2)}
                </div>
            );
        }
    }

    return (
        <div className={containerClasses}>
            {/* <AsyncAction
                action={() => quickviewOpen(id)}
                render={({ run, loading }) => (
                    <button
                        type="button"
                        onClick={run}
                        className={classNames('product-card__quickview', {
                            'product-card__quickview--preload': loading,
                        })}
                    >
                        <Quickview16Svg />
                    </button>
                )}
            /> */}
            {badges}
            {image}
            <div className="product-card__info">
                <div className="product-card__prices" style={{ marginBottom: '10px' }}>
                    {productData?.productBrand}
                </div>
                <div className="product-card__name">
                    <Link to={url.product(id)}>{productData?.productName}</Link>
                </div>
            </div>
            <div className="product-card__actions">
                {price}
            </div>
        </div>
    );
}

ProductCard.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = (state) => ({
    region: state.region,
    locale: state.locale,
});

const mapDispatchToProps = {
    cartAddItem,
    quickviewOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
