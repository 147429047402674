export const url = {
    home: () => '/',

    catalog: () => '/shop',

    category: (category) => `/shop/${category}`,

    product: (id) => `/shop/products/${id}`,
};

export function getCategoryParents(category) {
    return category.parent ? [...getCategoryParents(category.parent), category.parent] : [];
}
