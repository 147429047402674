// react
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const ldsRing = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const LdsLoader = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
& div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: ${ldsRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
& div:nth-child(1) {
  animation-delay: -0.45s;
}
& div:nth-child(2) {
  animation-delay: -0.3s;
}
& div:nth-child(3) {
  animation-delay: -0.15s;
}

`;

/**
 *
 * @param {object} param0
 * @param {'lds'|'default'} param0.variant 'lds' or 'default'
 */
export default function BlockLoader({ variant, fullWidth }) {
    if (variant === 'lds') {
        return (
            <div style={fullWidth ? {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                inset: 0,
                backgroundColor: 'rgba(0,0,0,70%)',
            } : {}}
            >
                <LdsLoader>
                    <div />
                    <div />
                    <div />
                    <div />
                </LdsLoader>
            </div>
        );
    }
    return (
        <div className="block-loader">
            <div className="block-loader__spinner" />
        </div>
    );
}

BlockLoader.propTypes = {
    variant: PropTypes.oneOf(['lds', 'default']),
    fullWidth: PropTypes.bool,
};

BlockLoader.defaultProps = {
    variant: 'default',
    fullWidth: false,
};
