// react
import React from 'react';

import BlockSlideShow from '../blocks/BlockSlideShow';

// data stubs
import RawHTML from './RawHTML';
import ProductsCarousel from './ProductsCarousel';

function Sections(props) {
    const { sections } = props;
    console.log("🚀 ~ file: Sections.jsx:12 ~ Sections ~ sections:", sections)
    const sectionComponents = [
    ];

    if (sections && sections.length > 0) {
        sections.sort((a, b) => a.sort - b.sort).forEach((section, i) => {
            const key = `home-${i}`;
            switch (section.type) {
            default:
                break;
            case 'html':
                sectionComponents.push(
                    <RawHTML key={key} html={section.html} />,
                );
                break;
            case 'slider':
                sectionComponents.push(
                    <BlockSlideShow key={key} slides={section.slides} />,
                );
                break;
            case 'productsCarousel':
                sectionComponents.push(
                    <ProductsCarousel key={key} section={section} />,
                );
                break;
            }
        });
    }

    return (
        <React.Fragment>
            {sectionComponents}
        </React.Fragment>
    );
}

export default Sections;
