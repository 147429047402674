import React, { useState } from "react";
import PropTypes from "prop-types";
import InputNumber from "./InputNumber";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const ProductInputNumber = ({
    selectedItems, size, handleChangeQuantity, store,
}) => {
    const [isPending, setIsPending] = useState(false);

    const disabled = isPending
        || (size.inventory === 0 && store?.storeSpecificFeatures?.outOfStockShopping === "Block out of stock shopping");
    const onChange = (val) => {
        setIsPending(true);
        sleep(160)
            .then(() => handleChangeQuantity({ size, val }))
            .finally(() => setIsPending(false));
    };
    return (
        <InputNumber
            id="product-quantity"
            aria-label="Quantity"
            className="size-quantity"
            size="lg"
            min={0}
            value={selectedItems.find((x) => x.label === size.label)?.quantity || 0}
            onChange={onChange}
            disabled={disabled}
        />
    );
};

ProductInputNumber.propTypes = {
    selectedItems: PropTypes.array,
    size: PropTypes.object,
    handleChangeQuantity: PropTypes.func,
    store: PropTypes.object,
};

export default ProductInputNumber;
